import Head from "next/head";
import useToggles from "src/services/toggles/useToggles";

export interface AppHeadProps {
  languages: string[];
  siteTitle: string;
  country: string;
  asPath: string;
  currentLang: string;
  host: string;
}

const excludedRoutesForAlternateLinks = [
  '/products',
  '/search',
];

const AppHead = (props: AppHeadProps) => {
  const {
    languages,
    siteTitle,
    country,
    currentLang,
    asPath,
    host,
  } = props;
  const {
    WITH_UBER_CANONICALS,
  } = useToggles();

  const getAlternateLangHref = (availableLanguage: string): string => {
    const alternateAsPath = asPath.replace(currentLang, availableLanguage);

    return `https://${host}${alternateAsPath}`;
  };

  const withAlternateLinks = !excludedRoutesForAlternateLinks.some(link => asPath.includes(link)) || !WITH_UBER_CANONICALS;

  return (
    <Head>
      <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no" />

      <title key='title'>{siteTitle}</title>

      {
        withAlternateLinks && languages.map((availableLang) =>
          <link
            key={availableLang}
            rel="alternate"
            hrefLang={`${availableLang}-${country}`}
            href={getAlternateLangHref(availableLang)}
          />,
        )
      }
    </Head>
  );
};

export default AppHead;
