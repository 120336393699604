import { fetchWithMemcached } from '../ApiCacheClient/fetchWithMemcached';
import { OverallConfig } from './types';

export default async function fetchOverallConfig(): Promise<OverallConfig> {
  const configUrl = process.env.CONFIG_URL;
  const { data } = await fetchWithMemcached<OverallConfig>({
    url: configUrl,
    cacheKey: configUrl,
    fetchOptions: {},
  });

  return data;
}
