import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { CartState } from "../general/cartTypes";

export const setCartInitialized = createAction('clientCart/setCartInitialized');

export const addSetCartInitializedCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(setCartInitialized, (state) => {
      state.isCartInitialized = true; //it looks like this property is only used in tests
    });
};
