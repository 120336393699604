import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartState } from './types';

const postCouponRequest = createAction('serverCart/postCouponRequest');
export const postCouponSuccess = createAction<ServerCartData>('serverCart/postCouponSuccess');
const postCouponError = createAction<RSAAActionErrorPayload>('serverCart/postCouponError');

const requestPostCoupon = (coupon_code: string) => (dispatch, getState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/coupon/`,
      body: JSON.stringify({ coupon_code }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postCouponRequest.type,
        {
          type: postCouponSuccess.type,
          payload: getSuccessPayload,
        },
        postCouponError.type,
      ],
    }),
  );
};

export default requestPostCoupon;

export function addPostCouponCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCouponRequest, state => {
      state.statusMap.postCoupon = 'loading';
      state.errorMap.postCoupon = null;
    })
    .addCase(postCouponSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postCoupon = 'succeeded';
      state.errorMap.postCoupon = null;
      state.errors = null;
    })
    .addCase(postCouponError, (state, action) => {
      state.statusMap.postCoupon = 'failed';
      state.errorMap.postCoupon = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
