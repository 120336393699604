import { UserPhone } from '../../swr/useUserProfile';
import { FIRST_NAME, LAST_NAME, SUBSCRIBE_TO_MARKETING, USER_BIRTH_DATE, USER_CAN_EDIT_EMAIL, USER_EMAIL } from '../constants';
import { UserData, UserDataPostRequestBody } from './userProfileReducers';

export function getServerSideEmails(
  currentEmail: string, storedEmails: string[] = [], isLogin = false,
) {
  if (isLogin) {
    return storedEmails;
  }

  const [ , ...restEmails ] = storedEmails;

  if (currentEmail === '') {
    return [...restEmails];
  }

  return [currentEmail, ...restEmails];
}

export function getServerSideBirthDate(userBirthDate: string) {
  if (!userBirthDate || userBirthDate === '--') return '';

  return userBirthDate;
}

export function getClientSideUselessEmails(emails = []) {
  return emails.map(e => e.email).filter(e => !!e);
}

export function getClientSideUserEmail(emails) {
  if (!emails || emails.length === 0) return '';

  return emails[0].email;
}

export function getIsEmailEditable(emails) {
  if (!emails || emails.length === 0) return true;

  return emails[0].is_editable;
}

export function getServerSideUserProfileData(userData: UserData): UserDataPostRequestBody {
  const birthdate = getServerSideBirthDate(userData[USER_BIRTH_DATE]);
  const result = {
    name: userData[FIRST_NAME],
    surname: userData[LAST_NAME],
    ...(birthdate && { birthdate }),
    emails: getServerSideEmails(
      userData[USER_EMAIL],
      userData.uselessEmails,
      !userData[USER_CAN_EDIT_EMAIL],
    ),
    subscribed_to_marketing: userData[SUBSCRIBE_TO_MARKETING],
  };

  return result;
}

export function getClientSidePhone(
  phoneList: UserPhone[],
  selectedPhone: string,
): string | null {
  const phoneHasDeleted = !phoneList.find(p => p.phone === selectedPhone);

  return phoneHasDeleted ? null : selectedPhone;
}
