import * as Sentry from '@sentry/nextjs';
import { DEFAULT_DELIVERY_TYPE } from 'src/redux/constants';
import setDeliveryTypeCookie from '../../utils/delivery/setDeliveryTypeCookie';
import setStoreIdCookie from '../../utils/delivery/setStoreIdCookie';
import { getCartSuccess } from './serverCart/requestGetCart';
import { postCartContactsSuccess } from './serverCart/requestPostCartContacts';
import { postCartItemsSuccess } from './serverCart/requestPostCartItems';
import { postCouponSuccess } from './serverCart/requestPostCoupon';
import { postPaymentSuccess } from './serverCart/requestPostPayment';
import { postReplacementsSuccess } from './serverCart/requestPostReplacements';
import { postScheduleSuccess } from './serverCart/requestPostSchedule';

const deliveryRefreshMiddleware = store => next => action => {
  const actionTypes = [
    getCartSuccess.type,
    postCartContactsSuccess.type,
    postCartItemsSuccess.type,
    postCouponSuccess.type,
    postPaymentSuccess.type,
    postReplacementsSuccess.type,
    postScheduleSuccess.type,
  ];

  const state = store.getState();
  const cartTypeIsServer = state.app.cartType === 'server';

  if (cartTypeIsServer && actionTypes.includes(action.type)) {
    const oldStoreId = state.serverCart.data.store_id;
    const oldDeliveryType = state.serverCart.data.delivery?.type || DEFAULT_DELIVERY_TYPE;
    const newStoreId = action.payload?.store_id;
    const newDeliveryType = action.payload.delivery?.type || DEFAULT_DELIVERY_TYPE;

    if (oldStoreId !== newStoreId || oldDeliveryType !== newDeliveryType) {
      // StoreId sentry report
      if (!newStoreId || newStoreId === 'undefined') {
        Sentry.captureException(
          new Error('StoreId is undefined'),
          {
            extra: {
              where: 'deliveryRefreshMiddleware.js',
              cartType: state.app?.cartType,
              deliveryType: state.serverCart?.data?.delivery?.type,
              address: JSON.stringify(state.addressManagement?.address),
            },
          },
        );
      }

      setStoreIdCookie(newStoreId);
      setDeliveryTypeCookie(newDeliveryType);

      window.location.reload();
    }
  }

  return next(action);
};

export default deliveryRefreshMiddleware;
