import { useState } from "react";
import { Offer } from "src/redux/apiTypes";

const useMainPromotionService = (initialMainPromotion: Offer | null) => {
  const [mainPromotion] = useState(initialMainPromotion);

  return mainPromotion;
};

export default useMainPromotionService;
