import { useMemo } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { selectAppConfig } from './selectAppConfig';

export function useAppConfig() {
  const appConfig = useAppSelector(selectAppConfig);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const appConfigMemo = useMemo(() => appConfig, []);

  return appConfigMemo;
}
