import { Fragment } from "react";
import MessengerColumnLinks from './components/MessengerColumnLinks';
import MessengersList from './components/MessengersList';
import useMessengersContext from './context/useMessengersContext';
import { MessengersProps } from "./Messengers.types";
import ZendeskWidget from './ZendeskWidget';

const Messengers = (props: MessengersProps) => {
  const { variant } = props;

  const { socials, listOfMessengersProps, zendeskButtonProps, zendeskWidgetProps, messengersShouldBeHidden } = useMessengersContext();

  return (
    <div data-testid="Messengers">
      {!messengersShouldBeHidden && (
        <Fragment>
          {variant === 'column' && (
            <MessengerColumnLinks
              socials={socials}
              zendeskButtonProps={zendeskButtonProps}
              listOfMessengersProps={listOfMessengersProps}
            />
          )}

          {variant === 'line' && (
            <MessengersList
              socials={socials}
              zendeskButtonProps={zendeskButtonProps}
              onClick={listOfMessengersProps.close}
              variant='line'
            />
          )}
        </Fragment>
      )}

      {zendeskWidgetProps && (
        <ZendeskWidget {...zendeskWidgetProps } />
      )}
    </div>
  );
};

export default Messengers;
