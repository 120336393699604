import { ServerCartItemToSend, CartOperation } from '../../serverCart/types';

interface GetSendingToCartItemsFunc {
  (items, operation: CartOperation): ServerCartItemToSend[];
}

export const getSendingToCartItems: GetSendingToCartItemsFunc = (items, operation) => {
  const sendingItems = items.map(
    (i): ServerCartItemToSend => {
      return {
        ean: i.ean,
        amount: i.amount,
        operation,
        comments: i.comments,
      };
    },
  );

  return sendingItems;
};
