import { createContext } from 'react';
import { SocialMessenger, ZendeskButtonProps, ZendeskWidgetProps } from '../Messengers.types';

export interface ListOfMessengersProps {
  isOpen: boolean;
  close: () => void;
  open: () => void;
}

export interface MessengersContextValue {
  socials: SocialMessenger[];
  messengersShouldBeHidden: boolean;
  zendeskWidgetProps: ZendeskWidgetProps;
  zendeskButtonProps: ZendeskButtonProps;
  listOfMessengersProps: ListOfMessengersProps;
}

const MessengersContext = createContext<MessengersContextValue | null>(null);

export default MessengersContext;
