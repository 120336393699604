import { RSAA } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS, USER_PROFILE_DATA_ERROR } from '../userProfileTypes';

export const fetchUserProfile = () => (dispatch, getState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch({
    [RSAA]: {
      method: 'GET',
      endpoint: `${apiRoot}/user/profile/`,
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        FETCH_USER_PROFILE_REQUEST,
        FETCH_USER_PROFILE_SUCCESS,
        USER_PROFILE_DATA_ERROR,
      ],
    },
  });
};
