import * as Sentry from '@sentry/nextjs';
import { Dispatch } from 'redux';
import { modalsManager } from '../../../utils/system/modalsManager';
import { setCartInitialized } from '../../cart/clientCart/setCartInitialized';
import { RootState } from '../../reducers';
import { initClientCartAndDelivery } from './initClientCartAndDelivery';
import { initServerCartAndDelivery } from './initServerCartAndDelivery';
import { prepareServerCartDataToInit } from './prepareServerCartDataToInit';

export const initAppOnClientSide = (storeId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const loginIsNotChecked = !state.user.isLoginChecked;

  if (loginIsNotChecked) {
    Sentry.captureMessage(
      'Application initialization occurs before checking user authorization',
    );
  }

  const userIsLoggedIn = state.user.isLoggedIn;

  let initWasSucceeded: boolean;

  if (userIsLoggedIn) {
    // server cart initialization
    await dispatch(prepareServerCartDataToInit());
    initWasSucceeded = await dispatch(initServerCartAndDelivery(storeId));
  } else {
    // client cart initialization
    initWasSucceeded = await dispatch(initClientCartAndDelivery(storeId));
  }

  if (initWasSucceeded) {
    setTimeout(() => {
      dispatch(setCartInitialized());
    }, 0);

    modalsManager.showFirstModal(dispatch);
  }
};

