import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";
import { DeliveryService, FetchConfig } from "../redux/apiTypes";
import getSsrUserAgent from "../utils/api/getUserAgent";

const emptyArray: DeliveryService[] = [];

const useDeliveryServices = (language: string, initialDeliveryServices?: DeliveryService[]) => {
  const { fetchConfig } = useAppConfig();

  const deliveryServicesFetcher = () => getDeliveryServices(fetchConfig);

  const { data, error } = useSWR(
    [language, 'deliveryServices'],
    deliveryServicesFetcher,
    {
      fallbackData: initialDeliveryServices,
    },
  );

  const deliveryServicesAreFetching = !data && !error;
  const deliveryServices = data && data.length ? data : emptyArray;

  return {
    deliveryServices,
    deliveryServicesAreFetching,
  };
};

export default useDeliveryServices;

export async function getDeliveryServices(fetchConfig: FetchConfig): Promise<DeliveryService[]> {
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const response = await fetch(
    `${apiRoot}/delivery_services/`,
    {
      headers: {
        'Accept-Language': language,
        'x-chain': xChain,
        'x-version': xVersion,
        'User-Agent': getSsrUserAgent(),
      },
    },
  );

  return await response.json();
}
