import { useRouter } from 'next/router';

export default function useRouterUtils<CustomRouter>() {
  const router = useRouter<CustomRouter>();

  const handleCleanUrlQuery = () => {
    router.replace(router.asPath.split('?')[0], undefined, { shallow: true });
  };

  const handleAddQueryParam = (param: keyof CustomRouter, value: string) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, [param]: value },
    });
  };

  return {
    router,
    cleanUrlQuery: handleCleanUrlQuery,
    addQueryParam: handleAddQueryParam,
  };
}
