import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartPayment, ServerCartState } from './types';

const postPaymentRequest = createAction('serverCart/postPaymentRequest');
export const postPaymentSuccess = createAction<ServerCartData>('serverCart/postPaymentSuccess');
const postPaymentError = createAction<RSAAActionErrorPayload>('serverCart/postPaymentError');

const requestPostPayment = (payment: ServerCartPayment) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/payment/`,
      body: JSON.stringify({ payment }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postPaymentRequest.type,
        {
          type: postPaymentSuccess.type,
          payload: getSuccessPayload,
        },
        postPaymentError.type,
      ],
    }),
  );
};

export default requestPostPayment;

export function addPostPaymentCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postPaymentRequest, (state) => {
      state.statusMap.postPayment = 'loading';
      state.errorMap.postPayment = null;
    })
    .addCase(postPaymentSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postPayment = 'succeeded';
      state.errorMap.postPayment = null;
      state.errors = null;
    })
    .addCase(postPaymentError, (state, action) => {
      state.statusMap.postPayment = 'failed';
      state.errorMap.postPayment = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
