import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from "../../utils/api/getResponseErrors";
import { Product, RSAAActionErrorPayload } from "../apiTypes";
import { RootState } from "../reducers";
import { ProductState } from "./types";

const fetchProductsDataRequest = createAction('product/fetchProductsDataRequest');
const fetchProductsDataSuccess = createAction<Product[]>('product/fetchProductsDataSuccess');
export const fetchProductsDataError = createAction<RSAAActionErrorPayload>('product/fetchProductsDataError');

const fetchProductsData = (
  eans: string[],
  storeId: string,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const bodyData = JSON.stringify({ eans });
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(createRsaaAction<RootState, Product[], undefined>({
    method: 'POST',
    endpoint: `${apiRoot}/stores/${storeId}/products/`,
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body: bodyData,
    credentials: 'include',
    types: [
      fetchProductsDataRequest.type,
      fetchProductsDataSuccess.type,
      fetchProductsDataError.type,
    ],
  }));
};

export default fetchProductsData;

export function addFetchProductsData(builder: ActionReducerMapBuilder<ProductState>) {
  builder
    .addCase(fetchProductsDataRequest, (state) => {
      state.isFetching = true;
      state.errors.fetchProductsData = null;
    })
    .addCase(fetchProductsDataSuccess, (state, action) => {
      state.productsData = action.payload;
      state.isFetching = false;
    })
    .addCase(fetchProductsDataError, (state, action) => {
      state.isFetching = false;
      state.errors.fetchProductsData = getResponseErrors(action.payload);
    });
}
