const getAbsoluteUrl = (asPath: string, host: string) => {
  const protocol = "https:";
  const origin = protocol + "//" + host;

  return {
    protocol,
    host,
    origin,
    fullUrl: origin + asPath,
  };
};

export default getAbsoluteUrl;
