import { NOT_FOUND } from '../../../../redux/constants';
import { HandlerFunc } from '../getRedirectPath';

const handleHoreca: HandlerFunc = ({ path, toggles }) => {
  if (
    (path.includes('account/horeca') || path.includes('/b2b-products')) &&
    !toggles.HORECA_IS_AVAILABLE
  ) {
    return NOT_FOUND;
  } else {
    return path;
  }
};

export default handleHoreca;
