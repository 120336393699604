import ssrCookie from 'cookie';
import { IncomingMessage, ServerResponse } from 'http';
import Cookies from 'js-cookie';
import { isValidApiUrl } from '../js-helpers/isValidUrl';
import { getIsServer } from '../system/getIsServer';

export default function getQueryApiRoot(
  queryApiRoot: string | undefined,
  req: IncomingMessage,
  res: ServerResponse,
): string | null {
  if (queryApiRoot) {
    if (isValidApiUrl(queryApiRoot)) {
      res.setHeader('set-cookie', `QUERY_API_ROOT=${queryApiRoot}; path=/; samesite=lax;`);

      return queryApiRoot;
    } else {
      res.write('Invalid parameter format api=url, please match it with "http(s)://some-name.zakaz.ua"');
      res.end();

      return null;
    }
  }

  let cookieApiRoot: string | null = null;

  if (getIsServer()) {
    const apiFromSsr = ssrCookie.parse(req.headers?.cookie || '')['QUERY_API_ROOT'];

    if (apiFromSsr) {
      cookieApiRoot = apiFromSsr;
    }
  } else {
    const apiFromCookies = Cookies.get('QUERY_API_ROOT');

    if (apiFromCookies) {
      cookieApiRoot = apiFromCookies;
    }
  }

  return cookieApiRoot;
}
