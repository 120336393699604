import { IncomingMessage } from 'http';
import Cookies from 'js-cookie';
import { HEADER_PROMOTION_CLOSED_COOKIE } from 'src/redux/constants';
import { getUserPreferencesFromCookies } from 'src/utils/pages/_app/_appUtils';
import { getIsServer } from 'src/utils/system/getIsServer';

export const setHeaderPromotionClosedCookie = () => Cookies.set(HEADER_PROMOTION_CLOSED_COOKIE, '1', { expires: 1 });
export const getHeaderPromotionClosedCookie = () => Cookies.get(HEADER_PROMOTION_CLOSED_COOKIE);
export const removeHeaderPromotionClosedCookie = () => Cookies.remove(HEADER_PROMOTION_CLOSED_COOKIE);

export const getServerOrClientPromotionClosedCookie = (req: IncomingMessage) => {
  if(getIsServer()) {
    return getUserPreferencesFromCookies(req)[HEADER_PROMOTION_CLOSED_COOKIE];
  }

  return getHeaderPromotionClosedCookie();
};

export const getPagePathWithoutLang = (pagePath: string) => {
  const splittedPath = pagePath.split('/');
  const pagePathWithoutLang = splittedPath.slice(2, splittedPath.length - 1).join('/');

  return pagePathWithoutLang;
};
