import { RSAA } from "redux-api-middleware";
import selectDeliveryType from "src/redux/cart/general/selectDeliveryType";
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { CACHEABLE } from "../../apiCacheMiddleware";
import { RootState } from "../../reducers";
import { GET_CATEGORIES_ERROR, GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS } from "../categoriesTypes";

interface GetAllCategoriesParams {
  onlyParents?: boolean;
  storeId: string;
}

const getAllCategories = (params: GetAllCategoriesParams) => async (dispatch, getState: () => RootState) => {
  const { storeId, onlyParents = false } = params;
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const deliveryType = selectDeliveryType(state);

  const endpoint = `${apiRoot}/stores/${storeId}/categories/?only_parents=${onlyParents}`;

  if (!state.categories.isFetching) {
    await dispatch({
      [CACHEABLE]: {
        cacheKey: `categories-${storeId}-${language}-${deliveryType}-${onlyParents}`,
        successActionType: GET_CATEGORIES_SUCCESS,
      },
      [RSAA]: {
        credentials: 'include',
        method: 'GET',
        endpoint,
        headers: {
          'Accept-Language': language,
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'X-Delivery-Type': deliveryType,
          'x-version': xVersion,
        },
        types: [
          GET_CATEGORIES_REQUEST,
          GET_CATEGORIES_SUCCESS,
          GET_CATEGORIES_ERROR,
        ],
      },
    });
  }
};

export default getAllCategories;
