import { SEGMENT_OF_CATEGORIES } from '../../redux/constants';

const isCategoriesPage = (asPath: string) => {
  const [path] = asPath.split('?');
  // 0 - root; 1 - lang; 2 - page;
  const segments = path.split('/');
  const pageSegment = segments[2] || '';
  return pageSegment.toLowerCase() === SEGMENT_OF_CATEGORIES;
};

export default isCategoriesPage;
