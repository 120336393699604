import { FetchConfig } from 'src/redux/apiTypes';
import { withMemcached } from 'src/utils/ApiCacheClient/withMemcached';
import { ChainDto } from './types';
import transformDtoToChain from './utils/transformDtoToChain';

export async function getChainSSR(fetchConfig: FetchConfig) {
  const { apiRoot, xChain, language, xVersion } = fetchConfig;

  const { result } = await withMemcached(async () => {
    const url = `${apiRoot}/retail_chains/${xChain}`;

    const options: RequestInit = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'x-version': xVersion,
      },
    };

    const res = await fetch(url, options);

    if (res.ok) {
      return await res.json() as ChainDto;
    }
  }, { cacheKey: `chain-${xChain}-${language}` });

  const chain = transformDtoToChain(result);

  return chain;
}
