import { FetchConfig } from '../../../redux/apiTypes';
import { SocialMessenger } from '../Messengers.types';

const requestGetSocialMessengers = async (fetchConfig: FetchConfig): Promise<{
  data: SocialMessenger[];
  error: boolean;
}> => {
  const { apiRoot, language, xVersion } = fetchConfig;
  try {
    const response = await window.fetch(`${apiRoot}/about/contacts`, {
      headers: {
        'Accept-language': language,
        'x-version': xVersion,
      },
    });
    const data: SocialMessenger[] = await response.json();

    if (response.ok) {

      return {
        data,
        error: false,
      };
    }

    return {
      data: [],
      error: true,
    };
  } catch (err) {

    return {
      data: [],
      error: true,
    };
  }
};

export default requestGetSocialMessengers;
