import * as Sentry from '@sentry/nextjs';
import { openModal } from '../../redux/modals';

const unexpectedErrorMiddleware = store => next => action => {
  const { payload, error, type } = action;

  if (!error) {
    return next(action);
  }

  const { status } = payload;

  if (
    !status ||
    status <= 400 ||
    status >= 600 ||
    status === 401 ||
    status === 403 ||
    status === 412 ||
    status === 429
  ) {
    return next(action);
  }

  if (status >= 500) {
    const { dispatch } = store;
    dispatch(openModal('unexpectedErrorModal', { eventId: undefined }));
    return next(action);
  }

  const { dispatch } = store;

  const eventId = Sentry.captureException(new Error(`Unexpected API error occurred during '${type}' request.`), {
    extra: {
      action: JSON.stringify(action),
    },
  });

  dispatch(openModal('unexpectedErrorModal', { eventId }));

  return next(action);
};

export default unexpectedErrorMiddleware;
