import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../apiTypes';
import { RootState } from '../reducers';
import { UserState } from './userTypes';

const postLogoutRequest = createAction('user/postLogoutRequest');
const postLogoutSuccess = createAction('user/postLogoutSuccess');
const postLogoutError = createAction<RSAAActionErrorPayload>('user/postLogoutError');

const requestPostLogout =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const { fetchConfig } = selectAppConfig(state);
    const { xChain, xVersion, apiRoot } = fetchConfig;

    return dispatch(
      createRsaaAction<RootState, undefined, undefined>({
        method: 'POST',
        credentials: 'include',
        endpoint: `${apiRoot}/user/logout/`,
        headers: {
          'x-chain': xChain,
          'x-version': xVersion,
        },
        types: [
          postLogoutRequest.type,
          postLogoutSuccess.type,
          postLogoutError.type,
        ],
      }),
    );
  };

export default requestPostLogout;

export function addPostLogoutCases(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(postLogoutRequest, state => {
    state.isFetching.logout = true;
  }).addCase(postLogoutSuccess, (state) => {
    state.isFetching.logout = false;
    state.isLoginChecked = true;
    state.isLoggedIn = false;
    state.error.logout = [];
  }).addCase(postLogoutError, (state, action) => {
    state.isFetching.logout = false;
    state.error.logout = getResponseErrors(action.payload);
  });
}
