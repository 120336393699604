import css from 'styled-jsx/css';

export const styles = css`
    .LoadingBarIndicator {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      width: 100%;
      height: 3px;
      background-color: var(--uiColors-LoadingBarIndicator-background);
      animation-name: animate-progress-bar;
      animation-duration: 2s;
      animation-timing-function: ease-in;
      animation-iteration-count: infinite;
      z-index: 1000;
    }
    .LoadingBarIndicator_isUnderContainer {
      top: auto;
      bottom: 0;
    }
    @-moz-keyframes animate-progress-bar {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
    @-webkit-keyframes animate-progress-bar {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
    @-o-keyframes animate-progress-bar {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
    @keyframes animate-progress-bar {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  `;
