import saveCart from '../../../../utils/cart/saveCart';

import { CartItem, CartState } from '../cartTypes';
import { RootState } from '../../../reducers';
import { Dispatch } from 'redux';
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

const setCartItemsAC = createAction<CartItem[]>('general/setCartItems');

export const setCartItems = (items: CartItem[], skipSave = false) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { cartType } = getState().app;

  if (cartType === 'client' && !skipSave) {
    saveCart(items);
  }

  dispatch(setCartItemsAC(items));
};

export const addSetCartItemsCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(setCartItemsAC, (state, { payload }) => {
      state.cart = payload;
    });
};
