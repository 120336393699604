import Head from 'next/head';
import { useRouter } from 'next/router';
import useToggles from 'src/services/toggles/useToggles';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import getAbsoluteUrl from '../../../utils/api/getAbsoluteUrl';
import defineRobotsAndCanonicalMode from '../../../utils/marketing/defineRobotsAndCanonicalMode/defineRobotsAndCanonicalMode';

export const getCanonicalHref = (fullUrl: string, withGetParam: boolean) => {
  if (!withGetParam) {
    return fullUrl;
  }
  return fullUrl.split('?')[0];
};

const RobotsAndCanonicalModeManager = () => {
  const router = useRouter();
  const withGetParam = router.asPath.includes('/?');

  const { host } = useAppConfig();
  const { WITH_NOINDEX_NOFOLLOW } = useToggles();

  const {withCanonicalTag, noIndexNoFollow} = defineRobotsAndCanonicalMode({ urlStr: router.asPath, router });

  return (
    <Head>
      {withCanonicalTag &&
        <link
          rel="canonical"
          key="canonical"
          href={getCanonicalHref(getAbsoluteUrl(router.asPath, host).fullUrl, withGetParam)}
        />
      }
      {(noIndexNoFollow || WITH_NOINDEX_NOFOLLOW) &&
        <meta name="robots" content="noindex, nofollow" />
      }
    </Head>
  );
};

export default RobotsAndCanonicalModeManager;
