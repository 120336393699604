export const lgWidth = 1366;
export const mdWidth = 1240;
export const smWidth = 1000;
export const tabletWidth = 768;
export const phoneWidth = 540;
export const mdPhoneWidth = 375;
export const smPhoneWidth = 320;

export const lg = `(min-width: ${lgWidth}px)`;
export const md = `(min-width: ${mdWidth}px) and (max-width: ${lgWidth - 1}px)`;
export const lg_md = `(min-width: ${mdWidth}px)`;
export const sm = `(min-width: ${smWidth}px) and (max-width: ${mdWidth - 1}px)`;
export const sm_md = `(min-width: ${smWidth}px) and (max-width: ${lgWidth - 1}px)`;
export const desktop = `(min-width: ${smWidth}px)`;
export const tablet_md = `(min-width: ${tabletWidth}px) and (max-width: ${mdWidth - 1}px)`;
export const tablet_md_phone = `(max-width: ${mdWidth - 1}px)`;
export const tablet_md_desktop = `(hover: none) and (pointer: coarse) and (min-width: ${tabletWidth}px)`;
export const tablet = `(min-width: ${tabletWidth}px) and (max-width: ${smWidth - 1}px)`;
export const phone = `(max-width: ${tabletWidth - 1}px)`;
export const phone_sm = `(max-width: ${mdPhoneWidth - 1}px)`;

export const tablet_desktop = `(min-width: ${tabletWidth}px)`;
export const phone_tablet = `(max-width: ${smWidth - 1}px)`;
export const phone_portrait = `(max-width: ${phoneWidth - 1}px)`;

export const not_lg = `(max-width: ${lgWidth - 1}px)`;
export const not_md = `(max-width: ${mdWidth - 1}px), (min-width: ${lgWidth}px)`;
export const not_lg_md = `(max-width: ${mdWidth - 1}px)`;
export const not_sm = `(max-width: ${smWidth - 1}px), (min-width: ${mdWidth}px)`;
export const not_desktop = `(max-width: ${smWidth - 1}px)`;
export const not_tablet_md = `(max-width: ${tabletWidth - 1}px), (min-width: ${mdWidth}px)`;
export const not_tablet = `(max-width: ${tabletWidth - 1}px), (min-width: ${smWidth}px)`;
export const not_phone = `(min-width: ${tabletWidth}px)`;

export const not_tablet_desktop = `(max-width: ${tabletWidth - 1}px)`;
export const not_phone_tablet = `(min-width: ${smWidth}px)`;
export const not_phone_portrait = `(min-width: ${phoneWidth}px)`;
