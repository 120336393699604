import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import getClosestSlot from 'src/modules/ScheduleDelivery/helpers/getClosestSlot';
import selectAddressCoords from 'src/redux/cart/general/selectAddressCoords';
import useToggles from 'src/services/toggles/useToggles';
import useScheduleDeliveryProps from 'src/swr/useScheduleDelivery/useScheduleDeliveryProps';
import usePrevious from '../../../hooks/usePrevious/usePrevious';
import { useAppSelector } from '../../../redux/hooks';
import { openModal } from '../../../redux/modals';
import useScheduleDelivery from '../../../swr/useScheduleDelivery/useScheduleDelivery';

const NoAvailableSlotsModalWatcher = () => {
  const dispatch = useDispatch();
  const { CHAIN_HIDDEN } = useToggles();
  const scheduleDeliveryProps = useScheduleDeliveryProps();
  const { scheduleDeliveryList, wasLoaded, error} = useScheduleDelivery(scheduleDeliveryProps);
  const closestSlot = getClosestSlot(scheduleDeliveryList);
  const [modalWasOpened, setModalWasOpened] = useState(false);
  const addressCoords = useAppSelector(selectAddressCoords);
  const prevAddressCoords = usePrevious(addressCoords);
  const addressExists = !!addressCoords;
  const noAvailableSlots = (scheduleDeliveryList.length === 0 || error || closestSlot === null) && wasLoaded;

  useEffect(() => {
    if (!CHAIN_HIDDEN && addressExists && noAvailableSlots && !modalWasOpened) {
      dispatch(openModal('noAvailableSlotsModal'));
      setModalWasOpened(true); //add wasOpened flag to don't show after changed cart items
    }
  }, [CHAIN_HIDDEN, dispatch, noAvailableSlots, modalWasOpened, addressExists]);

  useEffect(() => {
    //reset state if address changed to show modal again
    if(modalWasOpened && (prevAddressCoords !== addressCoords)){
      setModalWasOpened(false);
    }
  }, [modalWasOpened, addressCoords, prevAddressCoords]);

  return null;
};

export default NoAvailableSlotsModalWatcher;
