import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { RSAAActionErrorPayload } from 'src/redux/apiTypes';
import { RootState } from 'src/redux/reducers';
import { getResponseErrors } from 'src/utils/api/getResponseErrors';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartRecipient, ServerCartState } from './types';

const postRecipientRequest = createAction('serverCart/postRecipientRequest');
export const postRecipientSuccess = createAction<ServerCartData>('serverCart/postRecipientSuccess');
const postRecipientError = createAction<RSAAActionErrorPayload>('serverCart/postRecipientError');

const requestPostRecipient = (recipient: ServerCartRecipient) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/recipient/`,
      body: JSON.stringify({ recipient }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postRecipientRequest.type,
        {
          type: postRecipientSuccess.type,
          payload: getSuccessPayload,
        },
        postRecipientError.type,
      ],
    }),
  );
};

export default requestPostRecipient;

export function addPostRecipientCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postRecipientRequest, (state) => {
      state.statusMap.postRecipient = 'loading';
      state.errorMap.postRecipient = null;
    })
    .addCase(postRecipientSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postRecipient = 'succeeded';
      state.errorMap.postRecipient = null;
      state.errors = null;
    })
    .addCase(postRecipientError, (state, action) => {
      state.statusMap.postRecipient = 'failed';
      state.errorMap.postRecipient = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
