import LoadingRing from '../Loaders/LoadingRing/LoadingRing';
import { styles } from './Loader.styles';

export default function Loader() {
  return (
    <div className='Loader'>
      <LoadingRing
        color='var(--uiColors-Button-accentedText)'
        thickness='2'
        size='60'
      />

      <style jsx>{styles}</style>
    </div>
  );
}
