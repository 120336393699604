const { promisify } = require('util');

let instance;

export const getInstance = () => {
  if (instance) {
    return instance;
  }
  const cacheAddress = process.env.CACHE_ADDRESS;
  if(!cacheAddress || typeof window !== "undefined") {
    instance = {
      get: () => Promise.resolve(null),
      set: () => Promise.resolve(null),
      flush: () => Promise.resolve(null),
    };
    return instance;
  }
  if (cacheAddress && typeof window === 'undefined') {
    const Memcached = require("memcached");
    const connector = new Memcached(
      cacheAddress,
      {
        failures: 10,
        retry: 200,
        retries: 2,
        timeout: 1000,
        minTimeout: 1,
        maxTimeout: 1000,
        reconnect: 100,
        maxValue: 6291456,
      },
    );
    connector.on('issue', details => {
      console.log(`MCACHE ISSUE: ${details.messages.join(', ')}`);
    });
    instance = {
      get: promisify(connector.get).bind(connector),
      set: promisify(connector.set).bind(connector),
      flush: promisify(connector.flush).bind(connector),
    };
    return instance;
  }
};
