import Cookies from 'js-cookie';

const setStoreIdCookie = (storeId: string): void => {
  if (storeId && storeId !== 'undefined') {
    Cookies.set('storeId', storeId, { expires: 365 });
  } else {
    Cookies.remove('storeId');
  }

};

export default setStoreIdCookie;
