import { ApiErrorShape, RSAAActionErrorPayload } from "../../redux/apiTypes";

export const getResponseErrors = (payload: RSAAActionErrorPayload): ApiErrorShape[] => {
  if(payload.response) {
    return payload.response.errors;
  }

  return [{
    error_code: 5000,
    description: 'Unexpected Error',
  }];
};
