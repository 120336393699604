import { useEffect } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { Country, Language } from '../../utils/appConfig/getInitialAppConfig';

interface ZendeskWidgetProps {
  zendeskKey: string;
  language: Language;
  country: Country;
  chatIsOpen: boolean;
  shouldBeHidden: boolean;
  onCloseChat(): void;
}

type GroupType = 'Chat_UA' | 'Chat_MD';

const groupsMap: Record<Country, GroupType> = {
  ua: 'Chat_UA',
  md: 'Chat_MD',
};

const ZendeskWidget = (props: ZendeskWidgetProps) => {
  const {
    zendeskKey,
    language,
    country,
    chatIsOpen,
    shouldBeHidden,
    onCloseChat,
  } = props;

  useEffect(() => {
    if (chatIsOpen) {
      if (shouldBeHidden) {
        ZendeskAPI('webWidget', 'hide');
      } else {
        ZendeskAPI('webWidget', 'show');
        ZendeskAPI('webWidget', 'open');
      }
    }
  }, [chatIsOpen, shouldBeHidden]);

  const handleLoadZendeskApi = () => {
    ZendeskAPI('webWidget', 'setLocale', language);
    // for hide the native launcher button
    ZendeskAPI('webWidget', 'hide');
    ZendeskAPI('webWidget:on', 'close', () => {
      // for hide the native launcher button
      ZendeskAPI('webWidget', 'hide');
      onCloseChat();
    });
  };

  const groupType = groupsMap[country];

  return (
    <Zendesk
      defer
      zendeskKey={zendeskKey}
      onLoaded={handleLoadZendeskApi}
      webWidget={{
        zIndex: 299, //less than cart popup
        chat: {
          departments: {
            select: groupType,
            enabled: [groupType],
          },
        },
      }}
      messenger={{
        zIndex: 299, //less than cart popup
      }}
    />
  );
};

export default ZendeskWidget;
