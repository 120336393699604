import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { SearchInitialState } from "./types";

const resetSearchedProducts = createAction('search/resetSearchedProducts');

export default resetSearchedProducts;

export function addResetSearchedProducts(builder: ActionReducerMapBuilder<SearchInitialState>) {
  builder.addCase(resetSearchedProducts, (state) => {
    state.products = [];
  });
}
