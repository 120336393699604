import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import getMarketingDataForCheckout from '../../../utils/marketing/getMarketingDataForCheckout';
import { OrderDetailInfo, RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import { ServerCartState } from './types';

const postCartCheckoutRequest = createAction('serverCart/postCartCheckoutRequest');
const postCartCheckoutSuccess = createAction<OrderDetailInfo[]>('serverCart/postCartCheckoutSuccess');
const postCartCheckoutError = createAction<RSAAActionErrorPayload>('serverCart/postCartCheckoutError');

const requestPostCartCheckout = (etag: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig, host } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const requestBody = {
    skip_agreements: true,
    marketing: getMarketingDataForCheckout(language, host),
  };

  return dispatch(
    createRsaaAction<RootState, OrderDetailInfo[], undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/checkout/`,
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
        'If-Match': etag,
      },
      body: JSON.stringify(requestBody),
      types: [
        postCartCheckoutRequest.type,
        postCartCheckoutSuccess.type,
        postCartCheckoutError.type,
      ],
    }),
  );
};

export default requestPostCartCheckout;

export const resetOrderData = createAction('serverCart/resetOrderData');

export function addPostCartCheckoutCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCartCheckoutRequest, (state) => {
      state.statusMap.postCartCheckout = 'loading';
      state.errorMap.postCartCheckout = null;
    })
    .addCase(postCartCheckoutSuccess, (state, action) => {
      state.orderDataArr = action.payload;
      state.statusMap.postCartCheckout = 'succeeded';
      state.errorMap.postCartCheckout = null;
      state.errors = null;
    })
    .addCase(postCartCheckoutError, (state, action) => {
      state.statusMap.postCartCheckout = 'failed';
      state.errorMap.postCartCheckout = action.payload;
      state.errors = getResponseErrors(action.payload);
    })
    .addCase(resetOrderData, (state) => {
      state.orderDataArr = null;
      state.statusMap.postCartCheckout = 'idle';
    });
}
