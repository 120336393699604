import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { ServerCartState } from './types';

const clearCouponError = createAction('serverCart/clearCouponError');

export default clearCouponError;

export function addClearCouponErrorCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder.addCase(clearCouponError, state => {
    state.errorMap.postCoupon = null;
  });
}
