import { CartType, ReduxType } from './appReducers';
import { SET_BASE_URL, SET_CART_TYPE, SET_LANGUAGE, SET_REDUX_TYPE } from './appTypes';

export const setLanguage = (language: string) => {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
};

export const setBaseURL = (baseURL: string, host: string) => {
  return {
    type: SET_BASE_URL,
    payload: { baseURL, host },
  };
};

export const setReduxType = (reduxType: ReduxType) => {
  return {
    type: SET_REDUX_TYPE,
    payload: reduxType,
  };
};

export const setCartType = (cartType: CartType) => {
  return {
    type: SET_CART_TYPE,
    payload: cartType,
  };
};
