import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartReplacements, ServerCartState } from './types';

const postReplacementsRequest = createAction('serverCart/postReplacementsRequest');
export const postReplacementsSuccess = createAction<ServerCartData>('serverCart/postReplacementsSuccess');
const postReplacementsError = createAction<RSAAActionErrorPayload>('serverCart/postReplacementsError');

const requestPostReplacements = (replacements: ServerCartReplacements) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/replacements/`,
      body: JSON.stringify({ replacements }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postReplacementsRequest.type,
        {
          type: postReplacementsSuccess.type,
          payload: getSuccessPayload,
        },
        postReplacementsError.type,
      ],
    }),
  );
};

export default requestPostReplacements;

export function addPostReplacementsCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postReplacementsRequest, (state) => {
      state.statusMap.postReplacements = 'loading';
      state.errorMap.postReplacements = null;
    })
    .addCase(postReplacementsSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postReplacements = 'succeeded';
      state.errorMap.postReplacements = null;
      state.errors = null;
    })
    .addCase(postReplacementsError, (state, action) => {
      state.statusMap.postReplacements = 'failed';
      state.errorMap.postReplacements = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
