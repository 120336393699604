import { Fragment } from 'react';

interface Props {
  color?: string;
  size?: string;
  thickness?: string;
}

function LoadingRing(props: Props) {
  const { color = 'white', size = '30', thickness = '4' } = props;

  const stroke = color;

  return (
    <Fragment>
      <div
        className='LoadingRing'
        role='status'
        aria-live='polite'
        aria-label='Loading...'
        data-testid="spinner"
      >
        <svg className='LoadingRing__circularLoader' viewBox='25 25 50 50'>
          <circle
            className='LoadingRing__loaderPath'
            cx='50'
            cy='50'
            r='20'
            fill='none'
            stroke={color}
            strokeWidth={thickness}
          />
        </svg>
      </div>

      <style jsx>{`
        .LoadingRing {
          position: relative;
          margin: 0px auto;
          width: ${size + 'px'};
          height: ${size + 'px'};
        }

        .LoadingRing:before {
          content: '';
          display: block;
          padding-top: 100%;
        }

        .LoadingRing__circularLoader {
          -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
          height: 100%;
          -webkit-transform-origin: center center;
          -ms-transform-origin: center center;
          transform-origin: center center;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          margin: auto;
        }

        .LoadingRing__loaderPath {
          stroke-dasharray: 150, 200;
          stroke-dashoffset: -10;
          -webkit-animation: dash 1.5s ease-in-out infinite,
            color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite,
            color 6s ease-in-out infinite;
          stroke-linecap: round;
        }

        @-webkit-keyframes rotate {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        @keyframes rotate {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        @-webkit-keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }

        @-webkit-keyframes color {
          0% {
            stroke: ${stroke};
          }
          40% {
            stroke: ${stroke};
          }
          66% {
            stroke: ${stroke};
          }
          80%,
          90% {
            stroke: ${stroke};
          }
        }

        @keyframes color {
          0% {
            stroke: ${stroke};
          }
          40% {
            stroke: ${stroke};
          }
          66% {
            stroke: ${stroke};
          }
          80%,
          90% {
            stroke: ${stroke};
          }
        }
      `}</style>
    </Fragment>
  );
}

export default LoadingRing;
