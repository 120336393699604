import { Dispatch, combineReducers } from 'redux';
import { RSAAResultAction } from 'redux-api-middleware';
import { InitialAppConfig } from 'src/utils/appConfig/getInitialAppConfig';
import addressManagement from './addressManagement/addressManagement';
import analytics from './analytics/analyticsReducers';
import app from './app/appReducers';
import initialAppConfig from './appConfig/appConfigReducers';
import cart from './cart';
import serverCart from './cart/serverCart';
import catalogue from './catalogue/catalogueReducers';
import categories from './categories/categoriesReducers';
import modals from './modals';
import product from './product';
import referralProgram from './referralProgram';
import search from './search';
import translations from './translations/translationsReducers';
import user from './user/userReducer';
import userProfile from './userProfile/userProfileReducers';

const rootReducer = combineReducers({
  addressManagement,
  analytics,
  app,
  cart,
  catalogue,
  categories,
  initialAppConfig,
  modals,
  product,
  referralProgram,
  search,
  serverCart,
  translations,
  user,
  userProfile,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer> & {
  /** @deprecated Use appConfigSelector */
  initialAppConfig: InitialAppConfig;
};

export type ReduxThunkAction<T = RSAAResultAction> = (
  dispatch: Dispatch, getState: () => RootState
) => Promise<T>;
