import { IncomingMessage, ServerResponse } from 'http';
import { ParsedUrlQuery } from 'querystring';
import { ExperimentsMap } from 'src/modules/Experiments';
import { Skin } from 'src/modules/Theme/Theme';
import { APP_INSTANCE_ID, LATEST_API_VERSION } from 'src/redux/constants';
import getQueryApiRoot from './getQueryApiRoot';
import getRawConfig from './getRawConfig';

export type Country = 'ua' | 'md';
export type Language = 'uk' | 'ru' | 'en' | 'ro';
export type Currency = 'uah' | 'mdl';

export interface InitialAppConfig {
  imgRoot: string;
  /**
   * True host name
   * - `metro.zakaz.ua`
   * - `auchan.zakaz.ua`
   */
  host: string;
  /**
   * Chain name
   * - `metro`
   * - `auchan`
   */
  chain: string;
  /** Skin name */
  skin: Skin;
  /** Available languages */
  languages: Language[];
  /** Default language */
  defaultLanguage: Language;
  /** Currency name */
  currency: Currency,
  /** Country name */
  country: Country;
  /**
   * Country calling code
   * - `+380`
   * - `+373`
   */
  countryCallingCode: string;
  /**
   * Site title
   * - `Metro`
   * - `Auchan`
   */
  siteTitle: string;
  /** ID of every instance of started App */
  APP_INSTANCE_ID: string;
  settings: {
    DEFAULT_PRODUCTS_PER_PAGE: number;
    DEFAULT_VISIBLE_FILTERS_COUNT: number;
    RECOMMENDED_PRODUCTS_COUNT: number;
    MIN_SEARCH_LENGTH: number;
    MAP_TILE_LAYER_GOOGLE_URL: string | null;
    MAP_TILE_LAYER_VISICOM_URL: string | null;
    MAP_TILE_LAYER_OSM_URL: string | null;
    /** Google recaptcha public API key */
    RECAPTCHA_PUBLIC_KEY: string | null;
  };
  api: {
    /** Used for x-version fetch header */
    API_VERSION: string;
    /** API root URL, used on SSR only */
    SSR_API_ROOT: string | null;
    /** API root URL */
    PUBLIC_API_ROOT: string;
    /** API root URL from query param `?api=api_url` */
    QUERY_API_ROOT: string | null;
  };
  marketing: {
    /** Android app id for Google Play */
    ANDROID_APP_ID: string | null;
    /** iOS app id for App Store */
    IOS_APP_ID: string | null;
    /** Facebook ID */
    FB_APP_ID: string | null;
    /** Zendesk public API key */
    ZENDESK_KEY: string | null;
    GOOGLE_SITE_VERIFICATION: string | null;
    /** GTM public API key, for every chain */
    CHAIN_GTM_ID: string | null;
    /** GTM public API key, for all chains */
    CROSS_DOMAIN_GTM_ID: string | null;
    /** GTM public API key, for metro only */
    METRO_OWN_GTM_ID: string | null;
    LOAD_SCRIPT_DELAY?: string | null;
  };
  experiments: ExperimentsMap;
}

export default async function getInitialAppConfig(
  query: ParsedUrlQuery,
  req: IncomingMessage,
  res: ServerResponse,
): Promise<InitialAppConfig> {
  const queryApiRoot = getQueryApiRoot(query.api as string, req, res);
  const host = req.headers.host;

  const rawConfig = await getRawConfig(host);

  if (!rawConfig) {
    res.statusCode = 404;
    res.end();
    return null;
  }

  return {
    imgRoot: rawConfig.IMG_ROOT,
    host,
    chain: rawConfig.CHAIN,
    skin: rawConfig.SKIN,
    languages: rawConfig.LANGUAGES,
    defaultLanguage: rawConfig.DEFAULT_LANG,
    currency: rawConfig.CURRENCY,
    country: rawConfig.COUNTRY,
    countryCallingCode: rawConfig.COUNTRY_CODE,
    siteTitle: rawConfig.SITE_TITLE,
    APP_INSTANCE_ID,
    settings: {
      DEFAULT_PRODUCTS_PER_PAGE: rawConfig.DEFAULT_PRODUCTS_PER_PAGE,
      DEFAULT_VISIBLE_FILTERS_COUNT: rawConfig.DEFAULT_VISIBLE_FILTERS_COUNT,
      RECOMMENDED_PRODUCTS_COUNT: rawConfig.RECOMMENDED_PRODUCTS_COUNT,
      MIN_SEARCH_LENGTH: rawConfig.MIN_SEARCH_LENGTH,
      MAP_TILE_LAYER_GOOGLE_URL: rawConfig.MAP_TILE_LAYER_GOOGLE_URL || null,
      MAP_TILE_LAYER_VISICOM_URL: rawConfig.MAP_TILE_LAYER_VISICOM_URL || null,
      MAP_TILE_LAYER_OSM_URL: rawConfig.MAP_TILE_LAYER_OSM_URL || null,
      RECAPTCHA_PUBLIC_KEY: rawConfig.RECAPTCHA_PUBLIC_KEY || null,
    },
    api: {
      SSR_API_ROOT: rawConfig.SSR_API_ROOT || null,
      PUBLIC_API_ROOT: rawConfig.PUBLIC_API_ROOT,
      API_VERSION: LATEST_API_VERSION,
      QUERY_API_ROOT: queryApiRoot,
    },
    marketing: {
      ANDROID_APP_ID: rawConfig.ANDROID_APP_ID || null,
      IOS_APP_ID: rawConfig.IOS_APP_ID || null,
      FB_APP_ID: rawConfig.FB_APP_ID|| null,
      ZENDESK_KEY: rawConfig.ZENDESK_KEY || null,
      GOOGLE_SITE_VERIFICATION: rawConfig.GOOGLE_SITE_VERIFICATION || null,
      CHAIN_GTM_ID: rawConfig.CHAIN_GTM_ID || null,
      CROSS_DOMAIN_GTM_ID: rawConfig.CROSS_DOMAIN_GTM_ID || null,
      METRO_OWN_GTM_ID: rawConfig.METRO_OWN_GTM_ID || null,
      LOAD_SCRIPT_DELAY: rawConfig.LOAD_SCRIPT_DELAY || null,
    },
    experiments: {
      ...rawConfig.experiments,
    },
  };
}
