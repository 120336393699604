import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

const EsputnikUserInfo: FC = () => {
  const phone = useSelector((state: RootState) => state.userProfile.userData.USER_LOGIN_PHONE);
  const email = useSelector((state: RootState) => state.userProfile.userData.USER_LOGIN_EMAIL);

  return (
    <Fragment>
      <span id='EsMail' data-testid='EsMail' style={{display: 'none'}}>{email}</span>
      <span id='EsPhone' data-testid='EsPhone' style={{display: 'none'}}>{phone}</span>
    </Fragment>
  );
};

export default EsputnikUserInfo;
