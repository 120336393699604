import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { v4 as uuid } from 'uuid';
import { CartItem, CartState } from "../general/cartTypes";

export const updateClientCartItems = createAction<CartItem[]>('clientCart/updateClientCartItems');

export const addUpdateClientCartItemsCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(updateClientCartItems, (state, { payload }) => {
      state.cart = payload;
      state.dataSignature = uuid();
    });
};
