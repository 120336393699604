import { HandlerFunc } from '../getRedirectPath';

const handlePromotions: HandlerFunc = async ({ path }) => {
  const splitPath = path.split('?');
  const [uri, params] = splitPath;
  const segments = uri.split('/');
  const langSegment = segments[1];
  if (segments.length === 3 && segments[2] === '' && params === 'promotion=1') {
    return ['', langSegment, 'custom-categories' , 'promotions'].join('/');
  }
  return path;
};

export default handlePromotions;
