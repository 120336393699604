const siteRoutes = [
  'account',
  'categories',
  'custom-categories',
  'lists',
  'order',
  'products',
  'service-page',
  'search',
  'thanks',
  'chat',// @todo remove old chat route, after release of https://zakaz.atlassian.net/browse/ZAKAZ-6705
  'uikit',
  'recipes',
  'payment',
  'offers',
  'stores',
  'n',// /notifications/
  'p',// /payment/
  'c',// /chat/
];

export default siteRoutes;
