import { USER_EMAIL } from '../../../redux/constants';
import { useAppSelector } from '../../../redux/hooks';

function ScMail() {
  const userProfileEmail = useAppSelector(state => state.userProfile.userData[USER_EMAIL]);

  if (!userProfileEmail) return null;

  return (
    <div id='ScMail' style={{ display: 'none' }}>
      {userProfileEmail}
    </div>
  );
}

export default ScMail;
