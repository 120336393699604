import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Product } from "src/redux/apiTypes";
import { openModal } from "src/redux/modals";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { useSessionStorage } from "usehooks-ts";
import { NotificationService } from "../notification/types";
import requestGetFavoritesLists from "./ajax/requestGetFavoritesLists";
import requestPostFavoritesItems from "./ajax/requestPostFavoritesItems";
import { addOrRemoveItemFromLists } from "./helpers/addOrRemoveItemFromLists";
import { createEanListMap } from "./helpers/createEanListMap";
import { EanFavoriteListMap, ShoppingList, ShoppingListItemWithListId } from "./types";

interface FavoriteItemParams {
  product: Product;
  listId?: string;
}

export type GetProductIsFavoriteFunc = (ean: string) => boolean;

export interface FavoritesService {
  lists: ShoppingList[];
  listsIsFetching: boolean;
  favoriteEansMap: EanFavoriteListMap;
  revalidateFavoriteLists(): Promise<void>;
  toggleFavorite(params: FavoriteItemParams): void;
  updateItemsInLists(items: ShoppingListItemWithListId[]): void;
  getProductIsFavorite: GetProductIsFavoriteFunc;
}

const useFavoritesService = (
  isLoggedIn: boolean,
  openFavoriteNotification: NotificationService['openFavoriteNotification'],
): FavoritesService => {
  const [isFetching, setIsFetching] = useState(false);
  const [lists, setLists] = useState(null);
  const { fetchConfig } = useAppConfig();
  const [
    favoriteItemParamsFromStorage,
    setFavoriteItemParamsToStorage,
  ] = useSessionStorage<FavoriteItemParams | null>('favoriteParams', null);

  const dispatch = useDispatch();
  const favoriteEansMap = useMemo(() => lists ? createEanListMap(lists) : {}, [lists]);

  const getFavoritesLists = useCallback(async () => {
    setIsFetching(true);
    const response = await requestGetFavoritesLists(fetchConfig);

    setLists(response);
    setIsFetching(false);
  }, [fetchConfig]);

  const toggleFavorite = async (favoriteItemParams: FavoriteItemParams) => {
    if (isLoggedIn) {
      addOrRemoveItemFromLists({
        ...favoriteItemParams,
        fetchConfig,
        favoriteEansMap,
        lists,
        onSuccess: getFavoritesLists,
        openFavoriteNotification,
      });
    } else {
      dispatch(openModal('loginOrRegistrationModal'));
      setFavoriteItemParamsToStorage(favoriteItemParams);
    }
  };

  const getProductIsFavorite = useCallback((ean: string) => !!favoriteEansMap[ean], [favoriteEansMap]);

  useEffect(() => {
    if (isLoggedIn) {
      getFavoritesLists();
    }
  }, [getFavoritesLists, isLoggedIn]);

  useEffect(() => {
    //add product to list after login if product exists in storage
    const listsAreReady = lists?.length > 0;
    const dataAreReady = isLoggedIn
      && favoriteItemParamsFromStorage
      && listsAreReady;

    if (dataAreReady) {
      const ean = favoriteItemParamsFromStorage.product.ean;
      const productIsNotInLists = !favoriteEansMap[ean];

      if (productIsNotInLists) {
        addOrRemoveItemFromLists({
          ...favoriteItemParamsFromStorage,
          fetchConfig,
          favoriteEansMap,
          lists,
          onSuccess: getFavoritesLists,
          openFavoriteNotification,
        });
      }

      setFavoriteItemParamsToStorage(null);
    }

  }, [
    dispatch,
    favoriteEansMap,
    favoriteItemParamsFromStorage,
    fetchConfig,
    getFavoritesLists,
    isLoggedIn,
    lists,
    setFavoriteItemParamsToStorage,
    openFavoriteNotification,
  ]);

  useEffect(() => {
    //reset lists after logout
    if (!isLoggedIn && lists) {
      setLists(null);
    }
  }, [isLoggedIn, lists]);

  const updateItemsInLists = async (items: ShoppingListItemWithListId[]) => {
    await requestPostFavoritesItems({
      items,
      fetchConfig,
    });

    getFavoritesLists();
  };

  return {
    lists,
    listsIsFetching: isFetching,
    favoriteEansMap,
    revalidateFavoriteLists: getFavoritesLists,
    updateItemsInLists,
    getProductIsFavorite,
    toggleFavorite,
  };
};

export default useFavoritesService;
