import { sendUserData } from 'src/utils/marketing/enhancedEcommerce';
import { FIRST_NAME, USER_EMAIL, USER_PHONE_LIST } from '../../constants';
import { RootState } from '../../reducers';
import { resetErrors } from '../userProfileActions';
import { FETCH_USER_PROFILE_SUCCESS } from '../userProfileTypes';
import { fetchUserProfile } from './fetchUserProfile';

export const getUserProfile = () => async (dispatch, getState: () => RootState) => {
  const response = await dispatch(fetchUserProfile());

  if (response.type === FETCH_USER_PROFILE_SUCCESS) {
    dispatch(resetErrors());

    // E-sputnik trigger event
    const { userData } = getState().userProfile;
    const email = userData[USER_EMAIL];
    const firstName = userData[FIRST_NAME];
    const phone = userData[USER_PHONE_LIST]?.[0]?.phone;
    sendUserData({ email, firstName, phone });
  }

  return response;
};
