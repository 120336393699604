import * as Sentry from '@sentry/nextjs';
import { useMemo } from 'react';
import { TranslationDictionary } from './types';


export function useLocalizationService(translations: TranslationDictionary) {

  const localize = useMemo(() => {
    if (!translations) {
      throw new Error('Translations are empty.');
    }

    return (key: string) => {
      const translation = translations[key];

      if (translation) {
        return translation;
      }

      Sentry.captureException(
        new Error(`Missing translation for key '${key}'.`),
      );

      return key;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 return localize;
}
