import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartContacts, ServerCartData, ServerCartState } from './types';

const postCartContactsRequest = createAction<ServerCartContacts>('serverCart/postCartContactsRequest');
export const postCartContactsSuccess = createAction<ServerCartData>('serverCart/postCartContactsSuccess');
const postCartContactsError = createAction<RSAAActionErrorPayload>('serverCart/postCartContactsError');

const requestPostCartContacts = (contacts: ServerCartContacts) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/contacts/`,
      credentials: 'include',
      body: JSON.stringify({ contacts }),
      headers: {
        'Accept-Language': language,
        'x-chain': xChain,
        'x-version': xVersion,
        'Content-Type': 'application/json',
      },
      types: [
        postCartContactsRequest.type,
        {
          type: postCartContactsSuccess.type,
          payload: getSuccessPayload,
        },
        postCartContactsError.type,
      ],
    }),
  );
};

export default requestPostCartContacts;

export function addPostCartContactsCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCartContactsRequest, (state) => {
      state.statusMap.postCartContacts = 'loading';
      state.errorMap.postCartContacts = null;
    })
    .addCase(postCartContactsSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postCartContacts = 'succeeded';
      state.errorMap.postCartContacts = null;
      state.errors = null;
    })
    .addCase(postCartContactsError, (state, action) => {
      state.statusMap.postCartContacts = 'failed';
      state.errorMap.postCartContacts = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
