import { useContext } from 'react';
import ChainContext from './chainContext';
import { Chain } from './types';

export default function useChainService(): Chain {
  const context = useContext(ChainContext);

  if (!context) {
    throw new Error('ChainService is used without provider');
  }

  return context;
}
