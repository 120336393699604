import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { ProductState } from "./types";

const resetFetchProductDataError = createAction('product/resetFetchProductDataError');

export default resetFetchProductDataError;

export function addResetFetchProductDataError(builder: ActionReducerMapBuilder<ProductState>) {
  builder.addCase(resetFetchProductDataError, (state) => {
    state.errorFetchingProductData = false;
  });
}
