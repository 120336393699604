import dynamic from 'next/dynamic';

const auchan = dynamic(() => import('./components/auchan'), { ssr: true });
const cosmos = dynamic(() => import('./components/cosmos'), { ssr: true });
const ekomarket = dynamic(() => import('./components/ekomarket'), { ssr: true });
const kharkiv = dynamic(() => import('./components/kharkiv'), { ssr: true });
const megamarket = dynamic(() => import('./components/megamarket'), { ssr: true });
const metro = dynamic(() => import('./components/metro'), { ssr: true });
const novus = dynamic(() => import('./components/novus'), { ssr: true });
const tavriav = dynamic(() => import('./components/tavriav'), { ssr: true });
const ultramarket = dynamic(() => import('./components/ultramarket'), { ssr: true });
const vostorg = dynamic(() => import('./components/vostorg'), { ssr: true });
const winetime = dynamic(() => import('./components/winetime'), { ssr: true });
const zaraz = dynamic(() => import('./components/zaraz'), { ssr: true });
const masterzoo = dynamic(() => import('./components/masterzoo'), { ssr: true });
const alcohub = dynamic(() => import('./components/alcohub'), { ssr: true });
const biotus = dynamic(() => import('./components/biotus'), { ssr: true });

const styleComponents = {
  auchan,
  cosmos,
  ekomarket,
  kharkiv,
  megamarket,
  metro,
  novus,
  tavriav,
  ultramarket,
  vostorg,
  winetime,
  zaraz,
  masterzoo,
  alcohub,
  biotus,
};

export type Skin = keyof typeof styleComponents;

export interface Props {
  skin: Skin;
}

export default function Theme(props: Props) {
  const { skin } = props;

  const StyleComponent = styleComponents[skin];

  if (!StyleComponent) {
    throw new Error(`StyleComponent was not found for skin - ${skin}`);
  }

  return <StyleComponent />;
}
