import { IncomingMessage } from 'http';
import { DeliveryType } from 'src/redux/apiTypes';
import { HEADER_PROMOTION_CLOSED_COOKIE } from 'src/redux/constants';
import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { parseCookieString } from 'src/utils/js-helpers/parseCookieString';

export interface UserPreferences {
  storeId: 'default' | string;
  language: null | Language;
  clientType: undefined | string;
  deliveryType: null | DeliveryType;
  [HEADER_PROMOTION_CLOSED_COOKIE]: string | null;
}

export const defaultUserPreferencesMap: UserPreferences = {
  storeId: 'default',
  language: null,
  clientType: undefined,
  deliveryType: null,
  [HEADER_PROMOTION_CLOSED_COOKIE]: null,
};

export function getUserPreferencesFromCookies(req: IncomingMessage): UserPreferences {
  const cookie = (req && req.headers && req.headers.cookie) || '';
  const parsedCookies = parseCookieString(cookie);

  return Object.entries(defaultUserPreferencesMap)
    .reduce((acc, [preferenceName, defaultValue]) => {
      acc[preferenceName] = parsedCookies[preferenceName] || defaultValue;

      return acc;
    }, {} as UserPreferences);
}
