import { HandlerFunc } from '../getRedirectPath';

const handleUnderscore: HandlerFunc = async ({ path }) => {
  const [mainUrl, queryParams] = path.split('?');
  const newMainUrl = mainUrl.replaceAll('_', '-');

  const newPath = `${newMainUrl}${queryParams ? `?${queryParams}` : ''}`;

  return newPath;
};

export default handleUnderscore;
