import dynamic from 'next/dynamic';
import { RenderAfterInteract } from 'src/modules/interact-watcher';

const Messengers = dynamic(() => import('./Messengers'), { ssr: false });

export default function MessengersApp() {
  return (
    <RenderAfterInteract>
      <Messengers variant='column' />
    </RenderAfterInteract>
  );
}
