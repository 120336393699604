import { createReducer } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { getResponseErrors } from '../../utils/api/getResponseErrors';
import { ApiErrorShape, Category, CategoryFilterLike, CategoryProducts } from "../apiTypes";
import { addGetB2BProducts } from './categoriesOperations/getB2BProducts';
import { addGetFiltersLikeCategoriesList } from './categoriesOperations/getFiltersLikeCategoriesList';
import { GET_CATEGORIES_ERROR, GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS } from "./categoriesTypes";

export interface CustomCategory {
  isFetching: boolean;
  data: CategoryProducts;
  dataSignature: string;
  error: null | ApiErrorShape[];
}

export interface CategoriesReducersState {
  isFetching: boolean;
  dataSignature: string;
  error: ApiErrorShape[];
  categories: Category[];
  filtersLikeCategories: CategoryFilterLike[];
  redirect: boolean;
  b2bProducts: CustomCategory;
}

export const customCategoryInitialState: CustomCategory = {
  dataSignature: null,
  isFetching: false,
  data: {
    categories: [],
    count: 0,
    filters: [],
    results: [],
    count_available: 0,
    stats: {
      price: {
        min: 0,
        max: 0,
      },
    },
  },
  error: null,
};

export const initialCategoriesState: CategoriesReducersState = {
  isFetching: false,
  dataSignature: null,
  error: null,
  categories: [],
  redirect: false,
  filtersLikeCategories: [],
  b2bProducts: { ...customCategoryInitialState },
};

const actionHandlers = {
  [GET_CATEGORIES_REQUEST]: state => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [GET_CATEGORIES_SUCCESS]: (state, action) => {
    const { payload } = action;
    const allCategories = payload;

    if (Array.isArray(allCategories)) {
      return {
        ...state,
        isFetching: false,
        dataSignature: v4(),
        categories: allCategories,
      };
    }

    return {
      ...state,
      isFetching: false,
    };
  },
  [GET_CATEGORIES_ERROR]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isFetching: false,
      error: getResponseErrors(payload),
    };
  },
};

const reducer = createReducer(initialCategoriesState, builder => {
  addGetFiltersLikeCategoriesList(builder);
  addGetB2BProducts(builder);
  builder.addDefaultCase((state, action) => actionHandlers[action.type]?.(state, action) || state);
});

export default reducer;
