export const typographyJSStyles = {
  h1: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '36px',
    lineHeight: '40px',
    color: '#303030',
  },
  h2: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#303030',
  },
  h3: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#303030',
  },
  h4: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#303030',
  },
  h5: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#303030',
  },
  h6: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#303030',
  },
  h9: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#303030',
  },
  text: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color:' #303030',
  },
  smallAccentedText: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color:' #303030',
  },
  notAccentedText: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#9F9F9F',
  },
  notAccentedH9: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#9F9F9F',
  },
  btn: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
  },
  h1Mobile: {
    fontFamily: 'Mulish, Verdana, Arial, sans-serif',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '28px',
    lineHeight: '34px',
    color: '#303030',
  },
};

export function transformProps(propertyName) {
  function upperToHyphenLower(match) {
    return '-' + match.toLowerCase();
  }
  return propertyName.replace(/[A-Z]/g, upperToHyphenLower);
}

export function createCSSStyles(obj) {
  const result = {};
  for (const key in obj) {
    result[key] = '';
    for (const prop in obj[key]) {
      result[key] += `${transformProps(prop)}:${obj[key][prop]};`;
    }
  }
  return result;
}

const typography = createCSSStyles(typographyJSStyles) as typeof typographyJSStyles;
export default typography;
