import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import preloadImages from "src/utils/images/preloadImages";
import { SocialMessenger, ZendeskButtonProps, ZendeskWidgetProps } from "../Messengers.types";
import requestGetSocialMessengers from "../api/requestGetSocialMessengers";
import { ListOfMessengersProps, MessengersContextValue } from "./MessengersContext";

export const excludedRoutes = [
  '/[lang]/order/checkout',
  '/[lang]/c/[orderId]',
  '/[lang]/products/[token]',
];

export const useMessengersContextLogic = (): MessengersContextValue => {
  const [socials, setSocials] = useState<SocialMessenger[]>([]);
  const [socialsAreReady, setSocialsAreReady] = useState(false);
  const [listOfMessengersIsOpen, setListOfMessengersIsOpen] = useState(false);
  const [zendeskIsOpen, setZendeskIsOpen] = useState(false);

  const { route } = useRouter();
  const { fetchConfig, country, marketing } = useAppConfig();

  const language = fetchConfig.language;
  const { ZENDESK_KEY } = marketing;
  const messengersShouldBeHidden = excludedRoutes.includes(route);

  // getting social messengers
  useEffect(() => {
    (async () => {
      const { data, error } = await requestGetSocialMessengers(fetchConfig);

      if (!error) {
        await preloadImages(data.map(m => m.img_url));
        setSocials(data);
      }

      setSocialsAreReady(true);
    })();
  }, [fetchConfig]);

  const closeListOfMessengers = () => {
    setListOfMessengersIsOpen(false);
  };

  const handleCloseZendeskChat = () => {
    setZendeskIsOpen(false);
  };

  const handleClickZendeskChat = () => {
    setZendeskIsOpen(true);
    closeListOfMessengers();
  };

  const handleClickLauncherButton = () => {
    setListOfMessengersIsOpen(true);
  };

  const zendeskWidgetProps: ZendeskWidgetProps = {
    zendeskKey: ZENDESK_KEY,
    language,
    chatIsOpen: zendeskIsOpen,
    onCloseChat: handleCloseZendeskChat,
    country,
    shouldBeHidden: messengersShouldBeHidden,
  };

  const zendeskButtonProps: ZendeskButtonProps = {
    zendeskKey: ZENDESK_KEY,
    onClick: handleClickZendeskChat,
    dataMarkerValue: 'Zendesk Button',
  };

  const listOfMessengersProps: ListOfMessengersProps = {
    isOpen: listOfMessengersIsOpen,
    close: closeListOfMessengers,
    open: handleClickLauncherButton,
  };

  return {
    socials,
    messengersShouldBeHidden: messengersShouldBeHidden || !socialsAreReady,
    zendeskWidgetProps: ZENDESK_KEY ? zendeskWidgetProps : undefined,
    zendeskButtonProps: ZENDESK_KEY ? zendeskButtonProps : undefined,
    listOfMessengersProps,
  };
};

export default useMessengersContextLogic;
