import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartState } from './types';

const postCartClearRequest = createAction('serverCart/postCartClearRequest');
const postCartClearSuccess = createAction<ServerCartData>('serverCart/postCartClearSuccess');
const postCartClearError = createAction<RSAAActionErrorPayload>('serverCart/postCartClearError');

const requestPostCartClear = () => (dispatch, getState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/clear/`,
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postCartClearRequest.type,
        {
          type: postCartClearSuccess.type,
          payload: getSuccessPayload,
        },
        postCartClearError.type,
      ],
    }),
  );
};

export default requestPostCartClear;

export function addPostCartClearCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCartClearRequest, (state) => {
      state.data.delivery = null;
      state.statusMap.postCartClear = 'loading';
      state.errorMap.postCartClear = null;
    })
    .addCase(postCartClearSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postCartClear = 'succeeded';
      state.errorMap.postCartClear = null;
    })
    .addCase(postCartClearError, (state, action) => {
      state.data.delivery = null;
      state.statusMap.postCartClear = 'failed';
      state.errorMap.postCartClear = action.payload;
    });
}
