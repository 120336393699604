import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { sendOpenSearch } from 'src/utils/marketing/enhancedEcommerce';
import { disableBodyScroll, enableBodyScroll } from '../../utils/system/scrollLock';
import { SearchInitialState } from './types';

const setSearchBoxIsActiveAction = createAction<boolean>('search/setSearchBoxIsActive');

const setSearchBoxIsActive = (newSearchBoxIsActive: boolean) => (dispatch, getState) => {
  const state = getState();
  const searchBoxIsActive = state.search.searchBoxIsActive;

  /* We block the repeated call of the action with the same value
  in order not to break the scroll blocking */
  if (searchBoxIsActive === newSearchBoxIsActive ) {
    return;
  }

  if(newSearchBoxIsActive) {
    disableBodyScroll();
    sendOpenSearch();
  } else {
    enableBodyScroll();
  }

  dispatch(setSearchBoxIsActiveAction(newSearchBoxIsActive));
};

export default setSearchBoxIsActive;

export function addSetSearchBoxIsActive(builder: ActionReducerMapBuilder<SearchInitialState>) {
  builder.addCase(setSearchBoxIsActiveAction, (state, action) => {
    state.searchBoxIsActive = action.payload;
  });
}
