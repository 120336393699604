import { useState } from 'react';
import { ServicePage } from 'src/redux/apiTypes';
import { SERVICE_PAGE_PREFIX } from 'src/redux/constants';

interface Params {
  initialData: ServicePage[];
  trueHost: string;
  chainHost: string;
}

function isPageActive(page: ServicePage) {
  return page.id.includes(SERVICE_PAGE_PREFIX) && page.is_public;
}

function normalizePageParams({
  chainHost,
  trueHost,
}: {
  chainHost: string;
  trueHost: string;
}) {
  return (page: ServicePage) => ({
    ...page,
    id: page.id.replace(SERVICE_PAGE_PREFIX, ''),
    web_url: page.web_url.replace(chainHost, trueHost),
  });
}

export default function useServicePagesLogic(params: Params) {
  const { initialData, trueHost, chainHost } = params;

  const [servicePagesState] = useState(() => {
    const servicePagesData = initialData
      .filter(isPageActive)
      .map(normalizePageParams({ chainHost, trueHost }));

    return servicePagesData;
  });

  return servicePagesState;
}
