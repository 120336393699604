import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { HandlerFunc } from '../getRedirectPath';
import isEAN from '../isEAN';
import siteRoutes from '../siteRoutes';

const handleNoLanguage: HandlerFunc = async ({ path, appConfig }) => {
  const { languages, defaultLanguage } = appConfig;
  const splitPath = path.split('?');
  const [uri, params] = splitPath;
  const segments = uri.split('/');

  if (
    !languages.includes(segments[1] as Language) &&
    (siteRoutes.includes(segments[1]) || isEAN(segments[1]))
  ) {
    return (
      ['', defaultLanguage, ...segments].join('/') +
      `${params ? '?' + params : ''}`
    );
  } else if (
    !languages.includes(segments[1] as Language) &&
    (siteRoutes.includes(segments[2]) || !segments[2] || isEAN(segments[2]))
  ) {
    segments.shift();
    return (
      ['', defaultLanguage, ...segments].join('/') +
      `${params ? '?' + params : ''}`
    );
  }

  return path;
};

export default handleNoLanguage;
