import { ReactNode } from 'react';
import useMainPromotion from 'src/services/mainPromotion/useMainPromotion';
import { useAppSelector } from '../../redux/hooks';
import HeaderPromotionContext from './HeaderPromotionContext';
import useHeaderPromotionLogic from './useHeaderPromotionLogic';

interface HeaderPromotionProviderProps {
  children: ReactNode;
}

const HeaderPromotionProvider = ({ children }: HeaderPromotionProviderProps) => {
  const headerPromotion = useMainPromotion();
  const language = useAppSelector(s => s.app.language);
  const headerPromotionLogic = useHeaderPromotionLogic(headerPromotion, language);

  return (
    <HeaderPromotionContext.Provider value={headerPromotionLogic}>
      { children }
    </HeaderPromotionContext.Provider>
  );
};

export default HeaderPromotionProvider;
