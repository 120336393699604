const requestErrorMiddleware = () => next => action => {
  if (!action.error || action.payload.name !== 'RequestError') {
    return next(action);
  }

  /** @todo temporarily solution to not fail subsequent code */
  const newAction = {
    ...action,
    payload: {
      ...action.payload,
      response: {
        errors: [
          {
            error_code: 4999,
            description: 'Failed to fetch',
          },
        ],
      },
    },
  };

  return next(newAction);
};

export default requestErrorMiddleware;