import { NOT_MULTI_FILTER_PARAMS } from '../../../../redux/constants';
import isCategoriesPage from '../../../filter/isCategoriesPage';
import { HandlerFunc } from '../getRedirectPath';

// first of filter params should be in URL not in get params
const handleFirstFilterParamOfCategory: HandlerFunc = async ({ path }) => {
  const splitPath = path.split('?');
  const [uri, params] = splitPath;

  if(!params) {
    return path;
  }

  const segments = uri.split('/');
  // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
  const firstFilterParamSegment = segments[4];

  if(isCategoriesPage(path) && !firstFilterParamSegment) {
    const paramsArr = params.split('&');
    const firstFilterParamIndex = paramsArr.findIndex(param => {
      const [type] = decodeURIComponent(param).split('=');
      return !NOT_MULTI_FILTER_PARAMS.some(p => p === type);
    });
    const hasFilterParams = firstFilterParamIndex !== -1;

    if(hasFilterParams) {
      // here paramsArr is reduced by splice
      const firstFilterParam = paramsArr.splice(firstFilterParamIndex, 1)[0];

      return `${segments.join('/')}${firstFilterParam}/${paramsArr.length ? "?" + paramsArr.join('&'): ''}`;
    }
  }

  return path;
};

export default handleFirstFilterParamOfCategory;
