import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartDeliveryAsset, ServerCartState } from './types';

const postCartDeliveryRequest = createAction('serverCart/postCartDeliveryRequest');
export const postCartDeliverySuccess = createAction<ServerCartData>('serverCart/postCartDeliverySuccess');
const postCartDeliveryError = createAction<RSAAActionErrorPayload>('serverCart/postCartDeliveryError');

const requestPostCartDelivery = (body: ServerCartDeliveryAsset) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/delivery/`,
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body: JSON.stringify(body),
      types: [
        postCartDeliveryRequest.type,
        {
          type: postCartDeliverySuccess.type,
          payload: getSuccessPayload,
        },
        postCartDeliveryError.type,
      ],
    }),
  );
};

export default requestPostCartDelivery;

export function addPostCartDeliveryCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCartDeliveryRequest, state => {
      state.statusMap.postDelivery = 'loading';
      state.errorMap.postDelivery = null;
    })
    .addCase(postCartDeliverySuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postDelivery = 'succeeded';
      state.errorMap.postDelivery = null;
      state.errors = null;
    })
    .addCase(postCartDeliveryError, (state, action) => {
      state.statusMap.postDelivery = 'failed';
      state.errorMap.postDelivery = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
