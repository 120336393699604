import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { ServerCartState } from './types';

const resetServerCartItemsUpdateMap = createAction('serverCart/resetCartItemsUpdateMap');

export const addResetCartItemsUpdateMapCase = (builder: ActionReducerMapBuilder<ServerCartState>) => {
  builder.addCase(
    resetServerCartItemsUpdateMap,
    (state) => {
      state.cartItemsUpdateMap = {};
    },
  );
};

export default resetServerCartItemsUpdateMap;