import { useEffect, useState } from 'react';
import useRequestStatus from 'src/hooks/useRequestStatus/useRequestStatus';
import { FetchConfig } from 'src/redux/apiTypes';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { DeliveryPreset, PostDeliveryPresetsResponse } from './types';

export default function useDeliveryPresetsRequests(isLoggedIn: boolean) {
  const { fetchConfig } = useAppConfig();

  const [presets, setPresets] = useState<DeliveryPreset[] | null>(null);
  const { status, setStatus } = useRequestStatus('idle');

  useEffect(() => {
    if (isLoggedIn) {
      requestGetDeliveryPresets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const requestGetDeliveryPresets = async (): Promise<DeliveryPreset[]> => {
    let res = {} as Response;

    try {
      setStatus('loading');
      res = await getDeliveryPresetsFetcher(fetchConfig);
    } catch (error) {
      console.error(error);
      setStatus('failed');
      return null;
    }

    if (res.ok) {
      const data = await res.json();

      setStatus('succeeded');
      setPresets(data);

      return data;
    } else {
      setStatus('failed');
      return null;
    }
  };

  const requestPostDeliveryPresets = async (
    preset: DeliveryPreset,
  ): Promise<string | null> => {
    let res = {} as Response;

    try {
      setStatus('loading');
      res = await postDeliveryPresetsFetcher(preset, fetchConfig);
    } catch (error) {
      console.error(error);
      setStatus('failed');
      return null;
    }

    if (res.ok) {
      const data = await res.json() as PostDeliveryPresetsResponse;

      setStatus('succeeded');

      return data.id;
    } else {
      setStatus('failed');
      return null;
    }
  };

  const requestDeleteDeliveryPresets = async (
    id: string,
  ): Promise<true | null> => {
    let res = {} as Response;

    try {
      setStatus('loading');
      res = await deleteDeliveryPresetsFetcher(id, fetchConfig);
    } catch (error) {
      console.error(error);
      setStatus('failed');
      return null;
    }

    if (res.ok) {
      setStatus('succeeded');
      return true;
    } else {
      setStatus('failed');
      return null;
    }
  };

  return {
    requestGetDeliveryPresets,
    requestPostDeliveryPresets,
    requestDeleteDeliveryPresets,
    deliveryPresets: presets,
    loadingStatus: status,
    isReady: !isLoggedIn || status === 'succeeded' || status === 'failed',
  };
}

export async function getDeliveryPresetsFetcher(fetchConfig: FetchConfig) {
  const { apiRoot, xChain, language, xVersion } = fetchConfig;

  const url = `${apiRoot}/user/delivery_presets/`;

  const options: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
  };

  return await fetch(url, options);
}

export async function postDeliveryPresetsFetcher(
  preset: DeliveryPreset,
  fetchConfig: FetchConfig,
) {
  const { apiRoot, xChain, language, xVersion } = fetchConfig;

  const url = `${apiRoot}/user/delivery_presets/`;

  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body: JSON.stringify(preset),
  };

  return await fetch(url, options);
}

export async function deleteDeliveryPresetsFetcher(
  presetId: string,
  fetchConfig: FetchConfig,
) {
  const { apiRoot, xChain, language, xVersion } = fetchConfig;

  const url = `${apiRoot}/user/delivery_presets/${presetId}/`;

  const options: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
  };

  return await fetch(url, options);
}
