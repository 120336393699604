import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppLinks } from 'src/utils/marketing/appLinks/useAppLinks';
import { Offer } from '../../redux/apiTypes';
import { useUserAgent } from '../user-agent';
import { getHeaderPromotionClosedCookie, getPagePathWithoutLang, setHeaderPromotionClosedCookie } from './helpers';

export interface PromotionLineDate {
  content: string;
  link: string;
  linkLabel: string;
}

const mobileAppPromoTextMap = {
  'offers': 'more_promotions_in_app',
  'custom-categories/promotions': 'more_promotions_in_app',
  'account': 'download_our_app',
  'account/orders': 'manage_orders_in_app',
};

const useHeaderPromotionLogic = (
  headerPromotion: Offer | null,
  language: string,
) => {
  const { asPath } = useRouter();
  const localize = useLocalization();
  const appLinks = useAppLinks();
  const { platform, os } = useUserAgent();
  const wasClosedEarly = !!getHeaderPromotionClosedCookie();
  const [isClosed, setIsClosed] = useState(wasClosedEarly);
  const pagePathWithoutLang = getPagePathWithoutLang(asPath);

  const getPromotionLineData = () => {
    const isMobilePromoPage = Object.keys(mobileAppPromoTextMap).includes(pagePathWithoutLang);
    if (isMobilePromoPage && platform === 'mobile') {
      return {
        content: localize(mobileAppPromoTextMap[pagePathWithoutLang]),
        link: appLinks[os],
        linkLabel: localize('app_banner_download'),
      };
    }

    if (headerPromotion === null || !headerPromotion.showcase_info) {
      return null;
    }

    return {
      content: headerPromotion.showcase_info,
      link: `/${language}/offers/${headerPromotion.slug}/`,
      linkLabel: localize('general_more_details'),
    };
  };

  const [promotionLineData, setPromotionLineData] = useState<
    PromotionLineDate | null
  >(getPromotionLineData);

  const close = () => {
    setHeaderPromotionClosedCookie();
    setIsClosed(true);
  };

  useEffect(() => {
    //updates promo data on each route change, because on different pages this data may be difference
    setPromotionLineData(getPromotionLineData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePathWithoutLang]);

  return {
    isClosed,
    promotionLineData,
    close,
  };
};

export default useHeaderPromotionLogic;
