import { selectCartItems } from "src/redux/cart/general/cartSelectors";
import selectSelectedDelivery from "src/redux/cart/general/selectSelectedDelivery";
import { useAppSelector } from "src/redux/hooks";
import useStoreService from "src/services/storeManagement/useStoreService";
import { UseScheduleDeliveryProps } from "./useScheduleDelivery";

const useScheduleDeliveryProps = (): UseScheduleDeliveryProps => {
  const cartType = useAppSelector(state => state.app.cartType);
  const cartETag = useAppSelector(state => state.serverCart.data?.etag);
  const cartItems = useAppSelector(selectCartItems);
  const delivery = useAppSelector(selectSelectedDelivery);

  const { id: storeId } = useStoreService();

  return {
    delivery,
    cartItems,
    cartType,
    cartETag,
    storeId,
  };
};

export default useScheduleDeliveryProps;
