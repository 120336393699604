import { createAction as createRsaaAction, RSAACall } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';

export const CACHEABLE = '@@apiCacheMiddlewareCacheableMark';

// get Cache TTL from ENV, if empty or incorrect - fallback to default 60s
let ttl = parseInt(process.env.CACHE_TTL);

if (isNaN(ttl)) {
  ttl = 60;
}

const errorNames = ['ApiError', 'RequestError', 'InternalError'];

let cacheClient;

if (typeof window === 'undefined') {
  const getInstance = require('../utils/system/ApiCacheClient').default;
  cacheClient = getInstance();
}

const apiCacheMiddleware = (store) => next => async action => {
  if (typeof window === 'undefined') {
    if (!action[CACHEABLE] || !action[CACHEABLE].cacheKey) {
      return next(action);
    }

    const state = store.getState();
    const { chain } = selectAppConfig(state);
    const cacheKey = `${chain}-${action[CACHEABLE].cacheKey}`;
    let res;

    try {
      res = await cacheClient.get(cacheKey);
    } catch (e) {
      console.error(`ERROR RETRIEVING ${cacheKey}: ${e.message}`);
    }

    if (res) {
      const json = JSON.parse(res);
      const successActionType = action[CACHEABLE].successActionType;

      return next({
        type: successActionType,
        payload: json,
        meta: {
          cached: true,
        },
      });
    } else {
      const nextAction = await next({
        ...action,
        meta: {
          cached: false,
        },
      });

      if (!nextAction.payload) {
        console.log('Received action without payload after RSAA execution:', nextAction);
        return nextAction;
      }

      if (errorNames.includes(nextAction.payload.name)) {
        console.log(`${nextAction.payload.name} occurred while fetching cacheable data from API for key ${cacheKey}: ${nextAction.payload.message}`);
        return nextAction;
      }

      try {
        await cacheClient.set(cacheKey, JSON.stringify(nextAction.payload), ttl);
      } catch (e) {
        console.error(`ERROR SETTING ${cacheKey}: ${e.message}`);
      }

      return nextAction;
    }
  }

  return next(action);
};

export default apiCacheMiddleware;

export interface CacheConfig {
  cacheKey: string
  successActionType: string
}

export function createAction<State, Payload, Meta>(
  cacheConfig: CacheConfig, clientCall: RSAACall<State, Payload, Meta>,
) {
  const action = createRsaaAction(clientCall);
  action[CACHEABLE] = cacheConfig;
  return action;
}
