import { FetchConfig, Product } from "src/redux/apiTypes";
import { EanFavoriteListMap, ShoppingList } from "src/services/favorites/types";
import { NotificationService } from "src/services/notification/types";
import { sendAddProductToWishlistEvent } from "src/utils/marketing/enhancedEcommerce";
import requestPostFavoritesItem from "../ajax/requestPostFavoritesItem";
import getAddingRemovingLists from "./getAddingRemovingLists";

interface Params {
  product: Product;
  favoriteEansMap: EanFavoriteListMap;
  lists: ShoppingList[];
  fetchConfig: FetchConfig;
  listId?: string;

  onSuccess(): void;
  openFavoriteNotification: NotificationService['openFavoriteNotification'];
}

export const addOrRemoveItemFromLists = async (params: Params) => {
  const {
    listId = null,
    product,
    lists,
    favoriteEansMap,
    fetchConfig,
    onSuccess,
    openFavoriteNotification,
  } = params;

  const { ean } = product;
  const { removingLists, addingLists } = getAddingRemovingLists({
    currentListId: listId,
    ean,
    eanListsMap: favoriteEansMap,
    lists,
  });

  const isRemoving = removingLists.length > 0;
  const actionType = isRemoving ? 'remove' : 'add';
  const listIds = isRemoving ? removingLists : addingLists;

  await requestPostFavoritesItem({
    fetchConfig,
    ean,
    actionType,
    listIds,
  });

  onSuccess();

  if (!isRemoving) {
    sendAddProductToWishlistEvent(product);
  }

  openFavoriteNotification({
    product,
    listIds,
    actionType,
  });
};
