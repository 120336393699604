import { SET_CONFIRMED_AGE } from '../constants';
import { RESET_ERRORS, SET_USER_PROFILE_DATA, USER_PROFILE_DATA_ERROR } from './userProfileTypes';

export const setUserProfileData = data => ({
  type: SET_USER_PROFILE_DATA,
  payload: data,
});

export const setUserProfileDataError = errors => ({
  type: USER_PROFILE_DATA_ERROR,
  payload: {
    response: { errors },
  },
});

export const resetErrors = () => ({
  type: RESET_ERRORS,
});

export const setConfirmedAge = (confirmedAge) => (
  {
    type: SET_CONFIRMED_AGE,
    payload: confirmedAge,
  }
);
