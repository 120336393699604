import { MEASURE_KG } from '../../redux/constants';
import { gramsToKilos, kilosToGrams } from './weightConverter';
import { ProductUnit } from '../../redux/apiTypes';

export const getConvertedAmount = (
  amount: number,
  unit: ProductUnit,
  presentationMode?: boolean,
): number => {
  if (unit === MEASURE_KG) {
    if (presentationMode) return gramsToKilos(amount);
    else return kilosToGrams(amount);
  }

  return amount;
};
