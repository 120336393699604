import useChainService from 'src/services/chain/useChainService';
import useLocalization from 'src/services/localization/useLocalization';

export const useTitle = (): string => {
  const chain = useChainService();

  const localize = useLocalization();
  const chainTitleGettersMap = {
    'default': () => `${chain.name} ${localize(`MAIN_PAGE.title`)} ${localize(`meta.${chain.id}.in_cities`)} ${localize('meta.general.to_home')}`,
  };

  return chainTitleGettersMap[chain.id] ? chainTitleGettersMap[chain.id]() : chainTitleGettersMap.default();

};

export const useDescription = (): string => {
  const chain = useChainService();
  const localize = useLocalization();
  const mainPageDescription = `${chain.name} - ${localize('meta.description.main_page')}`;

  return mainPageDescription;
};
