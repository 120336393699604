import { useState } from "react";
import { ActiveNotificationState, NotificationFavoriteData, NotificationProductData, NotificationState } from "./types";

const useNotificationService = () => {
  const [notification, setNotification] = useState<NotificationState>({
    type: null,
    messageContent: null,
    data: null,
  });

  const openNotification = (newNotification: ActiveNotificationState) => {
    setNotification(newNotification);
  };

  const closeNotification = () => {
    setNotification({
      type: null,
      messageContent: null,
      data: null,
    });
  };

  const openErrorNotification = (messageKey: string) => {
    setNotification({
      type: 'error',
      messageContent: messageKey,
      data: null,
    });
  };

  const openTextNotification = (messageKey: string) => {
    setNotification({
      type: 'message',
      messageContent: messageKey,
      data: null,
    });
  };

  const openProductNotification = (product: NotificationProductData) => {
    setNotification({
      type: 'product',
      messageContent: null,
      data: product,
    });
  };

  const openFavoriteNotification = (data: NotificationFavoriteData) => {
    setNotification({
      type: 'favorite',
      messageContent: null,
      data,
    });
  };

  return {
    state: notification,
    openNotification,
    closeNotification,
    openErrorNotification,
    openTextNotification,
    openProductNotification,
    openFavoriteNotification,
  };
};

export default useNotificationService;
