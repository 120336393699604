import { ServerCartData, ServerCartDataResponse } from './types';

async function getSuccessPayload(
  _action,
  _state,
  res: Response,
): Promise<ServerCartData> {
  const data: ServerCartDataResponse = await res.json();
  const etag = res.headers.get('etag');

  return {
    ...data,
    etag,
  };
}

export default getSuccessPayload;
