import isCategoriesPage from '../../../filter/isCategoriesPage';
import { HandlerFunc } from '../getRedirectPath';

function getFullLoweredUriPath(uri, params) {
  return `${uri.toLowerCase()}${params ? `?${params}` : ''}`;
}

function getLoweredUriPathOfCategory(uri, params) {
  // 0 - root; 1 - lang; 2 - root of categories; 3 - category Name; 4 - first filter param
  const segments = uri.split('/');
  const categoryNameSegment = segments[3];
  const firstFilterParamSegment = segments[4];

  if(!categoryNameSegment || !firstFilterParamSegment) {
    return getFullLoweredUriPath(uri, params);
  }

  const loweredUri = segments
    .splice(0, 4)
    .join('/')
    .toLowerCase();

  return `${loweredUri}/${firstFilterParamSegment}${params ? `/?${params}` : '/'}`;
}

function isNotificationsPage(path: string) {
  const notificationsRegex = /^\/[a-zA-Z]{2}\/[nN]\/[^/]+\/?$/;
  const isMatchPath = notificationsRegex.test(path);

  return isMatchPath;
}

const handleCase: HandlerFunc = async ({ path }) => {
  if (isNotificationsPage(path)) {
    return path;
  }

  const splitPath = path.split('?');
  const [uri, params] = splitPath;

  if(isCategoriesPage(path)) {
    // URL of categories may contain the first param
    // of filter which may contain upper case inner
    // we shouldn't convert to lower case this param
    return getLoweredUriPathOfCategory(uri, params);
  }

  return getFullLoweredUriPath(uri, params);
};

export default handleCase;
