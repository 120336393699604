import { openModal } from "../../redux/modals";
import { ModalData, ModalName, SharedData } from "../../redux/modals/types";
import { Dispatch } from 'redux';

export interface ModalInfo {
  modalName: ModalName
  modalData?: ModalData
  sharedData?: SharedData
}

const getModalsFromSessionStorage = (): ModalInfo[] => {
  const infoFromStorage = sessionStorage.getItem('modalsManager');
  return infoFromStorage ? JSON.parse(infoFromStorage) : [];
};

const setModalsToSessionStorage = (modals: ModalInfo[]) => {
  sessionStorage.setItem('modalsManager', JSON.stringify(modals));
};

export const modalsManager = {
  addModal: (modalName: ModalName, modalData?: ModalData, sharedData?: SharedData) => {
    const allModals = getModalsFromSessionStorage();
    const newModals = [...allModals, {
      modalName,
      sharedData,
      modalData,
    }];
    setModalsToSessionStorage(newModals);
  },
  showFirstModal: (dispatch: Dispatch) => {
    const allModals = getModalsFromSessionStorage();
    const firstModal = allModals?.[0];
    if(firstModal) {
      const { modalName, modalData, sharedData } = firstModal;

      dispatch(openModal(modalName, modalData, sharedData));

      const newModals = allModals.filter(modal => modal.modalName !== firstModal.modalName);
      setModalsToSessionStorage(newModals);
    }
  },
};
