import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { UserState } from './userTypes';

/** @deprecated pass login via modal data */
const setLocalModalLogin = createAction<string>('user/setLocalModalLogin');

export default setLocalModalLogin;

export function addSetLocalModalLoginCases(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(setLocalModalLogin, (state, action) => {
    state.localModalLogin = action.payload;
  });
}
