import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useRouterUtils from 'src/hooks/useRouterUtils/useRouterUtils';
import { openModal } from "src/redux/modals";

interface Query {
  modal: string;
}

const RecommendationsModalWatcher = () => {
  const { router, cleanUrlQuery } = useRouterUtils<Query>();
  const dispatch = useDispatch();

  const encodedModalTypeData = router.query.modal;
  const recommendationsModalShouldBeOpened =  encodedModalTypeData === 'recommendations';

  useEffect(() => {
    if (recommendationsModalShouldBeOpened) {
      cleanUrlQuery();
      dispatch(openModal('recommendationsModal'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendationsModalShouldBeOpened]);

  return null;
};

export default RecommendationsModalWatcher;
