import { testHook } from 'src/mocks/testHooks';
import createReducer from '../createReducer';
import { AnalyticsState, SET_CART_MODE } from './types';

const initialState: AnalyticsState = {
  cartMode: 'cart',
};

const actionHandlers = {
  [SET_CART_MODE]: (_, action) => {
    testHook(action.payload);

    return {
      ...initialState,
      cartMode: action.payload || initialState.cartMode,
    };
  },
};

const reducer = createReducer(initialState, actionHandlers);

export default reducer;
