import { createReducer } from '@reduxjs/toolkit';
import { getResponseErrors } from '../../utils/api/getResponseErrors';
import { CHANGE_PASSWORD, CHECK_LOGIN, CONFIRM_RECOVERY_ERROR, CONFIRM_RECOVERY_REQUEST, CONFIRM_RECOVERY_SUCCESS, CREATE_PASSWORD_ERROR, CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, ERROR, LOGIN, RECOVERY_PASSWORD, REGISTER_CONFIRM, REGISTER_SIGNUP, REQUEST, RESET_ERROR, RESTORE_ORDER_INFO, SEND_FEEDBACK, SUCCESS } from '../constants';
import { addPostLoginCases } from './requestPostLogin';
import { addPostLogoutCases } from './requestPostLogout';
import { addSetLocalModalLoginCases } from './setLocalModalLogin';
import { clearQuickFeedbackIsSent, postQuickFeedbackError, postQuickFeedbackRequest, postQuickFeedbackSuccess } from './userActions';
import { UserState } from './userTypes';

export const userInitialState: UserState = {
  isFetching: {
    login: false,
    logout: false,
    register: false,
    recoveryPassword: false,
    checkLogin: false,
    quickFeedbackPopup: false,
    feedbackPopup: false,
    userPhone: false,
    userInfo: false,
    password: false,
  },
  error: {
    login: [],
    logout: [],
    recoveryPassword: [],
    password: [],
    register: [],
    confirmRecovery: [],
  },
  isLoginChecked: false,
  isLoggedIn: false,
  sessionInfo: null,
  orderInfo: null,
  register: {
    phone: '',
  },
  retryAfterSeconds: null,
  selectedPresetId: null,
  quickFeedbackIsSent: false,
  feedbackIsSent: false,
  localModalLogin: '',
  canLoginTimestamp: null,
  lastUsedLogin: null,
};

const actionHandlers = {

  [SEND_FEEDBACK + REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      feedbackPopup: true,
    },
    feedbackIsSent: false,
  }),
  [SEND_FEEDBACK + SUCCESS]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      feedbackPopup: false,
    },
    feedbackIsSent: true,
  }),
  [SEND_FEEDBACK + ERROR]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      feedbackPopup: false,
    },
  }),
  [postQuickFeedbackRequest.type]: state => ({
    ...state,
    quickFeedbackIsSent: false,
    isFetching: {
      ...state.isFetching,
      quickFeedbackPopup: true,
    },
  }),
  [postQuickFeedbackSuccess.type]: state => ({
    ...state,
    quickFeedbackIsSent: true,
    isFetching: {
      ...state.isFetching,
      quickFeedbackPopup: false,
    },
  }),
  [postQuickFeedbackError.type]: state => ({
    ...state,
    quickFeedbackIsSent: false,
    isFetching: {
      ...state.isFetching,
      quickFeedbackPopup: false,
    },
  }),
  [clearQuickFeedbackIsSent.type]: state => ({
    ...state,
    quickFeedbackIsSent: false,
  }),
  [CHECK_LOGIN + REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      checkLogin: true,
    },
  }),
  [CHECK_LOGIN + SUCCESS]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      checkLogin: false,
    },
    isLoggedIn: true,
    isLoginChecked: true,
  }),
  [CHECK_LOGIN + ERROR]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      checkLogin: false,
    },
    isLoggedIn: false,
    isLoginChecked: true,
  }),
  [LOGIN + SUCCESS]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      login: false,
    },
    error: {
      ...state.error,
      login: [],
    },
    isLoggedIn: true,
  }),
  [LOGIN + RESET_ERROR]: state => ({
    ...state,
    error: {
      ...state.error,
      login: [],
    },
  }),
  [RECOVERY_PASSWORD + RESET_ERROR]: state => ({
    ...state,
    error: {
      ...state.error,
      recoveryPassword: [],
    },
  }),
  [RECOVERY_PASSWORD + REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      recoveryPassword: true,
    },
    error: {
      ...state.error,
      recoveryPassword: [],
      confirmRecovery: [],
    },
    retryAfterSeconds: null,
  }),
  [RECOVERY_PASSWORD + SUCCESS]: (state, action) => {
    const { meta } = action;

    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        recoveryPassword: false,
      },
      retryAfterSeconds: meta.headers.retryAfterSeconds,
    };
  },
  [RECOVERY_PASSWORD + ERROR]: (state, action) => {
    const { payload, meta } = action;

    if (meta?.status === 429)
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          recoveryPassword: false,
        },
        retryAfterSeconds: meta.headers.retryAfterSeconds,
      };

    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        recoveryPassword: false,
      },
      error: {
        ...state.error,
        recoveryPassword: getResponseErrors(payload),
      },
    };
  },
  [CONFIRM_RECOVERY_REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      recoveryPassword: true,
    },
    error: {
      ...state.error,
      confirmRecovery: [],
    },
  }),
  [CONFIRM_RECOVERY_SUCCESS]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      recoveryPassword: false,
    },
  }),
  [CONFIRM_RECOVERY_ERROR]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        recoveryPassword: false,
      },
      error: {
        ...state.error,
        confirmRecovery: payload.response.errors,
      },
    };
  },
  [CREATE_PASSWORD_REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      recoveryPassword: true,
    },
    error: {
      ...state.error,
      createPassword: [],
    },
  }),
  [CREATE_PASSWORD_SUCCESS]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      recoveryPassword: false,
    },
  }),
  [CREATE_PASSWORD_ERROR]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        recoveryPassword: false,
      },
      error: {
        ...state.error,
        createPassword: payload.response.errors,
      },
    };
  },
  [CHANGE_PASSWORD + RESET_ERROR]: state => ({
    ...state,
    error: {
      ...state.error,
      password: [],
    },
  }),
  [CHANGE_PASSWORD + REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      password: true,
    },
    error: {
      ...state.error,
      password: [],
    },
  }),
  [CHANGE_PASSWORD + SUCCESS]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      password: false,
    },
  }),
  [CHANGE_PASSWORD + ERROR]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        password: false,
      },
      error: {
        ...state.error,
        password: getResponseErrors(payload),
      },
    };
  },
  [REGISTER_SIGNUP + REQUEST]: (state, action) => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      register: true,
    },
    error: {
      ...state.error,
      register: [],
    },
    register: action.payload,
    retryAfterSeconds: null,
  }),
  [REGISTER_SIGNUP + SUCCESS]: (state, action) => {
    const { meta } = action;
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        register: false,
      },
      error: {
        ...state.error,
        register: [],
      },
      retryAfterSeconds: meta.headers.retryAfterSeconds,
    };
  },
  [REGISTER_SIGNUP + ERROR]: (state, action) => {
    const { payload, meta } = action;

    if (meta?.status === 429)
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          register: false,
        },
        retryAfterSeconds: meta.headers.retryAfterSeconds,
      };

    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        register: false,
      },
      error: {
        ...state.error,
        register: getResponseErrors(payload),
      },
    };
  },
  [REGISTER_SIGNUP + RESET_ERROR]: state => ({
    ...state,
    error: {
      ...state.error,
      register: [],
    },
  }),
  [REGISTER_CONFIRM + REQUEST]: state => ({
    ...state,
    isFetching: {
      ...state.isFetching,
      register: true,
    },
    error: {
      ...state.error,
      register: [],
    },
  }),
  [REGISTER_CONFIRM + SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        register: false,
      },
      error: {
        ...state.error,
        register: [],
      },
      register: payload,
    };
  },
  [REGISTER_CONFIRM + ERROR]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        register: false,
      },
      error: {
        ...state.error,
        register: getResponseErrors(payload),
      },
    };
  },
  [RESTORE_ORDER_INFO]: state => ({
    ...state,
    orderInfo: null,
  }),
};

const reducer = createReducer(userInitialState, builder => {
  addPostLoginCases(builder);
  addPostLogoutCases(builder);
  addSetLocalModalLoginCases(builder);

  builder.addDefaultCase((state, action) => actionHandlers[action.type]?.(state, action) || state);
});

export default reducer;
