import { NOT_FOUND } from '../../../../redux/constants';
import getUserAgent from '../../../api/getUserAgent';
import getProductURL from '../../../product/getProductURL';
import splitProductToken from '../../../product/slitProductToken';
import { getEanFromURI } from '../getEanFromURI';
import { HandlerFunc } from '../getRedirectPath';

const handleEAN: HandlerFunc = async ({ path, storeId, appConfig }) => {
  const { fetchConfig } = appConfig;
  const [uri, paramsPath] = path.split('?');
  const [, languagePath, productsPath] = uri.split('/');
  const params = paramsPath ? `?${paramsPath}` : '';
  const eanSegment: string | null = getEanFromURI(uri);
  // eanSegment can be productToken or simple ean
  const ean = eanSegment ? splitProductToken(eanSegment).ean || eanSegment : null;

  const isProductPage = productsPath === 'products';
  const { xChain, apiRoot, xVersion } = fetchConfig;
  /* ean may be in random place of url*/
  if (ean) {

    const response = await fetch(`${apiRoot}/stores/${storeId}/products/${ean}`, {
      method: 'GET',
      headers: {
        'Accept-Language': languagePath,
        'x-chain': xChain,
        'x-version': xVersion,
        'User-Agent': getUserAgent(),
      },
    });

    if (response.ok && response.status === 200) {
      const json = await response.json();
      const { slug, ean } = json.product;

      return getProductURL(languagePath, ean, slug, params);
    }

    return NOT_FOUND;
  }

  if (isProductPage) {
    return NOT_FOUND;
  }

  return path;
};

export default handleEAN;
