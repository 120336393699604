import { NextRouter } from 'next/router';
import isCategoriesPage from '../../filter/isCategoriesPage';
import isOffersPage from '../../filter/isOffersPage';
import isPromotionsPage from '../../filter/isPromotionsPage';

export const indexedTaxons = [
  'years',
  'country',
  'fat',
  'method',
  'material',
  'variety',
  'genus_species',
  'size_diapers',
  'macaroni_method',
  'chocolate_kind',
  'deodorant_type',
  'for',
  'wattage',
  'volume_capacity',
  'den',
  'size_battery',
  'sausage_kind',
  'cheese_kind',
  'base',
  'children_from_month',
  'flavor',
  'tm',
];

export const noIndexLinks = [
  '/search',
  '/custom-categories/b2b-products',
  '/checkout',
  '/account',
];

export interface Props {
  urlStr: string;
  router: NextRouter;
}

export interface Result {
  noIndexNoFollow?: boolean;
  withCanonicalTag?: boolean;
}

const defineRobotsAndCanonicalMode = (props: Props): Result => {
  const {
    urlStr,
    router,
  } = props;

  /** @info handled separately */

  if (
    isCategoriesPage(urlStr) ||
    isPromotionsPage(urlStr) ||
    isOffersPage(urlStr) ||
    router.query.recipe_id
  ) {
    return {};
  }


  /** @info other pages */

  const urlSplitted = urlStr.split('/?');
  const params = urlSplitted.length > 1
    ? urlSplitted[1].split('&').map(p => decodeURIComponent(p).split('=')[0])
    : [];


  /** @info some links should not be indexed */
  if (noIndexLinks.some(link => urlStr.includes(link))) {
    return { noIndexNoFollow: true };
  }

  /** @info add canonical when no get params */
  if (
    !params.length ||
    (params.length === 1 && params[0].includes('page'))
  ) {
    if (!params.length) {
      return { withCanonicalTag: true };
    }
    return {};
  }


  return { noIndexNoFollow: true };
};

export default defineRobotsAndCanonicalMode;

