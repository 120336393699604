import { createReducer } from '@reduxjs/toolkit';
import { addClearCouponErrorCases } from './clearCouponError';
import { addGetCartCases } from './requestGetCart';
import { addPostCartCheckoutCases } from './requestPostCartCheckout';
import { addPostCartClearCases } from './requestPostCartClear';
import { addPostCartContactsCases } from './requestPostCartContacts';
import { addPostCartDeliveryCases } from './requestPostCartDelivery';
import { addPostCartItemsCases } from './requestPostCartItems';
import { addPostCouponCases } from './requestPostCoupon';
import { addPostPackingCases } from './requestPostPacking';
import { addPostPaymentCases } from './requestPostPayment';
import { addPostRecipientCases } from './requestPostRecipient';
import { addPostReplacementsCases } from './requestPostReplacements';
import { addPostScheduleCases } from './requestPostSchedule';
import { addResetCartItemsUpdateMapCase } from './resetServerCartItemsUpdateMap';
import { ServerCartState } from './types';
import { addUpdateCartItemsInStateCase } from './updateServerCartItemsInState';
import { addUpdateCartItemsUpdateMapCase } from './updateServerCartItemsUpdateMap';

export const serverCartInitialState: ServerCartState = {
  orderDataArr: null,
  data: {
    contacts: null,
    coupon_code: null,
    delivery: null,
    items: [],
    payment: null,
    price: null,
    replacements: null,
    store_id: 'default',
    time_window: null,
    time_window_id: null,
    total_weight: 0,
    warnings: [],
    etag: null,
    packing: null,
    stairs_discounts: null,
    recipient: null,
  },
  statusMap: {
    getCart: 'idle',
    getCartItems: 'idle',
    getDelivery: 'idle',
    postCartCheckout: 'idle',
    postCartClear: 'idle',
    postCartContacts: 'idle',
    postCartItems: 'idle',
    postCoupon: 'idle',
    postDelivery: 'idle',
    postPayment: 'idle',
    postReplacements: 'idle',
    postSchedule: 'idle',
    postPacking: 'idle',
    postRecipient: 'idle',
  },
  errorMap: {
    getCart: null,
    getCartItems: null,
    getDelivery: null,
    postCartCheckout: null,
    postCartClear: null,
    postCartContacts: null,
    postCartItems: null,
    postCoupon: null,
    postDelivery: null,
    postPayment: null,
    postReplacements: null,
    postSchedule: null,
    postPacking: null,
    postRecipient: null,
  },
  cartItemsUpdateMap: {},
  errors: null,
};

const reducer = createReducer(serverCartInitialState, builder => {
  addGetCartCases(builder);
  addPostCartItemsCases(builder);
  addPostCartClearCases(builder);
  addPostCartContactsCases(builder);
  addPostCartDeliveryCases(builder);
  addUpdateCartItemsUpdateMapCase(builder);
  addResetCartItemsUpdateMapCase(builder);
  addUpdateCartItemsInStateCase(builder);
  addPostReplacementsCases(builder);
  addPostPaymentCases(builder);
  addPostCouponCases(builder);
  addClearCouponErrorCases(builder);
  addPostScheduleCases(builder);
  addPostCartCheckoutCases(builder);
  addPostPackingCases(builder);
  addPostRecipientCases(builder);
});

export default reducer;
