import { useState } from "react";
import { Store } from "src/redux/apiTypes";

const useStoreLogic = (initialStore: Store) => {
  const [store] = useState(initialStore);

  return store;
};

export default useStoreLogic;
