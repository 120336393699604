import createReducer from "../createReducer";
import { UPDATE_MESSAGES } from "./translationsTypes";
import { REQUEST, SUCCESS, ERROR } from "../constants";

const reducer = createReducer(
  {
    isFetching: false,
    data: null,
    error: null,
  },
  {
    [UPDATE_MESSAGES + REQUEST]: state => (
      {
        ...state,
        isFetching: true,
      }
    ),
    [UPDATE_MESSAGES + SUCCESS]: (state, action) => (
      {
        ...state,
        isFetching: false,
        data: action.payload,
      }
    ),
    [UPDATE_MESSAGES + ERROR]: (state, action) => (
      {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    ),
  },
);

export default reducer;
