import { Fragment, ReactNode, useEffect, useState } from 'react';
import { useInteractWatcherContext } from './InteractWatcherContext';

interface RenderAfterInteractOrTimerProps {
  children: ReactNode;
  renderAfter?: number | null;
}

const RenderAfterInteractOrTimer = (props: RenderAfterInteractOrTimerProps) => {
  const { children, renderAfter } = props;
  const [ timerIsOver, setTimerIsOver ] = useState(false);
  const userInteracted = useInteractWatcherContext();

  useEffect(() => {
    let intervalId: number;
    let timerId: number;

    if (renderAfter) {
      intervalId = window.setInterval(() => {
        if (window.isLoaded) {
          timerId = window.setTimeout(() => {
            setTimerIsOver(true);
          }, renderAfter);

          clearInterval(intervalId);
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
        clearTimeout(timerId);
      };
    }
  });

  const canRender = userInteracted || timerIsOver;

  if (canRender) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default RenderAfterInteractOrTimer;
