import getInstance from 'src/utils/system/ApiCacheClient';

const TTL = Number(process.env.CACHE_TTL) || 60;

interface CacheClient {
  get(cacheKey: string): Promise<string | null>;
  set(cacheKey: string, data: string, ttl: number): Promise<void>;
}

interface Config {
  cacheKey: string;
  ttlSeconds?: number;
}

interface CallbackResult<Result> {
  result: Result;
  source: 'cb';
}

interface CachedResult<Result> {
  result: Result;
  source: 'cache';
}

type WithMemcachedResult<T> = CallbackResult<T> | CachedResult<T>;

export async function withMemcached<Result>(
  cb: () => Result | Promise<Result>,
  config: Config,
): Promise<WithMemcachedResult<Result>> {
  const { cacheKey, ttlSeconds = TTL } = config;

  const cacheClient: CacheClient = getInstance();

  let cachedResponse: string | null = null;

  try {
    cachedResponse = await cacheClient.get(cacheKey);
  } catch (error) {
    console.error(`ERROR RETRIEVING ${cacheKey}: ${error.message}`);
  }

  if (cachedResponse) {
    return {
      source: 'cache',
      result: JSON.parse(cachedResponse),
    };
  }

  const result = await cb();

  try {
    await cacheClient.set(cacheKey, JSON.stringify(result), ttlSeconds);
  } catch (error) {
    console.error(`ERROR SETTING ${cacheKey}: ${error.message}`);
  }

  return {
    source: 'cb',
    result,
  };
}
