import { ProductUnit } from '../../redux/apiTypes';
import { MEASURE_KG } from '../../redux/constants';

/**
 * If formatPrice is not wrapped in <span> it can potentially lead
 * to 404 error when the page is translated with Google Translator,
 * which is a React bug.
 * The best way to fix this is to wrap the returned value in a span and
 * it should be tested thoroughly throughout the project.
 * Here you can find a direct link to project tech debt related to the issue
 * https://zakaz.atlassian.net/browse/SHWEB-3175
*/

export const formatPrice = (price: number, decimal = true): string => {
  let result: number | string = price / 100;

  if (decimal) {
    result = result.toFixed(2);
  }

  return String(result);
};

export const getPriceStringByUnit = (
  price: number, unit: ProductUnit,
): string => {
  const priceNumber = Number(price);

  return unit === MEASURE_KG
    ? formatPrice(priceNumber / 1000, false)
    : formatPrice(priceNumber);
};
