import { useServices } from "..";

const useMainPromotion = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useMainPromotion');
  }

  return services.mainPromotion;
};

export default useMainPromotion;
