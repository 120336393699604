import { FetchConfig, ServicePage } from 'src/redux/apiTypes';
import { withMemcached } from 'src/utils/ApiCacheClient/withMemcached';


export async function getServicePagesSSR(fetchConfig: FetchConfig, host: string) {
  const { apiRoot, xChain, language, xVersion } = fetchConfig;

  const { result } = await withMemcached(async () => {
    const url = `${apiRoot}/stores/default/service_pages/`;

    const options: RequestInit = {
      method: 'GET',
      headers: {
        'Accept-Language': language,
        'x-version': xVersion,
        'x-chain': xChain,
      },
    };

    const res = await fetch(url, options);

    if (res.ok) {
      return await res.json() as ServicePage[];
    }
  }, {
    cacheKey: `${host}-${xChain}-service_pages-${language}`,
  });

  return result;
}
