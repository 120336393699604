import { createReducer } from '@reduxjs/toolkit';
import { addResetSearchedProducts } from './resetSearchedProducts';
import { addLoadMoreSearchProducts } from './searchLoadMoreProducts';
import { addSearchProducts } from './searchProducts';
import { addSetSearchBoxIsActive } from './setSearchBoxIsActive';
import { SearchInitialState } from './types';

export const searchInitialState: SearchInitialState = {
  products: [],
  filters: [],
  categories: [],
  count: 0,
  isFetching: false,
  searchBoxIsActive: false,
  count_available: 0,
};

const reducer = createReducer(searchInitialState, builder => {
  addResetSearchedProducts(builder);
  addSetSearchBoxIsActive(builder);
  addSearchProducts(builder);
  addLoadMoreSearchProducts(builder);
});

export default reducer;

