import { HandlerFunc } from '../getRedirectPath';

const handlePreferredLanguage: HandlerFunc = async ({ path, preferredLanguage, appConfig }) => {
  const { languages } = appConfig;
  const splitPath = path.split('?');
  const [uri, params] = splitPath;
  const segments = uri.split('/');
  const [firstSegment, langSegment, ...restSegments] = segments;

  if (preferredLanguage && preferredLanguage !== langSegment && languages.includes(preferredLanguage)) {
    let modifiedPath = [firstSegment, preferredLanguage, ...restSegments].join('/');

    if(params != null) {
      modifiedPath = `${modifiedPath}?${params}`;
    }

    return { modifiedPath, redirectType: 302 };
  }

  return path;
};

export default handlePreferredLanguage;
