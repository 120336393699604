import Cookies from 'js-cookie';
import { setAddressInLocalStorage } from 'src/utils/delivery/addressLocalStorage';

const unavailableStoreMiddleware = () => next => action => {
  if (typeof window === 'undefined') {
    return next(action);
  }
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.errors &&
    action.payload.response.errors[0] &&
    action.payload.response.errors[0].error_code === 4202
  ) {
    Cookies.remove('storeId');
    setAddressInLocalStorage();
    window.location.reload();
  }
  return next(action);
};

export default unavailableStoreMiddleware;
