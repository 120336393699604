import { Chain, ChainDto } from '../types';

export default function transformDtoToChain(chainDto: ChainDto) {
  const chain: Chain = {
    id: chainDto.id,
    name: chainDto.name,
    hostname: chainDto.hostname,
    novaPoshtaIsAvailable: chainDto.is_np_available,
    logoLinks: {
      logo: chainDto.logo.logo,
      opengraph: chainDto.logo.opengraph,
      ownBrandGamification: chainDto.logo.own_brand_gamification,
    },
  };

  return chain;
}
