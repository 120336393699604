import { CoordsStrings } from '../modules/AddressManagement/AddressManagement.types';
import { Coords, DeliveryType, FetchConfig, Store } from '../redux/apiTypes';
import getUserAgent from '../utils/api/getUserAgent';

async function getStoresByDeliveryTypeAndCoords(
  deliveryType: DeliveryType,
  coords: CoordsStrings | Coords,
  config: FetchConfig,
): Promise<Store[]> {
  const { apiRoot, xChain, xVersion, language } = config;
  const { lat, lng } = coords;
  const response = await fetch(
    `${apiRoot}/stores/?delivery_type=${deliveryType}&coords=${lat},${lng}`,
    {
      headers: {
        'x-chain': xChain,
        'x-version': xVersion,
        'Accept-Language': language,
        'User-Agent': getUserAgent(),
      },
    },
  );

  return await response.json();
}

export default getStoresByDeliveryTypeAndCoords;
