import { OverallConfig, RawConfig } from './types';

export default function getRetailConfig(
  overallConfig: OverallConfig,
  retailName: string,
): RawConfig | null {
  const retailConfig = overallConfig[retailName];

  if (!retailConfig || retailName === 'global') {
    return null;
  }

  return {
    ...overallConfig['global'],
    ...retailConfig,
  };
}
