export function parseCookieString(cookieString: string) {
  const cookiePairs = cookieString.split('; ');
  const cookieObject = {};

  for (const pair of cookiePairs) {
    const [key, value] = pair.split('=');
    cookieObject[key] = value;
  }

  return cookieObject;
}
