import { RSAA } from "redux-api-middleware";
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { CACHEABLE } from "../apiCacheMiddleware";
import { ERROR, REQUEST, SUCCESS } from "../constants";
import { UPDATE_MESSAGES } from "./translationsTypes";

export const getTranslations = () => (dispatch, getState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    {
      [CACHEABLE]: {
        cacheKey: `translations-${language}`,
        successActionType: UPDATE_MESSAGES + SUCCESS,
      },
      [RSAA]: {
        method: 'GET',
        endpoint: `${apiRoot}/translations/`,
        headers: {
          'Accept-Language': language,
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'x-version': xVersion,
        },
        types: [
          UPDATE_MESSAGES + REQUEST,
          UPDATE_MESSAGES + SUCCESS,
          UPDATE_MESSAGES + ERROR,
        ],
      },
    },
  );
};
