function processMultipleParams (key, values) {
  return values.reduce((string, val, index) => {
    const starter = index === 0 ? '' : '&';
    return string + starter + encodeURIComponent(key) + '=' + encodeURIComponent(val);
  }, '');
}

export default function makeSearchString (queryObject = {}, excludeParams: string[] = []) {
  const searchString = '';
  return Object.keys(queryObject)
    .reduce((string, key) => {
      if(excludeParams.indexOf(key) === -1 && queryObject[key]) {
        const starter = string === '' ? '?' : '&';
        if (Array.isArray(queryObject[key])) {
          return string + starter + processMultipleParams(key, queryObject[key]);
        }
        return string + starter + encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
      }
      return string;
    }, searchString);
}
