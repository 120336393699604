import dynamic from 'next/dynamic';
import { RenderAfterInteract } from 'src/modules/interact-watcher';
import { useUserAgent } from 'src/modules/user-agent';

const MobileAppBannerContainer = dynamic(
  () => import('./MobileAppBannerContainer'),
  { ssr: false },
);

export default function MobileAppBannerWrapper() {
  const { platform, os } = useUserAgent();

  if (platform === 'mobile') {
    return (
      <RenderAfterInteract>
        <MobileAppBannerContainer os={os} />
      </RenderAfterInteract>
    );
  }

  return null;
}
