import css from 'styled-jsx/css';
import { phone } from '../../../../styles/media';
import typography from '../../../../styles/typography';

export const styles = css.global`
  /* Typography */
  h1 {
    margin: 0 0 20px;
    font-size: 36px;
    line-height: 1.11;
  }
  @media ${phone} {
    h1 {
      font-size: 24px;
    }
  }
  h2 {
    ${typography.h2}
    margin: 0 0 20px;
  }
  h3 {
    ${typography.h3}
    margin: 0 0 15px;
  }
  h4 {
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 1.375;
    color: var(--colors-site);
  }
  h5 {
    margin: 0 0 15px;
    font-size: 15px;
    line-height: 1.4;
  }
  h6 {
    margin: 0 0 15px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--colors-site);
  }
  p {
    margin: 0 0 15px;
  }
  b {
    font-weight: 600;
  }
  a {
    text-decoration: none;
    transition: 0.2s color ease-in-out;
  }
  a:focus {
    outline: none;
  }

  /* Main tags */
  html {
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  body {
    position: relative;
    font-size: 16px;
    line-height: 1.5;
  }
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  }
  body {
    ${typography.text}
    background-color: var(--uiColors-globals-bodyBackground);
  }
  ul {
    padding: 0;
    margin: 0;
  }

  /* Inputs reset */
  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='tel'],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    font-family: 'Mulish', Verdana, Arial, sans-serif;
    font-weight: 400;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Page */
  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .main {
    position: relative;
    z-index: 100;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }

  /* Form control */
  .form-control {
    ${typography.h5}
    display: block;
    width: 100%;
    outline: 0 none;
    -webkit-appearance: none;
    height: 40px;
    padding: 10px 20px;
    border-radius: var(--border-radius-roundBox);
    border: 1px solid var(--uiColors-globals-formControlBorder);
    transition: 0.2s border-color ease-in-out;
  }
  .form-control:focus:not([disabled]):not([readonly]):not(.has-error) {
    border-color: var(--uiColors-globals-formControlHoverFocusBorder);
  }
  .form-control.has-error {
    border-color: var(--uiColors-globals-formControlHasErrorBorder);
  }
  .form-control[readonly],
  .form-control[disabled] {
    background-color: var(--uiColors-globals-formControlReadOnlyBackground);
    color: var(--uiColors-globals-formControlReadOnlyText);
  }
  textarea.form-control {
    height: auto;
    resize: vertical;
  }
  .FormControl__error {
    margin-top: 5px;
    font-size: 12px;
    color: var(--uiColors-globals-formControlErrorText);
  }
  .FormControl__errorMessage {
    margin: 0;
  }

  button {
    ${typography.text}
  }
  button:focus {
    outline: none;
  }

  /* Textarea */
  .textArea_default {
    font-size: 14px;
    width: 100%;
    resize: none;
    border-radius: var(--border-radius-roundBox);
    border: 1px solid var(--colors-brandLightGrey1);
    padding: 15px;
    outline: none;
    line-height: 1.5;
  }
  .textarea_smallPadding {
    padding: 4px 12px;
  }
  @media ${phone} {
    .textArea_default {
      font-size: 16px;
    }
  }
  .textArea_default::placeholder {
    color: var(--colors-brandLightGrey8);
  }
  .textArea_default.has-error {
    border-color: var(--uiColors-globals-formControlHasErrorBorder);
  }

  /* General input */
  input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='password'].GeneralInput,
  input[type='number'].GeneralInput,
  input[type='tel'].GeneralInput,
  input[type='email'].GeneralInput,
  input[type='text'].GeneralInput {
    box-shadow: none !important;
    -webkit-appearance: none;
  }
  input.GeneralInput {
    outline: none;
    border: 1px solid var(--colors-brandLightGrey1);
    font-size: 16px;
    color: var(--colors-site);
    border-radius: var(--border-radius-roundBox);
    padding: 0 21px;
    height: 40px;
  }
  /* Remove iOS input shadow */
  :global(input) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
