import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartState } from './types';

const getCartRequest = createAction('serverCart/getCartRequest');
export const getCartSuccess = createAction<ServerCartData>('serverCart/getCartSuccess');
const getCartError = createAction<RSAAActionErrorPayload>('serverCart/getCartError');

// We define requestGetCart as a function, for make mocks in unit tests
const requestGetCart = () => (dispatch, getState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'GET',
      endpoint: `${apiRoot}/cart/`,
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        getCartRequest.type,
        {
          type: getCartSuccess.type,
          payload: getSuccessPayload,
        },
        getCartError.type,
      ],
    }),
  );
};

export default requestGetCart;

export function addGetCartCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(getCartRequest, (state) => {
      state.statusMap.getCart = 'loading';
      state.errorMap.getCart = null;
    })
    .addCase(getCartSuccess, (state, action) => {
      state.statusMap.getCart = 'succeeded';
      state.errorMap.getCart = null;
      state.errors = null;

      if(state.data.etag !== action.payload.etag) {
        state.data = action.payload;
      }
    })
    .addCase(getCartError, (state, action) => {
      state.data.delivery = null;
      state.statusMap.getCart = 'failed';
      state.errorMap.getCart = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
