import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { CartState } from "../general/cartTypes";

export const resetClientCartErrors = createAction('clientCart/resetClientCartErrors');

export const addResetClientCartErrorsCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(resetClientCartErrors, (state) => {
      state.errors = null;
    });
};
