import { REGISTER_TYPE_B2B } from '../../../../redux/constants';
import { HandlerFunc } from '../getRedirectPath';


const handleB2BLink: HandlerFunc = ({ path, clientType, toggles }) => {
  return (
    path.includes(`register=${REGISTER_TYPE_B2B}`) &&
    clientType === 'horeca' &&
    toggles?.HORECA_IS_AVAILABLE
      ? {
          modifiedPath: 'custom-categories/b2b-products/',
          isHandled: true,
          redirectType: 307,
        }
      : path
  );
};

export default handleB2BLink;
