const createReducer = <S>(initialState: S, actionHandlers) => (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  const newState: S = handler
    ? handler(state, action)
    : state;

  return newState;
};

export default createReducer;