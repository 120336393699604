import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { RSAAActionErrorPayload } from '../apiTypes';
import { RootState } from '../reducers';
import { ReferralProgramState, ReturnCoupon } from './types';

const getReturnCouponsRequest = createAction('referralProgram/getReturnCouponsRequest');
const getReturnCouponsSuccess = createAction<ReturnCoupon[]>('referralProgram/getReturnCouponsSuccess');
const getReturnCouponsError = createAction<RSAAActionErrorPayload>('referralProgram/getReturnCouponsError');

const getReturnCoupons =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const { fetchConfig } = selectAppConfig(state);
    const { xChain, xVersion, apiRoot } = fetchConfig;

    return dispatch(
      createRsaaAction<RootState, ReturnCoupon[], undefined>({
        method: 'GET',
        endpoint: `${apiRoot}/user/referral_program/return_coupons`,
        credentials: 'include',
        headers: {
          'x-chain': xChain,
          'x-version': xVersion,
        },
        types: [
          getReturnCouponsRequest.type,
          getReturnCouponsSuccess.type,
          getReturnCouponsError.type,
        ],
      }),
    );
  };

export default getReturnCoupons;

export function addGetReturnCouponsCases(builder: ActionReducerMapBuilder<ReferralProgramState>) {
  builder
    .addCase(getReturnCouponsRequest, (state) => {
      state.returnCoupons = null;
      state.statusMap.getReturnCoupons = 'loading';
      state.errorMap.getReturnCoupons = null;
    })
    .addCase(getReturnCouponsSuccess, (state, action) => {
      /*
        Since API can return HTTP 204 No Content when user has no return coupons,
        we check if payload is truthy, otherwise set state.returnCoupons to null
        (RSAA for 204 response returns undefined as a payload)
      */
      state.returnCoupons = action.payload ? action.payload : null;
      state.statusMap.getReturnCoupons = 'succeeded';
      state.errorMap.getReturnCoupons = null;
    })
    .addCase(getReturnCouponsError, (state, action) => {
      state.returnCoupons = null;
      state.statusMap.getReturnCoupons = 'failed';
      state.errorMap.getReturnCoupons = action.payload;
    });
}
