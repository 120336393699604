import createReducer from '../createReducer';
import { INIT_CLIENT_CART, INIT_SERVER_CART, SET_BASE_URL, SET_CART_TYPE, SET_LANGUAGE, SET_REDUX_TYPE } from './appTypes';

export type CartType = 'client' | 'server' | null;
export type ReduxType = 'initial' | 'client' | 'server';

interface AppState {
  language: string;
  baseURL: string;
  host: string;
  cartType: CartType;
  reduxType: ReduxType;
  stateHasHydrated: boolean;
}

export const appInitialState: AppState = {
  language: null,
  baseURL: null,
  host: null,
  cartType: null,
  reduxType: 'initial',
  stateHasHydrated: null,
};

const reducer = createReducer(
  appInitialState,
  {
    [SET_LANGUAGE]: (state, action) => (
      {
        ...state,
        language: action.payload,
      }
    ),
    [SET_BASE_URL]: (state, action) => (
      {
        ...state,
        baseURL: action.payload.baseURL,
        host: action.payload.host,
      }
    ),
    [INIT_SERVER_CART]: (state) => {
      return {
        ...state,
        cartType: 'server',
      };
    },
    [SET_CART_TYPE]: (state, action) => {
      return {
        ...state,
        cartType: action.payload,
      };
    },
    [INIT_CLIENT_CART]: (state) => {
      return {
        ...state,
        cartType: 'client',
      };
    },
    [SET_REDUX_TYPE]: (state, action) => {
      const newReduxType = action.payload;

      if (newReduxType === 'client') {
        return {
          ...state,
          reduxType: newReduxType,
          stateHasHydrated: true,
        };
      }

      return {
        ...state,
        reduxType: newReduxType,
      };
    },
  },
);

export default reducer;
