import { createReducer } from '@reduxjs/toolkit';
import {  ReferralProgramState} from './types';
import { addGetInfoCases } from './getReferralInfo';
import { addGetReturnCouponsCases } from './getReturnCoupons';

const initialState: ReferralProgramState = {
  info: null,
  returnCoupons: null,
  statusMap: {
    getReferralInfo: 'idle',
    getReturnCoupons: 'idle',
  },
  errorMap: {
    getReferralInfo: null,
    getReturnCoupons: null,
  },
};

const reducer = createReducer(initialState, builder => {
  addGetInfoCases(builder);
  addGetReturnCouponsCases(builder);
});

export default reducer;