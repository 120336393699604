import { useEffect } from "react";
import { SocialMessenger, ZendeskButtonProps } from "../Messengers.types";
import { ListOfMessengersProps } from "../context/MessengersContext";
import MessengerLauncherButton from "./MessengerLauncherButton";
import MessengersList from "./MessengersList";

interface MessengerColumnLinksProps {
  socials: SocialMessenger[];
  zendeskButtonProps: ZendeskButtonProps;
  listOfMessengersProps: ListOfMessengersProps;
}

const MessengerColumnLinks = (props: MessengerColumnLinksProps) => {
  const {
    socials,
    zendeskButtonProps,
    listOfMessengersProps,
  } = props;

  // enable/disable document click handler to close messenger list
  useEffect(() => {
    if (listOfMessengersProps.isOpen) {
      const handleClickDocument = (event: MouseEvent) => {
        const target = (event.target as Element);
        const targetIsChildOfMessengers = !!target?.closest(
          '.MessengerColumnLinks',
        );

        if (!targetIsChildOfMessengers) {
          listOfMessengersProps.close();
        }
      };

      document.addEventListener('click', handleClickDocument, true);

      return () => document.removeEventListener('click', handleClickDocument, true);
    }
  }, [listOfMessengersProps]);

  return (
    <div className='MessengerColumnLinks'>
      {listOfMessengersProps.isOpen ? (
        <MessengersList
          zendeskButtonProps={zendeskButtonProps}
          socials={socials}
          variant='column'
          onClick={listOfMessengersProps.close}
          closeButtonProps={{
            onClick: listOfMessengersProps.close,
          }}
        />
      ) : (
        <MessengerLauncherButton onClick={listOfMessengersProps.open} />
      )}

      <style jsx>{`
        .MessengerColumnLinks {
          position: fixed;
          bottom: 32px;
          right: 32px;
          z-index: 299; //less than cart popup
          user-select: none;
        }
      `}</style>
    </div>
  );
};

export default MessengerColumnLinks;
