import { HandlerFunc } from '../getRedirectPath';

const handleRecipes: HandlerFunc = async ({ path }) => {
  const RECIPES = 'recipes';
  const splitPath = path.split('?');
  const [uri] = splitPath;
  const segments = uri.split('/');
  const segmentsLength = segments.length;
  const langSegment = segments[1];

  const makeRecipesPath = (pathSegments: string[] = []) =>
    ['', langSegment, RECIPES, ...pathSegments].join('/');

  if (segments[2] !== RECIPES) {
    return path;
  }

  // recipe in category
  if (segmentsLength > 5 && segments[5]) {
    const recipeSlug = segments[5];

    return makeRecipesPath([recipeSlug]);
  }

  // category
  if (segmentsLength === 6 && segments[5] === '') {
    return makeRecipesPath();
  }

  // recipe without category
  if (segmentsLength === 4 && segments[3].slice(-5) === '.html') {
    return makeRecipesPath([segments[3].slice(0, -5)]);
  }

  return path;
};

export default handleRecipes;
