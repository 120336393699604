import { ApiErrorShape } from '../../redux/apiTypes';
import { GENERAL_ERROR } from '../../redux/constants';


export interface ErrorsMap {
  [key: string]: string;
}

export interface ErrorsObject {
  [key: string]: ApiErrorShape[]
}

// maybe move to separate module/reducer/middleware/hook
export function errorsMapper(errors: ApiErrorShape[], errorsMap: ErrorsMap): ErrorsObject {
  if (!errors) return {};

  const errorsParsed = errors.map(error => {
    const knownError = error?.field && errorsMap[error.field];

    return knownError ? { [knownError]: error } : { [GENERAL_ERROR]: error };
  });

  const errorsObj = {};
  errorsParsed.forEach(error => {
    for (const fieldName in error) {
      if (errorsObj[fieldName]) errorsObj[fieldName].push(error[fieldName]);
      else errorsObj[fieldName] = [error[fieldName]];
    }
  });

  return errorsObj;
}
