import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { HandlerFunc } from '../getRedirectPath';

const handleProfile: HandlerFunc = async ({ path, appConfig }) => {
  const { languages, defaultLanguage } = appConfig;
  const splitPath = path.split('?');
  const [uri] = splitPath;
  const segments = uri.split('/');
  let langSegment = segments[1] as Language;
  if (!languages.includes(langSegment)) {
    langSegment = defaultLanguage;
  }
  if (segments.includes('my') && segments.includes('profile')) {
    const innerPageSegmentIndex = segments.indexOf('profile') + 1;
    const innerPageSegment = segments[innerPageSegmentIndex];
    const listId = segments[innerPageSegmentIndex + 1];

    switch (innerPageSegment) {
      case 'account':
      case 'password':
      case 'email':
        return ['', langSegment, 'account/settings'].join('/');
      case 'addresses':
        return ['', langSegment, 'account/addresses'].join('/');
      case 'orders':
        return ['', langSegment, 'account/orders'].join('/');
      case 'lists':
        if (!listId) {
          return ['', langSegment, 'lists'].join('/');
        }
        return ['', langSegment, 'lists', listId].join('/');
      default:
        return path;
    }
  }
  return path;
};

export default handleProfile;
