import { useContext } from 'react';
import UserAgent from './UserAgentContext';

const useUserAgent = () => {
  const context = useContext(UserAgent);

  if(!context) {
    throw new Error('useUserAgent must be used within a UserAgentProvider');
  }

  return context;
};

export default useUserAgent;
