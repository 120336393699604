import { Coords, FetchConfig, Store } from '../redux/apiTypes';

/**
 * @description It is now implemented only for delivery_type = plan.
 * Extend current implementation or create a new one for other delivery types.
 */
async function getStoresByCoords(coords: Coords, config: FetchConfig): Promise<Store[]> {
  const { apiRoot, xChain, xVersion, language } = config;
  const url = `${apiRoot}/stores/?delivery_type=plan&coords=${coords.lat},${coords.lng}`;

  const response = await fetch(url, {
    headers: {
      'x-chain': xChain,
      'x-version': xVersion,
      'Accept-Language': language,
    },
  });

  if (!response.ok) {
    throw new Error(`An error occurred while fetching the data: ${url}`);
  }

  const data = await response.json();

  return data;
}

export default getStoresByCoords;
