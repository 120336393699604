import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { ServerCartItem, ServerCartState } from './types';

const updateServerCartItemsInState = createAction<ServerCartItem[]>('serverCart/updateServerCartItemsInState');

export const addUpdateCartItemsInStateCase = (
  builder: ActionReducerMapBuilder<ServerCartState>,
) => {
  builder.addCase(updateServerCartItemsInState, (state, { payload }) => {
    state.data.items = payload;
  });
};

export default updateServerCartItemsInState;