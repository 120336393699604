import Bowser from 'bowser';
import { ReactNode, useState } from 'react';
import UserAgent from './UserAgentContext';

export type PlatformType = 'desktop' | 'mobile' | 'tablet' | 'tv';

export type OS = 'android' | 'ios' | 'linux' | 'macos' | 'windows';

export interface UserAgentContext {
  platform: PlatformType;
  isIE: boolean;
  os: OS;
}

interface Props {
  children: ReactNode;
  userAgent: string;
}

export default function UserAgentProvider(props: Props) {
  const { children, userAgent } = props;

  const [bowser] = useState(() => {
    return Bowser.getParser(userAgent || 'unknown');
  });

  const os = bowser.getOSName(true) as OS;

  const browserName = bowser.getBrowserName();
  const isIE = browserName === 'Internet Explorer';

  const platformType = bowser.getPlatformType(true) as PlatformType;

  const providerValue: UserAgentContext = {
    platform: platformType,
    isIE,
    os,
  };

  return (
    <UserAgent.Provider value={providerValue}>
      {children}
    </UserAgent.Provider>
  );
}
