import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartState } from './types';

const postPackingRequest = createAction('serverCart/postPackingRequest');
export const postPackingSuccess = createAction<ServerCartData>('serverCart/postPackingSuccess');
const postPackingError = createAction<RSAAActionErrorPayload>('serverCart/postPackingError');

const requestPostPacking = (packingType: string) => (dispatch, getState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/packing/`,
      body: JSON.stringify({ packing: packingType }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postPackingRequest.type,
        {
          type: postPackingSuccess.type,
          payload: getSuccessPayload,
        },
        postPackingError.type,
      ],
    }),
  );
};

export default requestPostPacking;

export function addPostPackingCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postPackingRequest, state => {
      state.statusMap.postPacking = 'loading';
      state.errorMap.postPacking = null;
    })
    .addCase(postPackingSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postPacking = 'succeeded';
      state.errorMap.postPacking = null;
      state.errors = null;
    })
    .addCase(postPackingError, (state, action) => {
      state.statusMap.postPacking = 'failed';
      state.errorMap.postPacking = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
