import getStoresByDeliveryType from 'src/ajax/getStoresByDeliveryType';
import getStoresByDeliveryTypeAndCoords from 'src/ajax/getStoresByDeliveryTypeAndCoords';
import { DeliveryType, FetchConfig, Store } from 'src/redux/apiTypes';
import { CoordsStrings } from '../../../AddressManagement.types';
import { NovaPoshtaType } from '../useNovaPoshtaTabProps';

export async function getStoreData(
  coords: CoordsStrings,
  fetchConfig: FetchConfig,
  novaPoshtaType?: NovaPoshtaType,
): Promise<{
  store: Store;
  deliveryType: DeliveryType;
}> {
  let stores = [];

  if (novaPoshtaType !== 'postomat') {
    stores = await getStoresByDeliveryTypeAndCoords(
      'nova_poshta_fresh',
      coords,
      fetchConfig,
    );

    if (stores?.length) {
      return {
        store: stores[0],
        deliveryType: 'nova_poshta_fresh',
      };
    }
  }

  stores = await getStoresByDeliveryType('nova_poshta', fetchConfig);

  return {
    store: stores?.[0],
    deliveryType: 'nova_poshta',
  };
}
