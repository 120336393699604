import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { CartState } from "../general/cartTypes";

export const restoreCartPrice = createAction('clientCart/restoreCartPrice');

export const addRestoreCartPriceCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(restoreCartPrice, (state) => {
      state.cartPrice = {
        price: {
          ...state.cartPrice?.price,
          delivery_fee: 0,
          subtotal: 0,
          total: 0,
          weight_fee: 0,
          tips: 0,
        },
        total_weight: 0,
      };
    });
};
