import * as Sentry from '@sentry/nextjs';
import { DEFAULT_DELIVERY_TYPE } from 'src/redux/constants';
import { clearLocalCart, getLocalCart } from '../../../utils/cart/saveCart';
import setDeliveryTypeCookie from '../../../utils/delivery/setDeliveryTypeCookie';
import setStoreIdCookie from '../../../utils/delivery/setStoreIdCookie';
import { setAddressIsChecked } from '../../addressManagement/addressManagement';
import { setServerCartItems } from '../../cart/serverCart/operations/setServerCartItems';
import { openModal } from '../../modals';
import { RootState } from '../../reducers';
import { INIT_SERVER_CART } from '../appTypes';
import { fetchGeneralAppData } from './fetchGeneralAppData';

export const initServerCartAndDelivery = (ssrStoreId: string) => async (
  dispatch,
  getState,
): Promise<boolean> => {
  const state: RootState = getState();
  const serverCartData = state.serverCart.data;
  const ssrDeliveryType = state.addressManagement.delivery?.type || DEFAULT_DELIVERY_TYPE;
  const serverAddress = serverCartData.delivery;

  if (serverAddress) {
    const serverDeliveryType = serverAddress.type;
    const serverStoreId = serverCartData.store_id;

    const deliveryMethodsAreDifferent =
      serverDeliveryType !== ssrDeliveryType || serverStoreId !== ssrStoreId;

    // StoreId sentry report
    if (!serverStoreId || serverStoreId === 'undefined') {
      Sentry.captureException(
        new Error('StoreId is undefined'),
        {
          extra: {
            where: 'initClientCartAndDelivery.ts',
            cartType: state.app?.cartType,
            deliveryType: state.serverCart?.data?.delivery?.type,
            address: JSON.stringify(state.addressManagement?.delivery),
          },
        },
      );
    }

    setStoreIdCookie(serverStoreId);
    setDeliveryTypeCookie(serverDeliveryType);

    if (deliveryMethodsAreDifferent) {
      // Reloading the page is necessary to update the data loaded on the SSR,
      // which depends on the values of the storeId and deliveryType
      window.location.reload();
      return false;
    }
  }

  await dispatch(fetchGeneralAppData(ssrStoreId));

  dispatch(setAddressIsChecked());

  const localItems = getLocalCart();
  const serverItems = serverCartData.items;
  const userHasToChooseItems = !!serverItems.length && !!localItems.length;
  const localItemsShouldBeUsed = !!localItems.length && !serverItems.length;

  // User resolves how to merge items
  if (userHasToChooseItems) {
    dispatch(
      openModal('cartResolveModal', { serverItemsCount: serverItems.length }),
    );

    return false;
  }

  // Local items are adding to server cart
  if (localItemsShouldBeUsed) {
    await dispatch(setServerCartItems(localItems));

    clearLocalCart();
  }

  dispatch({ type: INIT_SERVER_CART });
  return true;
};
