let topPosition = 0;

export function disableBodyScroll() {
  window.scrollLocksCounter
    ? window.scrollLocksCounter += 1
    : window.scrollLocksCounter = 1;
  topPosition = window.pageYOffset;
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.top = `-${topPosition}px`;
  document.body.style.width = '100%';
}

export function enableBodyScroll() {
  if (window.scrollLocksCounter && window.scrollLocksCounter > 1) {
    window.scrollLocksCounter += -1;
  } else {
    window.scrollLocksCounter = 0;
    document.body.style.overflow = 'visible';
    document.body.style.position = 'static';
    document.body.style.top = '0';
    document.body.style.width = '100%';
    window.scrollTo(0, topPosition);
    topPosition = 0;
  }
}

/** based on the article:  https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/ */
