import { DeliveryType, FetchConfig, Store } from 'src/redux/apiTypes';
import getUserAgent from '../utils/api/getUserAgent';

async function getStoresByDeliveryType(deliveryType: DeliveryType, config: FetchConfig): Promise<Store[]> {
  const { apiRoot, xChain, xVersion, language } = config;
  const response = await fetch(
    `${apiRoot}/stores/?delivery_type=${deliveryType}`,
    {
      headers: {
        'x-chain': xChain,
        'x-version': xVersion,
        'Accept-Language': language,
        'User-Agent': getUserAgent(),
      },
    },
  );

  return await response.json();
}

export default getStoresByDeliveryType;
