import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction, RSAARequestType } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getResponseErrors } from '../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../apiTypes';
import { RootState } from '../reducers';
import { SessionInfo, UserState } from './userTypes';

interface Meta {
  headers: {
    retryAfterSeconds: string
  }
}

export interface SuccessAction {
  type: string
  payload: SessionInfo
  meta: Meta
}

interface ErrorAction {
  type: string
  payload: RSAAActionErrorPayload
  meta: Meta
}

const postLoginRequest = createAction('user/postLoginRequest');
const postLoginSuccess = createAction<SessionInfo>('user/postLoginSuccess');
export const postLoginError = createAction<RSAAActionErrorPayload>('user/postLoginError');

const requestPostLogin = (login: string, password: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const body = JSON.stringify({ login, password });
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot } = fetchConfig;

  return dispatch(createRsaaAction<RootState, SessionInfo, Meta>({
    method: 'POST',
    credentials: 'include',
    endpoint: `${apiRoot}/user/login/`,
    headers: {
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body,
    types: [
      {
        type: postLoginRequest.type,
        payload: login,
      } as RSAARequestType,
      {
        type: postLoginSuccess.type,
        meta: (action, state, res) => {
          const retryAfterSeconds = res.headers.get('retry-after');

          return { headers: { retryAfterSeconds } };
        },
      },
      {
        type: postLoginError.type,
        meta: (action, state, res) => {
          const retryAfterSeconds = res.headers.get('retry-after');

          return { headers: { retryAfterSeconds } };
        },
      },
    ],
  }));
};

export default requestPostLogin;

export function getCanLoginTimestamp<T extends SuccessAction | ErrorAction>(
  action: T,
): number | null {
  const retryAfterSeconds = action.meta?.headers.retryAfterSeconds;

  if (retryAfterSeconds) {
    const retryAfterSecondsInt = parseInt(retryAfterSeconds);

    return Date.now() + retryAfterSecondsInt * 1000;
  }

  return null;
}

export function addPostLoginCases(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(postLoginRequest, (state, action) => {
    state.lastUsedLogin = action.payload;
    state.isFetching.login = true;
    state.error.login = [];
  }).addCase(postLoginSuccess, (state, action) => {
    state.canLoginTimestamp = getCanLoginTimestamp(action as SuccessAction);
    state.isFetching.login = false;
    state.error.login = [];
    state.isLoggedIn = true;
    state.sessionInfo = action.payload;
  }).addCase(postLoginError, (state, action) => {
    state.canLoginTimestamp = getCanLoginTimestamp(action as ErrorAction);
    state.isFetching.login = false;
    state.error.login = getResponseErrors(action.payload);
  });
}
