import { CartItem } from '../../redux/cart/general/cartTypes';
import { IS_SERVER } from '../../redux/constants';

export function saveCart(products: CartItem[]) {
  if (!IS_SERVER) {
    localStorage?.setItem('cart', JSON.stringify(products));
  }
}

export function getLocalCart(): CartItem[] {
  const localItems: string | null = localStorage?.getItem('cart');
  const cartItems: CartItem[] = localItems ? JSON.parse(localItems) : [];

  return cartItems;
}

export const clearLocalCart = () => saveCart([]);

export default saveCart;
