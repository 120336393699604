export const SEND_USER_PROFILE_DATA_REQUEST = 'SEND_USER_PROFILE_DATA_REQUEST';
export const SEND_USER_PROFILE_DATA_SUCCESS = 'SEND_USER_PROFILE_DATA_SUCCESS';

export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';

export const USER_PROFILE_DATA_ERROR = 'USER_PROFILE_DATA_ERROR';

export const RESET_ERRORS = 'RESET_ERRORS';

export const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA';

export const ADD_USER_PHONE_REQUEST = 'ADD_USER_PHONE_REQUEST';
export const ADD_USER_PHONE_SUCCESS = 'ADD_USER_PHONE_SUCCESS';

export const CONFIRM_USER_PHONE_REQUEST = 'CONFIRM_USER_PHONE_REQUEST';
export const CONFIRM_USER_PHONE_SUCCESS = 'CONFIRM_USER_PHONE_SUCCESS';

export const DELETE_USER_PHONE_REQUEST = 'DELETE_USER_PHONE_REQUEST';
export const DELETE_USER_PHONE_SUCCESS = 'DELETE_USER_PHONE_SUCCESS';

export const CHANGE_USER_PHONE_REQUEST = 'CHANGE_USER_PHONE_REQUEST';
export const CHANGE_USER_PHONE_SUCCESS = 'CHANGE_USER_PHONE_SUCCESS';

export const CONFIRM_CHANGE_USER_PHONE_REQUEST = 'CONFIRM_CHANGE_USER_PHONE_REQUEST';
export const CONFIRM_CHANGE_USER_PHONE_SUCCESS = 'CONFIRM_CHANGE_USER_PHONE_SUCCESS';

export const FETCH_USER_PHONE_LIST_REQUEST = 'FETCH_USER_PHONE_LIST_REQUEST';
export const FETCH_USER_PHONE_LIST_SUCCESS = 'FETCH_USER_PHONE_LIST_SUCCESS';

export const REGISTER_HORECA_REQUEST = 'REGISTER_HORECA_REQUEST';
export const REGISTER_HORECA_SUCCESS = 'REGISTER_HORECA_SUCCESS';
export const REGISTER_HORECA_ERROR = 'REGISTER_HORECA_ERROR';
