import { promises as fs } from 'fs';
import { ENVIRONMENT_NAME } from 'src/redux/constants';
import { withMemcached } from '../ApiCacheClient/withMemcached';
import fetchOverallConfig from './fetchOverallConfig';
import getRetailConfig from './getRetailConfig';
import getRetailNameFromHost from './getRetailNameFromHost';
import { RawConfig } from './types';

export default async function getRawConfig(host: string): Promise<RawConfig | null> {
  const { result } = await withMemcached(
    async () => {
      const retailName = getRetailNameFromHost(host);

      if (
        ENVIRONMENT_NAME === 'development' &&
        retailName.startsWith('localhost:')
      ) {
        try {
          const buffer = await fs.readFile(`config_local.json`);
          const jsonData = buffer.toString();
          const localConfig = JSON.parse(jsonData) as RawConfig;

          return localConfig;
        } catch (_error) {
          console.error(
            '\x1b[31m%s\x1b[0m',
            'You need to create `config_local.json` in the root folder. You can use `config_local.sample.json`.',
          );
        }
      }

      const overallConfig = await fetchOverallConfig();
      const retailConfig = getRetailConfig(overallConfig, retailName);

      return retailConfig;
    },
    { cacheKey: host },
  );
  return result;
}
