import { Coords } from 'src/redux/apiTypes';
import { CoordsStrings } from '../AddressManagement.types';

export function coordsToString(coords: Coords): CoordsStrings {
  return {
    lat: '' + coords.lat,
    lng: '' + coords.lng,
  };
}

export function coordsToNumbers(coords: CoordsStrings): Coords {
  return {
    lat: Number(coords.lat),
    lng: Number(coords.lng),
  };
}

export function getCoordsIsValid(coords: Coords): boolean {
  return !!(coords?.lat && coords?.lng);
}
