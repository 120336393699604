import { ServerCartDelivery } from "../../serverCart/types";

const validateWarehouse = (delivery: ServerCartDelivery) => {
  const { address } = delivery;

  if (address.nova_poshta && !address.nova_poshta.warehouse) {
    return false;
  }

  if(address.nova_poshta_fresh && !address.nova_poshta_fresh.warehouse){
    return false;
  }

  return true;
};

export default validateWarehouse;
