import { Dispatch } from 'redux';
import { DeliveryType } from 'src/redux/apiTypes';
import setSelectedAddress from '../../addressManagement/setSelectedAddress';
import { getTranslations } from '../../translations/translationsActions';
import { setBaseURL } from '../appActions';

interface Params {
  baseURL: string;
  deliveryType?: DeliveryType;
  host?: string;
}

export const initAppOnServerSide = (params: Params) => async (
  dispatch: Dispatch,
) => {
  const {
    baseURL,
    deliveryType = null,
    host,
  } = params;

  dispatch(setSelectedAddress({
    type: deliveryType,
    address: {},
    hash: '',
  }));
  dispatch(setBaseURL(baseURL, host));

  await dispatch(getTranslations());
};
