import { createSelector, Selector } from '@reduxjs/toolkit';
import { NovaPoshtaType } from 'src/modules/AddressManagement/components/NovaPoshtaTab/useNovaPoshtaTabProps';
import { RootState } from '../../reducers';

const selectNovaPoshtaType: Selector<
  RootState, NovaPoshtaType
> = createSelector(
  [
    state => state.addressManagement?.address,
  ],
  (address) => {
    if(
      !address?.department ||
      (
        address?.delivery !== 'nova_poshta' &&
        address?.delivery !== 'nova_poshta_fresh'
      )
    ) {
      return null;
    }

    return address.department.type;
  },
);

export default selectNovaPoshtaType;
