export const floorRegexp = /^\d*$|^$/;

export const pieceInputRegexp = /^\d{0,3}$|^$/;
export const weightInputRegexp = /^\d{0,3}\.\d{0,1}?$|^\d{0,3}$|^$/;

export const onlyLatin = /^[a-zA-Z-' ]*$/;
export const onlyLatinName = /^[a-zA-Z-']*$/;
export const onlyLatinEmail = /[^A-Za-z0-9!#$%'*+-=/?^_`{|}~@]/;
export const onlyDigits = /^[0-9]*$/;
export const taxIdNumRegexp = /^\d{8,10}$/;
export const taxIdInputRegexp = /^\d{0,10}$/;
export const hasCyrillic = /[а-яА-ЯёЁ]/;
export const cyrillicOnly = /^[а-яёіїєґА-ЯЁІЇЄҐ\s-`'’ʼ]+$/;

export const recipientPhoneRegexp = /^(?:380\d{9}|373\d{8})$/;

export const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
