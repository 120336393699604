import { Dispatch } from 'redux';
import { modalsManager } from 'src/utils/system/modalsManager';
import { openModal } from "../../../modals";
import { RootState } from "../../../reducers";
import { ClientCartRequest, ClientCartRequestCartItem, ClientCartRequestDeliveryData } from "../clientCartTypes";
import requestPostCartPrice from "../requestPostClientCart";
import { restoreCartPrice } from "../restoreCartPrice";

interface GetCartPriceParams {
  ignoreEmptyCart?: boolean;
  storeId?: string;
}

export const getCartPrice = (params: GetCartPriceParams) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { ignoreEmptyCart, storeId = 'default' } = params;
  const state = getState();
  const { cart } = state.cart;

  if (cart.length === 0 && !ignoreEmptyCart) {
    return dispatch(restoreCartPrice());
  }

  const availableCart = cart.filter(
    item => item.isAvailable && item.amount > 0,
  );

  if (availableCart.length === 0 && !ignoreEmptyCart) {
    return dispatch(restoreCartPrice());
  }

  const { delivery } = state.addressManagement;
  const deliveryType = delivery?.type;

  const cartData: ClientCartRequestCartItem[] = availableCart.map(({ ean, amount }) => ({
    ean,
    amount,
  }));

  const deliveryData: ClientCartRequestDeliveryData = {
    type: deliveryType,
  };

  if (deliveryType === 'plan') {
    deliveryData.address = {
      coords: `${delivery.address.plan.coords.lat},${delivery.address.plan.coords.lng}`,
    };
  }
  else if (deliveryType === 'nova_poshta') {
    const { settlement,  warehouse } = delivery.address.nova_poshta;

    deliveryData.nova_poshta = {
      settlement_id: settlement.id,
      warehouse_id: warehouse.id,
      type: warehouse.type,
    };
  }
  else if (deliveryType === 'nova_poshta_fresh') {
    const { settlement,  warehouse } = delivery.address.nova_poshta_fresh;

    deliveryData.nova_poshta_fresh = {
      settlement_id: settlement.id,
      warehouse_id: warehouse.id,
      type: warehouse.type,
    };
  }
  else if (deliveryType === 'nova_poshta_address') {
    const { settlement, street } = delivery.address.nova_poshta_address.address;

    deliveryData.nova_poshta_address = {
      address: {
        settlement: {
          id: settlement.id,
        },
        street: {
          id: street.id,
        },
      },
    };
  }

  if (!delivery) {
    if (!ignoreEmptyCart) {
      dispatch(openModal('addressModal', undefined, { onPageReload:() => modalsManager.addModal('cartModal') }));
    }

    // in some places the error property in the response object is expected
    return Promise.resolve({ error: true });
  }

  const requestBody: ClientCartRequest = {
    delivery: deliveryData,
    cart: cartData,
  };

  return dispatch(requestPostCartPrice(storeId, requestBody));
};
