import { FetchConfig, Offer, Store } from '../../redux/apiTypes';

async function requestGetMainPromotion(
  store: Store,
  fetchConfig: FetchConfig,
): Promise<null | Offer> {
  const { xChain, apiRoot, xVersion, language } = fetchConfig;
  const { delivery_service, id } = store;

  try {
    const params = `?delivery_service=${delivery_service}&store_id=${id}`;
    const url = `${apiRoot}/promotions/main/${params}`;
    const response = await fetch(
      url,
      {
        headers: {
          'Accept-Language': language,
          'x-chain': xChain,
          'x-version': xVersion,
        },
      },
    );

    if (!response.ok) {
      return null;
    }

    return response.json();
  } catch {
    return null;
  }
}

export default requestGetMainPromotion;
