import { FetchConfig, Store } from "src/redux/apiTypes";
import { fetchWithMemcached } from "src/utils/ApiCacheClient/fetchWithMemcached";

const requestGetStoreByIdSsr = async (storeId: string, fetchConfig: FetchConfig): Promise<Store> => {
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const store = await fetchWithMemcached<Store>({
    url: `${apiRoot}/stores/${storeId}/`,
    cacheKey: `${xChain}-store-${storeId}-${language}`,
    fetchOptions: {
    credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
    },
  });

  return store.data;
};

export default requestGetStoreByIdSsr;
