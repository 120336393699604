import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { HandlerFunc } from '../getRedirectPath';

const handleStore: HandlerFunc = ({ path, appConfig }) => {
  const { languages, defaultLanguage } = appConfig;
  const splitPath = path.split('?');
  const [uri, params] = splitPath;
  const segments = uri.split('/');
  let langSegment = segments[1] as Language;
  if (!languages.includes(langSegment)) {
    langSegment = defaultLanguage;
  }
  if (segments[2] === 'store') {
    const newUri = ['', langSegment, ''].join('/');
    const newParams = params ? `?${params}` : '';
    return `${newUri}${newParams}`;
  }
  return path;
};

export default handleStore;
