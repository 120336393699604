import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { ProductState } from "./types";

const resetProductsData = createAction('product/resetProductsData');

export default resetProductsData;

export function addResetProductsData(builder: ActionReducerMapBuilder<ProductState>) {
  builder.addCase(resetProductsData, (state) => {
    state.productsData = null;
  });
}
