import { useState } from 'react';
import { RequestStatus } from 'src/redux/apiTypes';

/**
 * A hook for managing request status.
 *
 * This hook initializes a state for the request status and provides a function to update it.
 * It is used to manage request statuses such as 'idle', 'loading', 'success', 'error'.
 *
 * @param [initialStatus='idle'] - The initial state of the request status.
 * @returns An object with the current request status and a function to update it.
 * @returns status - The current request status.
 * @returns setStatus - A function to update the request status.
 */
export default function useRequestStatus(initialStatus?: RequestStatus) {
  const [status, setStatus] = useState<RequestStatus>(initialStatus || 'idle');

  return { status, setStatus };
}
