import { openModal } from "../../redux/modals";
import removeAllCookies from "../../utils/system/removeAllCookies";
import { IS_SERVER } from "../constants";

/** @todo handle 403 differently when API fixed */
/** @todo use TS */
const unauthorizedErrorMiddleware = store => next => action => {
  const { payload, error } = action;

  if (!error) {
    return next(action);
  }

  const { status } = payload;

  if (status !== 401 && status !== 403) {
    return next(action);
  }

  const { dispatch } = store;

  dispatch(openModal('loginOrRegistrationModal', { selectedTab: 'login' }));
  removeAllCookies();

  if (!IS_SERVER) {
    localStorage?.removeItem('userData');
  }

  return next(action);
};

export default unauthorizedErrorMiddleware;
