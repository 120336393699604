import { withMemcached } from 'src/utils/ApiCacheClient/withMemcached';
import getRedirectPath, { GetRedirectPathFunc } from './getRedirectPath';

const getRedirectPathCached: GetRedirectPathFunc = async (...args) => {
  const [context] = args;
  const { path, clientType, preferredLanguage, storeId } = context;

  const cacheKey = `${path}-${storeId}-${clientType}-${preferredLanguage}`;

  const { result } = await withMemcached(
    () => {
      return getRedirectPath(...args);
    },
    {
      cacheKey,
    },
  );

  return result;
};

export default getRedirectPathCached;
