import { getCartPrice } from 'src/redux/cart/clientCart/operations/getCartPrice';
import { ServerCartDelivery } from 'src/redux/cart/serverCart/types';
import { ReduxThunkAction } from 'src/redux/reducers';
import { convertCoordsNumberToCoordsString } from 'src/utils/delivery/convertCoords';
import { setAddress } from '../../../redux/addressManagement/addressManagement';
import { Store } from '../../../redux/apiTypes';
import selectSelectedDelivery from '../../../redux/cart/general/selectSelectedDelivery';
import requestPostCartDelivery from '../../../redux/cart/serverCart/requestPostCartDelivery';
import { setAddressInLocalStorage } from '../../../utils/delivery/addressLocalStorage';
import setDeliveryTypeCookie from '../../../utils/delivery/setDeliveryTypeCookie';
import setStoreIdCookie from '../../../utils/delivery/setStoreIdCookie';

const setNewDelivery = (newDelivery: ServerCartDelivery, newStore: Store, prevStore: Store): ReduxThunkAction<boolean> =>
  async (dispatch, getState) => {
    const state = getState();
    const prevStoreId = prevStore.id;
    const newStoreId = newStore.id;
    const prevDeliveryType = selectSelectedDelivery(state)?.type;
    const newDeliveryType = newDelivery.type;
    const cartType = state.app.cartType;
    const onPageReload = state.modals.sharedData?.onPageReload;
    const onSetNewDelivery = state.modals.sharedData?.onSetNewDelivery;

    if (typeof onSetNewDelivery === 'function') {
      onSetNewDelivery();
    }

    const storeIdIsChanged = prevStoreId !== newStoreId;
    const deliveryTypeIsChanged = prevDeliveryType !== newDeliveryType;
    const pageShouldBeReloaded = storeIdIsChanged || deliveryTypeIsChanged;

    if (cartType === 'client') {
      let result: ServerCartDelivery = newDelivery;

      if (newDeliveryType === 'nova_poshta_address') {
        const { address, comments } = newDelivery.address.nova_poshta_address;
        result = {
          ...newDelivery,
          address: {
            nova_poshta_address: {
              comments,
              address: {
                ...address,
                settlement: {
                  ...address.settlement,
                  coords: convertCoordsNumberToCoordsString(newStore.address.coords),
                },
              },
            },
          },
        };
      }

      setAddressInLocalStorage(result);
      dispatch(setAddress(result));
      dispatch(getCartPrice({
        ignoreEmptyCart: true,
        storeId: newStoreId,
      }));
    }

    if (cartType === 'server') {
      await dispatch(requestPostCartDelivery({ delivery: newDelivery }));
    }

    setStoreIdCookie(newStoreId);
    setDeliveryTypeCookie(newDeliveryType);

    if (pageShouldBeReloaded) {
      if(typeof onPageReload === 'function') {
        onPageReload();
      }

      window.location.reload();
    }

    return pageShouldBeReloaded;
  };

export default setNewDelivery;
