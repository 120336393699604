import { ScheduleDeliveryList } from "src/swr/useScheduleDelivery/scheduleDeliveryTypes";

const getClosestSlot = (scheduleDeliveryList: ScheduleDeliveryList) => {
  const closestList = scheduleDeliveryList
    .map(daySchedule => daySchedule.items.find(slot => slot.is_open));

  const availableClosestSlot = closestList.filter(closestItem => !!closestItem)[0];

  if (!availableClosestSlot) {
    return null;
  }

  return availableClosestSlot;
};

export default getClosestSlot;
