import getInstance from 'src/utils/system/ApiCacheClient';

const TTL = Number(process.env.CACHE_TTL) || 60;

interface CacheClient {
  get(cacheKey: string): Promise<string | null>;
  set(cacheKey: string, data: string, ttl: number): Promise<void>;
}

interface Config {
  url: string;
  cacheKey: string;
  fetchOptions: RequestInit;
  ttlSeconds?: number;
}

interface FetchedResult<ResponseData> {
  data: ResponseData;
  source: 'fetch';
  status: number;
}

interface CachedResult<ResponseData> {
  data: ResponseData;
  source: 'cache';
}

type fetchWithMemcachedResult<T> = FetchedResult<T> | CachedResult<T>;

export async function fetchWithMemcached<ResponseData>(
  config: Config,
): Promise<fetchWithMemcachedResult<ResponseData>> {
  const { cacheKey, url, fetchOptions, ttlSeconds = TTL } = config;

  const cacheClient: CacheClient = getInstance();

  let cachedResponse: string;

  try {
    cachedResponse = await cacheClient.get(cacheKey);
  } catch (error) {
    console.error(`ERROR RETRIEVING ${cacheKey}: ${error.message}`);
  }

  if (cachedResponse) {
    return {
      source: 'cache',
      data: JSON.parse(cachedResponse),
    };
  }

  const response = await fetch(url, fetchOptions);
  const data: ResponseData = await response.json();

  if (response.ok) {
    try {
      await cacheClient.set(cacheKey, JSON.stringify(data), ttlSeconds);
    } catch (error) {
      console.error(`ERROR SETTING ${cacheKey}: ${error.message}`);
    }
  }

  return {
    source: 'fetch',
    data,
    status: response.status,
  };
}
