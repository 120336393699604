import { InitialAppConfig } from 'src/utils/appConfig/getInitialAppConfig';
import createReducer from '../createReducer';

export const setInitialAppConfig = (config: InitialAppConfig) => ({
  type: 'SET_INITIAL_APP_CONFIG',
  payload: config,
});

export const initialState = {} as InitialAppConfig;

const reducer = createReducer(initialState, {
  SET_INITIAL_APP_CONFIG: (_state, action) => {
    return {
      ...action.payload,
    };
  },
});

export default reducer;
