import { HandlerFunc } from '../getRedirectPath';

const handleApi: HandlerFunc = ({ path }) => {
  if (path.includes('api=')) {
    const [mainUrl, queryParams] = path.split('?');
    const clearedParams = queryParams
      .split('&')
      .filter((p) => !p.includes('api='))
      .join('&');

    const newPath = `${mainUrl}${clearedParams ? `?${clearedParams}` : ''}`;

    return newPath;
  }

  return path;
};

export default handleApi;
