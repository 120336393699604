import { createReducer } from '@reduxjs/toolkit';
import { addFetchProductData } from './fetchProductData';
import { addFetchProductsData } from './fetchProductsData';
import { addResetFetchProductDataError } from './resetFetchProductDataError';
import { addResetProductData } from './resetProductData';
import { addResetProductsData } from './resetProductsData';
import { ProductState } from './types';

export const productInitialState: ProductState = {
  productData: null,
  productsData: null,
  isFetchingProductData: false,
  errorFetchingProductData: false,
  isFetching: false,
  errors: {
    fetchProductsData: null,
  },
};

const reducer = createReducer(productInitialState, builder => {
  addResetProductData(builder);
  addResetProductsData(builder);
  addResetFetchProductDataError(builder);
  addFetchProductData(builder);
  addFetchProductsData(builder);
});

export default reducer;
