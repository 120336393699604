import isCategoriesPage from 'src/utils/filter/isCategoriesPage';
import { HandlerFunc } from '../getRedirectPath';

const customCategoriesToRedirect = [
  `own-brands`,
  `take-more`,
  `b2b-products`,
  `promotions`,
];

const handleCustomCategory: HandlerFunc = async ({ path }) => {
  const splitPath = path.split('?');
  const [uri] = splitPath;
  const segments = uri.split('/');
  const langSegment = segments[1];
  const customCategoryOldIdSegment = segments[2];
  const categoryIdSegment = segments[3];

  const redirectFromCategories = isCategoriesPage(path) && customCategoriesToRedirect.find(i => categoryIdSegment?.includes(i));
  const redirectFromCustomCategories = customCategoriesToRedirect.find(i => customCategoryOldIdSegment?.includes(i));

  const redirectPage = redirectFromCategories || redirectFromCustomCategories;

  if (redirectPage) {
    return ['', langSegment, 'custom-categories', redirectPage].join('/');
  }

  return path;
};

export default handleCustomCategory;
