import { createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction, RSAA } from 'redux-api-middleware';
import { RegistrationHorecaDataToBeSent } from 'src/components/views/RegistrationStepOne/RegistrationStepOne';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import getUserAgent from '../../utils/api/getUserAgent';
import { sendUserId } from '../../utils/marketing/enhancedEcommerce';
import { RSAAActionErrorPayload } from '../apiTypes';
import { BUILD_NUMBER, CHANGE_PASSWORD, CHECK_LOGIN, CONFIRM_RECOVERY_ERROR, CONFIRM_RECOVERY_REQUEST, CONFIRM_RECOVERY_SUCCESS, CREATE_PASSWORD_ERROR, CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, ERROR, LOGIN, RECOVERY_PASSWORD, REGISTER_CONFIRM, REGISTER_SIGNUP, REQUEST, RESET_ERROR, RESTORE_ORDER_INFO, SEND_FEEDBACK, SUCCESS } from '../constants';
import { RootState } from '../reducers';
import { SessionInfo, UserConfirmSignupData, UserRegister } from './userTypes';

export const resetError = (type: string) => ({
  type: type + RESET_ERROR,
});

export const sendFeedback = (data, orderId, params = {}) => (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;
  const bodyToBeSent = JSON.stringify({
    comment: data.comment,
    net_promoter_score: data.net_promoter_score,
    customer_satisfaction: data.customer_satisfaction,
    ...params,
  });

  return dispatch({
    [RSAA]: {
      method: 'POST',
      endpoint: `${apiRoot}/user/orders/${orderId}/feedback/`,
      credentials: 'include',
      headers: {
        'Accept-Language': state.app.language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body: bodyToBeSent,
      types: [
        SEND_FEEDBACK + REQUEST,
        SEND_FEEDBACK + SUCCESS,
        SEND_FEEDBACK + ERROR,
      ],
    },
  });
};

export const postQuickFeedbackRequest = createAction(
  'user/postQuickFeedbackRequest',
);

export const postQuickFeedbackSuccess = createAction(
  'user/postQuickFeedbackSuccess',
);

export const postQuickFeedbackError = createAction<RSAAActionErrorPayload>(
  'user/postQuickFeedbackError',
);

export interface QuickFeedbackProblem {
  category: string;
  subcategories: string[];
  comment?: string;
}
export const sendQuickFeedback = (comment: string, orderId: string, problems: QuickFeedbackProblem[]) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;
  const bodyToBeSent = JSON.stringify({
    problems,
    comment,
    client_information: {
      platform: 'WEB',
      device: getUserAgent(),
      app_version: BUILD_NUMBER,
    },
  });

  return dispatch(createRsaaAction<RootState, undefined, undefined>({
    method: 'POST',
    endpoint: `${apiRoot}/user/orders/${orderId}/quick_feedback/`,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body: bodyToBeSent,
    types: [
      postQuickFeedbackRequest.type,
      postQuickFeedbackSuccess.type,
      postQuickFeedbackError.type,
    ],
  }));
};

export const clearQuickFeedbackIsSent = createAction('user/clearQuickFeedbackIsSent');

export const userLoginRecovery = (data) => (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;
  const bodyData = JSON.stringify({
    login: data.login,
    captcha_token: data.captcha_token,
  });

  return dispatch({
    [RSAA]: {
      method: 'POST',
      credentials: 'include',
      endpoint: `${apiRoot}/user/password_recovery/`,
      headers: {
        'Accept-Language': state.app.language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body: bodyData,
      types: [
        RECOVERY_PASSWORD + REQUEST,
        {
          type: RECOVERY_PASSWORD + SUCCESS,
          meta: (action, state, res) => {
            const retryAfterSeconds = res.headers.get('retry-after');

            return { headers: { retryAfterSeconds } };
          },
        },
        {
          type: RECOVERY_PASSWORD + ERROR,
          meta: (action, state, res) => {
            const retryAfterSeconds = res.headers.get('retry-after');

            return { headers: { retryAfterSeconds }, status: res.status };
          },
        },
      ],
    },
  });
};

export const changePassword = (newPassword, oldPassword) => (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;

  const body = JSON.stringify({
    password_new: newPassword,
    password: oldPassword,
  });

  return dispatch({
    [RSAA]: {
      method: 'POST',
      credentials: 'include',
      endpoint: `${apiRoot}/user/profile/password/`,
      headers: {
        'Accept-Language': state.app.language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body,
      types: [
        CHANGE_PASSWORD + REQUEST,
        CHANGE_PASSWORD + SUCCESS,
        CHANGE_PASSWORD + ERROR,
      ],
    },
  });
};

export interface UserSignupBody {
  phone: string;
  captcha_token: string;
  subscribed_to_marketing: boolean;
  accepted_privacy_policy: boolean;
  horeca?: RegistrationHorecaDataToBeSent;
}

export interface UserSignupMeta {
  headers: {
    retryAfterSeconds: string;
  };
}

export interface AgreementsObject {
  acceptedPrivacyPolicy: boolean;
  subscribedToMarketing?: boolean;
}

export interface UserRegistrationSignupOperation {
  (
    phone: string,
    agreements: AgreementsObject,
    modeIsHoreca: boolean,
    horecaData: RegistrationHorecaDataToBeSent,
    captcha_token: string,
  )
}

export const userRegistrationSignup: UserRegistrationSignupOperation = (
  phone,
  agreements,
  modeIsHoreca,
  horecaData,
  captcha_token,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;

  const body: UserSignupBody = {
    phone,
    captcha_token,
    subscribed_to_marketing: agreements.subscribedToMarketing,
    accepted_privacy_policy: agreements.acceptedPrivacyPolicy,
  };

  if (modeIsHoreca) {
    body.horeca = horecaData;
  }

  const bodyData = JSON.stringify(body);

  return dispatch(
    createRsaaAction<RootState, UserRegister, UserSignupMeta>({
      method: 'POST',
      credentials: 'include',
      endpoint: `${apiRoot}/user/signup/`,
      headers: {
        'Accept-Language': state.app.language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body: bodyData,
      types: [
        {
          type: REGISTER_SIGNUP + REQUEST,
          payload: {
            phone,
          },
        },
        {
          type: REGISTER_SIGNUP + SUCCESS,
          meta: (action, state, res) => {
            const retryAfterSeconds = res.headers.get('retry-after');

            return { headers: { retryAfterSeconds } };
          },
        },
        {
          type: REGISTER_SIGNUP + ERROR,
          meta: (action, state, res) => {
            const retryAfterSeconds = res.headers.get('retry-after');

            return { headers: { retryAfterSeconds }, status: res.status };
          },
        },
      ],
    }),
  );
};

export const userRegistrationConfirm = (otp: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const { fetchConfig } = selectAppConfig(state);
    const { xChain, apiRoot, xVersion } = fetchConfig;
    const bodyData: UserConfirmSignupData = {
      otp,
      phone: state.user.register.phone,
    };

    const response = await dispatch(
      createRsaaAction<RootState, SessionInfo, undefined>({
        method: 'POST',
        credentials: 'include',
        endpoint: `${apiRoot}/user/confirm_signup/`,
        headers: {
          'Accept-Language': state.app.language,
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'x-version': xVersion,
        },
        body: JSON.stringify(bodyData),
        types: [
          REGISTER_CONFIRM + REQUEST,
          REGISTER_CONFIRM + SUCCESS,
          REGISTER_CONFIRM + ERROR,
        ],
      }),
    );

    const { payload, error } = response;

    if (!error && payload && 'expires' in payload) {
      sendUserId(payload.user_id);

      return dispatch({
        type: LOGIN + SUCCESS,
        payload: {
          expires: payload.expires,
        },
      });
    }
    return response;
  };





export const confirmPasswordRecovery = (phone, otp) => (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;
  const bodyData = JSON.stringify({
    phone,
    otp,
  });
  return dispatch({
    [RSAA]: {
      method: 'POST',
      credentials: 'include',
      endpoint: `${apiRoot}/user/confirm_password_recovery/`,
      headers: {
        'Accept-Language': state.app.language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body: bodyData,
      types: [
        CONFIRM_RECOVERY_REQUEST,
        CONFIRM_RECOVERY_SUCCESS,
        CONFIRM_RECOVERY_ERROR,
      ],
    },
  });
};

export const createPassword = (data) => (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;
  const bodyData = JSON.stringify({
    password: data.password,
    password2: data.passwordConfirm,
    otp: data.otp,
    phone: data.login,
  });

  return dispatch({
    [RSAA]: {
      method: 'POST',
      credentials: 'include',
      endpoint: `${apiRoot}/user/create_password/`,
      headers: {
        'Accept-Language': state.app.language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      body: bodyData,
      types: [
        CREATE_PASSWORD_REQUEST,
        CREATE_PASSWORD_SUCCESS,
        CREATE_PASSWORD_ERROR,
      ],
    },
  });
};

export const checkUserLogin = () => async (
  dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, apiRoot, xVersion } = fetchConfig;

  dispatch({ type: CHECK_LOGIN + REQUEST });

  /**
   * The call is done via fetch to avoid API error middleware
   * and not to show error modal.
   */
  const url = `${apiRoot}/user/login/`;

  try {

    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'x-chain': xChain,
        'User-Agent': getUserAgent(),
        'x-version': xVersion,
      },
    });

    if (response.ok) {
      const payload = await response.json();
      await dispatch({
        type: CHECK_LOGIN + SUCCESS,
        payload,
      });

      sendUserId(payload.user_id);
    } else {
      await dispatch({ type: CHECK_LOGIN + ERROR });
    }
    return response;
  } catch (error) {
    await dispatch({ type: CHECK_LOGIN + ERROR });
  }

};

export const restoreOrderInfo = () => (dispatch) =>
  dispatch({
    type: RESTORE_ORDER_INFO,
  });
