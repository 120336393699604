import { ViewProduct } from "src/components/views/ProductsCarousel/ProductsCarousel";
import { Product, ProductUnit } from "src/redux/apiTypes";
import { CartItem } from "src/redux/cart/general/cartTypes";
import { getConvertedAmount } from "src/utils/product/getConvertedAmount";

export const getAmountedPrice = (price: number, amount: number, unit: ProductUnit) => {
  const convertedAmount = getConvertedAmount(amount, unit, true);

  return convertedAmount > 0 ? price * convertedAmount : price;
};

export const getProductsWithAmountPrices = (products: ViewProduct[]) => products.map(p => ({
  ...p,
  price: getAmountedPrice(p.price, p.amount, p.unit),
  discount: {
    ...p.discount,
    old_price: getAmountedPrice(p.discount.old_price, p.amount, p.unit),
  },
}));

export const filterCartItems = (products: Product[], cartItems: CartItem[]): Product[] => products.filter(
  (product) => !cartItems.some(cartItem => cartItem.ean === product.ean),
);
