import isCategoriesPage from 'src/utils/filter/isCategoriesPage';
import { NOT_FOUND } from '../../../../redux/constants';
import getUserAgent from '../../../api/getUserAgent';
import { HandlerFunc } from '../getRedirectPath';

const handleCategory: HandlerFunc = async ({ path, appConfig }) => {
  const { fetchConfig } = appConfig;
  const splitPath = path.split('?');
  const [uri] = splitPath;
  const segments = uri.split('/');
  const langSegment = segments[1];
  const categoryName = segments[3];
  const { xChain, apiRoot, xVersion } = fetchConfig;

  if (categoryName && isCategoriesPage(path)) {
    const response = await fetch(
      `${apiRoot}/stores/default/categories/${categoryName}`,
      {
        method: 'GET',
        headers: {
          'Accept-Language': langSegment,
          'x-chain': xChain,
          'x-version': xVersion,
          'User-Agent': getUserAgent(),
        },
        redirect: 'manual',
      },
    );

    if (response.status === 301) {
      const responseSegments = response.headers.get('Location')?.split('/');
      const lastSegment = responseSegments[responseSegments.length - 1];
      const redirectedCategoryName =
        lastSegment === ''
          ? responseSegments[responseSegments.length - 2]
          : lastSegment;
      return ['', langSegment, 'categories', redirectedCategoryName, ''].join('/');
    }

    if (response.status === 404) {
      return NOT_FOUND;
    }
  }

  return path;
};

export default handleCategory;
