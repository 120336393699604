import Cookies from 'js-cookie';

/**
 * It's not possible to remove literally all cookies from the user's browser.
 * Some of them might have been created with a separate domain
 * or path attribute than the one the user is currently in
 * or even created with the httpOnly attribute
 * and therefore they might not be visible.
 *
 * What it's possible is to try to remove all cookies
 * that are visible to the user:
 */
const removeAllCookies = () => {
  const cookies = Cookies.get();

  Object
    .keys(cookies)
    .forEach(cookieName => {
      /**
       * Here you pass the same attributes that were used
       * when the cookie was created
       * and are required when removing the cookie
       */
      const neededAttributes = {};

      Cookies.remove(cookieName, neededAttributes);
    });
};

export default removeAllCookies;
