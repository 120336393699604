import { useServices } from "..";

const useLocalization = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useLocalization');
  }

  return services.localization;
};


export default useLocalization;
