import { FetchConfig } from "src/redux/apiTypes";
import { FavoriteOperationType } from "../types";

interface Params {
  fetchConfig: FetchConfig;
  actionType: FavoriteOperationType;
  listIds: string[];
  ean: string;
}

const requestPostFavoritesItem = async (params: Params): Promise<boolean> => {
  const {
    fetchConfig,
    actionType,
    listIds,
    ean,
  } = params;
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const response = await fetch(`${apiRoot}/user/lists/single_item`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body: JSON.stringify({
      item: {
        ean,
      },
      list_ids: {
        [actionType]: listIds,
      },
    }),
  });

  if (!response.ok) {
    throw new Error('Post favorite lists item error');
  }

  return true;
};

export default requestPostFavoritesItem;
