import { RSAA } from 'redux-api-middleware';
import getUserAgent from '../utils/api/getUserAgent';

const userAgentMiddlware = () => next => action => {
  if (action[RSAA]) {
    const modifiedAction = {
      ...action,
      [RSAA]: {
        ...action[RSAA],
        headers: {
          ...action[RSAA].headers,
          'User-Agent': getUserAgent(),
        },
      },
    };
    return next(modifiedAction);
  }
  return next(action);
};

export default userAgentMiddlware;
