import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import {
  ServerCartState,
  ServerCartItemToSend,
} from './types';

const updateServerCartItemsUpdateMap = createAction<ServerCartItemToSend[]>(
  'serverCart/updateCartItemsUpdateMap',
);

export const addUpdateCartItemsUpdateMapCase = (
  builder: ActionReducerMapBuilder<ServerCartState>,
) => {
  builder.addCase(updateServerCartItemsUpdateMap, (state, action) => {

    action.payload.forEach((cartItemUpdateData) => {
      const oldData = state.cartItemsUpdateMap[cartItemUpdateData.ean];

      // this case happens when a user adding a product
      // to the cart from the favorite list or orders history
      if (
        oldData &&
        oldData.operation === 'add' &&
        cartItemUpdateData.operation === 'add'
      ) {
        state.cartItemsUpdateMap[cartItemUpdateData.ean] = {
          ...cartItemUpdateData,
          amount: oldData.amount + cartItemUpdateData.amount,
        };
      } else {
        state.cartItemsUpdateMap[cartItemUpdateData.ean] = cartItemUpdateData;
      }
    });
  });
};

export default updateServerCartItemsUpdateMap;