import { IncomingMessage, ServerResponse } from "http";

const browserCacheTime = 60000;

const checkIfModified = (res: ServerResponse, req: IncomingMessage) => {
  const lastModifiedTime =  Math.floor(Date.now() / browserCacheTime) * browserCacheTime;
  const lastModifiedDateString = new Date(lastModifiedTime).toUTCString();
  res.setHeader('Cache-Control', 'public, no-cache');
  res.setHeader('Last-Modified', lastModifiedDateString);
  const cachedDocDateString = req.headers['if-modified-since'];
  const cachedDocTime = cachedDocDateString && new Date(cachedDocDateString).getTime();

  return !cachedDocTime || cachedDocTime < lastModifiedTime;
};

export default checkIfModified;
