import { useServices } from "..";

const useStoreService = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useStoreService');
  }

  return services.store;
};

export default useStoreService;
