import cn from 'classnames';
import * as React from 'react';
import { Fragment } from 'react';
import { styles } from './LoadingBarIndicator.styles';

interface Props {
  isUnderContainer?: boolean
}

const LoadingBarIndicator: React.FC<Props> = (props) => {
  const { isUnderContainer } = props;
  return (
    <Fragment>
      <div
        className={cn(
          'LoadingBarIndicator',
          {'LoadingBarIndicator_isUnderContainer': isUnderContainer},
        )}
        data-testid='LoadingBarIndicator'
        aria-busy='true'
        aria-live='polite'
        role='status'
      />
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default LoadingBarIndicator;
