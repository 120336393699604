import { weightMeasureEnum } from "../../fixtures";
import { Quantity } from "../../redux/apiTypes";

export function getMinimum(unit: string, quantity: Quantity = {}): number | never {
  if ([weightMeasureEnum.pcs, weightMeasureEnum.kg].includes(unit) === false) {
    throw new Error('No unit provided');
  }

  return (quantity?.is_strict && quantity?.min) || weightMeasureEnum.properties[unit].MINIMUM;
}
