import * as Sentry from '@sentry/nextjs';
import { MainSiteDelivery } from '../types';

export function getDeliveryData(
  encodedDeliveryData: string | undefined,
): MainSiteDelivery | null {
  if (!encodedDeliveryData) {
    return null;
  }

  try {
    const jsonDeliveryData = decodeURIComponent(encodedDeliveryData);
    const deliveryData: MainSiteDelivery = JSON.parse(jsonDeliveryData);

    return deliveryData;
  } catch (error) {
    console.error(error);

    Sentry.captureException(
      new Error(`Can't parse delivery data: '${encodedDeliveryData}'`),
      {
        extra: {
          originalError: error,
        },
      },
    );

    return null;
  }
}
