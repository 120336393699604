import { getSendingToCartItems } from '../../general/helpers/getSendingToCartItems';
import requestPostCartItems from '../requestPostCartItems';

import { CartItem } from '../../general/cartTypes';
import { Dispatch } from 'redux';

export const setServerCartItems = (items: CartItem[]) => (dispatch: Dispatch) => {
  const sendingItems = getSendingToCartItems(items, 'set');

  return dispatch(requestPostCartItems(sendingItems));
};
