import Cookies from 'js-cookie';
import { SubscriptionsSettings } from 'src/swr/useUserProfile';
import { ErrorsMap, errorsMapper, ErrorsObject } from '../../utils/api/errorsMapper';
import { getResponseErrors } from '../../utils/api/getResponseErrors';
import { CLIENT_TYPE, FIRST_NAME, HORECA, IS_HORECA, LAST_NAME, SET_CONFIRMED_AGE, SUBSCRIBE_TO_MARKETING, USER_BIRTH_DATE, USER_CAN_EDIT_EMAIL, USER_EMAIL, USER_LOGIN_EMAIL, USER_LOGIN_PHONE, USER_PHONE_LIST } from '../constants';
import createReducer from '../createReducer';
import { getClientSideUselessEmails, getClientSideUserEmail, getIsEmailEditable } from './dataConverters';
import { ADD_USER_PHONE_REQUEST, ADD_USER_PHONE_SUCCESS, CHANGE_USER_PHONE_REQUEST, CHANGE_USER_PHONE_SUCCESS, CONFIRM_CHANGE_USER_PHONE_REQUEST, CONFIRM_CHANGE_USER_PHONE_SUCCESS, CONFIRM_USER_PHONE_REQUEST, CONFIRM_USER_PHONE_SUCCESS, DELETE_USER_PHONE_REQUEST, DELETE_USER_PHONE_SUCCESS, FETCH_USER_PHONE_LIST_REQUEST, FETCH_USER_PHONE_LIST_SUCCESS, FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS, RESET_ERRORS, SEND_USER_PROFILE_DATA_REQUEST, SEND_USER_PROFILE_DATA_SUCCESS, SET_USER_PROFILE_DATA, USER_PROFILE_DATA_ERROR } from './userProfileTypes';

export interface UserPhone {
  phone: string;
  is_editable: boolean;
}

export interface UserData {
  isDataCanBeSaved?: boolean;
  [USER_LOGIN_PHONE]?: string;
  [USER_LOGIN_EMAIL]?: string;
  FIRST_NAME?: string;
  [LAST_NAME]?: string;
  [SUBSCRIBE_TO_MARKETING]?: boolean;
  USER_EMAIL?: string;
  [USER_CAN_EDIT_EMAIL]?: boolean;
  [USER_BIRTH_DATE]?: string;
  USER_PHONE_LIST?: UserPhone[];
  [IS_HORECA]?: boolean;
  deliveredOrdersCount?: number;
  allChainsDeliveredOrdersCount?: number;
  uselessEmails?: string[];
  subscriptions?: SubscriptionsSettings;
  need_update_policy?: boolean;
}

export type ConfirmedAge = 'confirmed' | 'canceled' ;

export interface UserDataPostRequestBody {
  emails?: string[];
  name?: string;
  birthdate?: string;
  subscribed_to_marketing?: boolean;
  subscriptions?: SubscriptionsSettings;
}

interface InitialState {
  isFetching: boolean;
  errors: ErrorsObject;
  userData: UserData;
  retryAfterSeconds: null | number;
  confirmedAge: ConfirmedAge;
}

export const userProfileInitialState: InitialState = {
  isFetching: false,
  errors: {},
  userData: {},
  retryAfterSeconds: null,
  confirmedAge: 'confirmed',
};

const actionHandlers = {
  [FETCH_USER_PROFILE_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [SET_CONFIRMED_AGE]: (state, action) => (
    {
      ...state,
      confirmedAge: action.payload,
    }
  ),
  [FETCH_USER_PROFILE_SUCCESS]: (state, action) => {
    if (!action.payload) return {
      ...state,
      isFetching: false,
    };

    const {
      name,
      surname,
      subscribed_to_marketing,
      birthdate,
      emails,
      phones,
      is_horeca,
      delivered_orders_count,
      login = {},
      subscriptions,
      need_update_policy,
      all_chains_delivered_orders_count,
    } = action.payload;

    if (is_horeca && Cookies.get(CLIENT_TYPE) !== HORECA) {
      Cookies.set(CLIENT_TYPE, HORECA, { expires: 14 });
    }

    if (!is_horeca && Cookies.get(CLIENT_TYPE) === HORECA) {
      Cookies.remove(CLIENT_TYPE);
    }

    const userData = {
      isDataCanBeSaved: false,

      [USER_LOGIN_PHONE]: login.phone,
      [USER_LOGIN_EMAIL]: login.email,
      [FIRST_NAME]: name || '',
      [LAST_NAME]: surname || '',
      [SUBSCRIBE_TO_MARKETING]: subscribed_to_marketing,
      [USER_EMAIL]: getClientSideUserEmail(emails),
      [USER_CAN_EDIT_EMAIL]: getIsEmailEditable(emails),
      [USER_BIRTH_DATE]: birthdate || '',
      [USER_PHONE_LIST]: phones || [],
      [IS_HORECA]: is_horeca,
      deliveredOrdersCount: delivered_orders_count,
      allChainsDeliveredOrdersCount: all_chains_delivered_orders_count,
      subscriptions,
      need_update_policy,

      // handle storing useless user emails for server API, this logic transferred from old AccountSettingsPersonal
      uselessEmails: getClientSideUselessEmails(emails),
    };

    return {
      ...state,
      isFetching: false,
      userData,
    };
  },
  [SEND_USER_PROFILE_DATA_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [SEND_USER_PROFILE_DATA_SUCCESS]: state => ({
    ...state,
    isFetching: false,
  }),
  [FETCH_USER_PHONE_LIST_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [FETCH_USER_PHONE_LIST_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    userData: {
      ...state.userData,
      [USER_PHONE_LIST]: action.payload,
    },
  }),
  [ADD_USER_PHONE_REQUEST]: state => ({
    ...state,
    isFetching: true,
    retryAfterSeconds: null,
  }),
  [ADD_USER_PHONE_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    retryAfterSeconds: action.meta.headers.retryAfterSeconds,
  }),
  [CONFIRM_USER_PHONE_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [CONFIRM_USER_PHONE_SUCCESS]: state => ({
    ...state,
    isFetching: false,
  }),
  [DELETE_USER_PHONE_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [DELETE_USER_PHONE_SUCCESS]: state => ({
    ...state,
    isFetching: false,
  }),
  [CHANGE_USER_PHONE_REQUEST]: state => ({
    ...state,
    isFetching: true,
    retryAfterSeconds: null,
  }),
  [CHANGE_USER_PHONE_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    retryAfterSeconds: action.meta.headers.retryAfterSeconds,
  }),
  [CONFIRM_CHANGE_USER_PHONE_REQUEST]: state => ({
    ...state,
    isFetching: true,
  }),
  [CONFIRM_CHANGE_USER_PHONE_SUCCESS]: state => ({
    ...state,
    isFetching: false,
  }),
  [RESET_ERRORS]: state => {
    return {
      ...state,
      errors: {},
    };
  },
  [USER_PROFILE_DATA_ERROR]: (state, action) => {
    const { payload } = action;

    const errorsMap: ErrorsMap = {
      name: FIRST_NAME,
      FIRST_NAME,
      LAST_NAME,
      subscribed_to_marketing: SUBSCRIBE_TO_MARKETING,
      USER_EMAIL,
      emails: USER_EMAIL,
      'emails[0]': USER_EMAIL,
      USER_BIRTH_DATE,
      birthdate: USER_BIRTH_DATE,
      USER_PHONE_LIST,
    };

    const newErrors = errorsMapper(getResponseErrors(payload), errorsMap);

    return {
      ...state,
      isFetching: false,
      errors: {
        ...state.errors,
        ...newErrors,
      },
      userData: {
        ...state.userData,
        isDataCanBeSaved: false,
      },
    };
  },
  [SET_USER_PROFILE_DATA]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      userData: {
        ...state.userData,
        isDataCanBeSaved: true,
        ...payload,
      },
    };
  },
};

const reducer = createReducer(userProfileInitialState, actionHandlers);

export default reducer;
