import { createSelector } from '@reduxjs/toolkit';
import { Selector } from 'react-redux';
import { FetchConfig } from 'src/redux/apiTypes';
import { RootState } from 'src/redux/reducers';
import { getIsServer } from '../system/getIsServer';
import { InitialAppConfig, Language } from './getInitialAppConfig';

export interface AppConfig extends InitialAppConfig {
  fetchConfig: FetchConfig;
}

export const selectAppConfig: Selector<RootState, AppConfig> = createSelector([
  (state: RootState) => state.initialAppConfig,
  (state: RootState) => state.app.language,
],(initialAppConfig, language: Language) => {
  const { chain, api } = initialAppConfig;
  const { API_VERSION, SSR_API_ROOT, PUBLIC_API_ROOT, QUERY_API_ROOT } = api;

  /**
   * If you need it you can hardcode API_ROOT here.
   * But as a better way, you can pass QUERY_API_ROOT as a parameter 'api='
   */
  const HARDCODED_API_ROOT = '';

  const apiRoot =
    HARDCODED_API_ROOT ||
    QUERY_API_ROOT ||
    (getIsServer() ? SSR_API_ROOT : PUBLIC_API_ROOT);

  const appConfig: AppConfig = {
    ...initialAppConfig,
    fetchConfig: {
      xChain: chain,
      apiRoot,
      xVersion: API_VERSION,
      language,
    },
  };

  return appConfig;
});
