import { createReducer } from '@reduxjs/toolkit';
import { addPlaceProductToClientCartCases } from './clientCart/operations/placeProductToClientCart';
import { addRequestPostClientCartCases } from './clientCart/requestPostClientCart';
import { addResetClientCartErrorsCases } from './clientCart/resetClientCartErrors';
import { addRestoreCartPriceCases } from './clientCart/restoreCartPrice';
import { addSetCartInitializedCases } from './clientCart/setCartInitialized';
import { addUpdateClientCartItemsCases } from './clientCart/updateClientCartItems';
import { CartState } from './general/cartTypes';
import { addSetCartItemsCases } from './general/operations/setCartItems';

export const cartInitialState: CartState = {
  isFetching: false,
  errors: null,
  cart: [],
  cartPrice: {},
  isCartInitialized: false,
  dataSignature: null,
};

const reducer = createReducer(cartInitialState, builder => {
  addRequestPostClientCartCases(builder);
  addResetClientCartErrorsCases(builder);
  addPlaceProductToClientCartCases(builder);
  addRestoreCartPriceCases(builder);
  addSetCartInitializedCases(builder);
  addUpdateClientCartItemsCases(builder);
  addSetCartItemsCases(builder);
});

export default reducer;
