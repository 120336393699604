import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { ProductState } from "./types";

const resetProductData = createAction('product/resetProductData');

export default resetProductData;

export function addResetProductData(builder: ActionReducerMapBuilder<ProductState>) {
  builder.addCase(resetProductData, (state) => {
    state.productData = null;
  });
}
