import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { TimeWindow } from '../../../swr/useScheduleDelivery/scheduleDeliveryTypes';
import { getResponseErrors } from '../../../utils/api/getResponseErrors';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartState } from './types';

const postScheduleRequest = createAction('serverCart/postScheduleRequest');
export const postScheduleSuccess = createAction<ServerCartData>('serverCart/postScheduleSuccess');
const postScheduleError = createAction<RSAAActionErrorPayload>('serverCart/postScheduleError');

const requestPostSchedule = (timeWindow: TimeWindow) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/time_window/`,
      body: JSON.stringify({
        time_window_id: timeWindow.id,
      }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postScheduleRequest.type,
        {
          type: postScheduleSuccess.type,
          payload: getSuccessPayload,
        },
        postScheduleError.type,
      ],
    }),
  );
};

export default requestPostSchedule;

export function addPostScheduleCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postScheduleRequest, (state) => {
      state.statusMap.postSchedule = 'loading';
      state.errorMap.postSchedule = null;
    })
    .addCase(postScheduleSuccess, (state, action) => {
      state.data = action.payload;
      state.statusMap.postSchedule = 'succeeded';
      state.errorMap.postSchedule = null;
      state.errors = null;
    })
    .addCase(postScheduleError, (state, action) => {
      state.statusMap.postSchedule = 'failed';
      state.errorMap.postSchedule = action.payload;
      state.errors = getResponseErrors(action.payload);
    });
}
