import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { RSAAActionErrorPayload } from '../apiTypes';
import { RootState } from '../reducers';
import { ReferralProgramInfo, ReferralProgramState } from './types';

const getInfoRequest = createAction('referralProgram/getInfoRequest');
const getInfoSuccess = createAction<ReferralProgramInfo>('referralProgram/getInfoSuccess');
const getInfoError = createAction<RSAAActionErrorPayload>('referralProgram/getInfoError');

const getReferralInfo =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const { fetchConfig } = selectAppConfig(state);
    const { xChain, xVersion, apiRoot, language } = fetchConfig;

    return dispatch(
      createRsaaAction<RootState, ReferralProgramInfo, undefined>({
        method: 'GET',
        endpoint: `${apiRoot}/user/referral_program/info`,
        credentials: 'include',
        headers: {
          'x-chain': xChain,
          'x-version': xVersion,
          'accept-language': language,
        },
        types: [
          getInfoRequest.type,
          getInfoSuccess.type,
          getInfoError.type,
        ],
      }),
    );
  };

export default getReferralInfo;

export function addGetInfoCases(builder: ActionReducerMapBuilder<ReferralProgramState>) {
  builder
    .addCase(getInfoRequest, (state) => {
      state.info = null;
      state.statusMap.getReferralInfo = 'loading';
      state.errorMap.getReferralInfo = null;
    })
    .addCase(getInfoSuccess, (state, action) => {
      state.info = action.payload;
      state.statusMap.getReferralInfo = 'succeeded';
      state.errorMap.getReferralInfo = null;
    })
    .addCase(getInfoError, (state, action) => {
      state.info = null;
      state.statusMap.getReferralInfo = 'failed';
      state.errorMap.getReferralInfo = action.payload;
    });
}
