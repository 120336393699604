import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { RSAAActionErrorPayload } from '../../apiTypes';
import { RootState } from '../../reducers';
import getSuccessPayload from './getSuccessPayload';
import { ServerCartData, ServerCartItemToSend, ServerCartState } from './types';

const postCartItemsRequest = createAction('serverCart/postCartItemsRequest');
export const postCartItemsSuccess = createAction<ServerCartData>('serverCart/postCartItemsSuccess');
const postCartItemsError = createAction<RSAAActionErrorPayload>('serverCart/postCartItemsError');

const requestPostCartItems = (items: ServerCartItemToSend[]) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    createRsaaAction<RootState, ServerCartData, undefined>({
      method: 'POST',
      endpoint: `${apiRoot}/cart/items/`,
      body: JSON.stringify({ items }),
      credentials: 'include',
      headers: {
        'Accept-Language': language,
        'Content-Type': 'application/json',
        'x-chain': xChain,
        'x-version': xVersion,
      },
      types: [
        postCartItemsRequest.type,
        {
          type: postCartItemsSuccess.type,
          payload: getSuccessPayload,
        },
        postCartItemsError.type,
      ],
    }),
  );
};

export default requestPostCartItems;

export function addPostCartItemsCases(builder: ActionReducerMapBuilder<ServerCartState>) {
  builder
    .addCase(postCartItemsRequest, (state) => {
      state.statusMap.postCartItems = 'loading';
      state.errorMap.postCartItems = null;
    })
    .addCase(postCartItemsSuccess, (state, action) => {
      const newServerCartData = {...action.payload};

      if(newServerCartData?.items) {
        const oldCartItemsMap = state.data.items.reduce((acc, item) => {
          acc[item.ean] = item;

          return acc;
        }, {});

        Object.keys(state.cartItemsUpdateMap).forEach((ean) => {
          const itemIndexInNewArray = newServerCartData.items.findIndex(i => i.ean === ean);
          const oldCartItem = oldCartItemsMap[ean];
          const cartItemInNewArray = itemIndexInNewArray !== -1;

          // if item was removed from local server cart state
          // we also have to remove this item from newCartItems
          if(!oldCartItem) {
            if (cartItemInNewArray) {
              newServerCartData.items = newServerCartData.items.filter(i => i.ean !== ean);
            }

            return;
          }

          if(cartItemInNewArray) {
            newServerCartData.items[itemIndexInNewArray] = oldCartItem;
          } else {
            newServerCartData.items.push(oldCartItem);
          }
        });
      }

      state.data = newServerCartData;
      state.statusMap.postCartItems = 'succeeded';
      state.errorMap.postCartItems = null;
    })
    .addCase(postCartItemsError, (state, action) => {
      state.statusMap.postCartItems = 'failed';
      state.errorMap.postCartItems = action.payload;
    });
}
