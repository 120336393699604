/** @todo create separate files */
import { DeliveryType, ImageSize } from '../redux/apiTypes';
import { PlanType, ReplacementsCommunicationType, ReplacementsNoReplyAction } from '../redux/cart/serverCart/types';
import { MEASURE_KG, MEASURE_PCS } from '../redux/constants';
import { RegistrationUser } from '../redux/modals/types';
import { pieceInputRegexp, weightInputRegexp } from '../utils/validation/regexpPatterns';

/**
 * @todo
 * Create separate files for MEASURE_KG and MEASURE_PCS
 */

export const copyrightStartEnum = {
  ua: '2010',
  md: '2019',
};

export const weightMeasureEnum = {
  pcs: 'pcs',
  kg: 'kg',
  properties: {
    [MEASURE_PCS]: {
      MINIMUM: 1,
      STEP: 1,
      limit: 999,
      translation_key: 'general.item',
    },
    [MEASURE_KG]: {
      MINIMUM: 100,
      STEP: 500,
      limit: 999000,
      translation_key: 'general.kg',
    },
  },
  patterns: {
    [MEASURE_PCS]: pieceInputRegexp,
    [MEASURE_KG]: weightInputRegexp,
  },
};

export const sortOrderList = [
  {
    id: 'popularity_desc',
    translation_key: 'general.sort.popular-desc',
    value: 'popularity_desc',
    default: true,
  },
  {
    id: 'price_asc',
    translation_key: 'general.sort.price-asc',
    value: 'price_asc',
  },
  {
    id: 'price_desc',
    translation_key: 'general.sort.price-desc',
    value: 'price_desc',
  },
  {
    id: 'alphabetic_asc',
    translation_key: 'alphabetic_asc',
    value: 'alphabetic_asc',
  },
  {
    id: 'alphabetic_desc',
    translation_key: 'alphabetic_desc',
    value: 'alphabetic_desc',
  },
];

export interface SortOrderListItem {
  id: string
  translation_key: string
  value: string
  default?: boolean
}

export const searchResultsSortOrderList: SortOrderListItem[] = [
  {
    id: 'relevance_desc',
    translation_key: 'general.sort.relevance_desc',
    value: 'relevance_desc',
    default: true,
  },
  {
    id: 'popularity_desc',
    translation_key: 'general.sort.popular-desc',
    value: 'popularity_desc',
  },
  {
    id: 'price_asc',
    translation_key: 'general.sort.price-asc',
    value: 'price_asc',
  },
  {
    id: 'price_desc',
    translation_key: 'general.sort.price-desc',
    value: 'price_desc',
  },
  {
    id: 'alphabetic_asc',
    translation_key: 'alphabetic_asc',
    value: 'alphabetic_asc',
  },
  {
    id: 'alphabetic_desc',
    translation_key: 'alphabetic_desc',
    value: 'alphabetic_desc',
  },
];

export const orderProductsSort: SortOrderListItem[] = [
  {
    translation_key: 'general.sort.by_adding_time',
    id: 'by_adding_time',
    value: 'by_adding_time',
  },
  {
    translation_key: 'general.sort.by_category',
    id: 'by_category',
    value: 'by_category',
    default: true,
  },
];


interface UserCheckoutFieldNamesEnum {
  CHECKOUT_EXTRA_PHONE: string
  CONNECTION_RADIO_NAME: string
  NO_REPLY_RADIO_NAME: string
  CHECKOUT_UPDATE_COMMENT: string
  ADDRESS: {[key: string]: string }
  COUPON: string
  pickerContact: { [key: string]: ReplacementsCommunicationType }
  pickerUpdate: { [key: string]: ReplacementsNoReplyAction }
  addressType: { [key: string]: PlanType }
  OFFER: string,
  CHECKOUT_TIPS: string,
}

/** @deprecated use TS types */
export const userCheckoutFieldNamesEnum: UserCheckoutFieldNamesEnum = {
  CHECKOUT_EXTRA_PHONE: 'checkoutExtraPhone',
  CONNECTION_RADIO_NAME: 'communication_type',
  NO_REPLY_RADIO_NAME: 'no_reply_action',
  pickerContact: {
    BY_PHONE: 'by_phone',
    BY_CHAT: 'by_chat',
  },
  pickerUpdate: {
    UPDATE: 'replace',
    DO_NOT_COLLECT: 'skip',
  },
  CHECKOUT_UPDATE_COMMENT: 'comments',
  ADDRESS: {
    BLOCK: 'block',
    COMPANY_NAME: 'company_name',
    COORDINATES: 'coords',
    ELEVATOR: 'elevator',
    ENTRANCE: 'entrance',
    ENTRANCE_CODE: 'entrance_code',
    FLOOR: 'floor',
    ID: 'id',
    ROOM: 'room',
  },
  addressType: {
    APT: 'apartment',
    OFFICE: 'office',
    HOUSE: 'house',
  },
  COUPON: 'coupon',
  OFFER: 'offer',
  CHECKOUT_TIPS: 'tips',
};

export const passwordFields = {
  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
};

export const userLoginField = {
  LOGIN_PHONE: 'loginPhone',
  LOGIN_PASSWORD: 'loginPassword',
};

/** @deprecated use `DeliveryType` type */
export const deliveryAvailabilityEnum: {
  COURIER: Extract<DeliveryType, 'plan'>
  PICK_UP: Extract<DeliveryType, 'pickup'>
  NOVA_POSHTA: Extract<DeliveryType, 'nova_poshta'>
  NOVA_POSHTA_FRESH: Extract<DeliveryType, 'nova_poshta_fresh'>
  NOVA_POSHTA_ADDRESS: Extract<DeliveryType, 'nova_poshta_address'>
} = {
  COURIER: 'plan',
  PICK_UP: 'pickup',
  NOVA_POSHTA: 'nova_poshta',
  NOVA_POSHTA_FRESH: 'nova_poshta_fresh',
  NOVA_POSHTA_ADDRESS: 'nova_poshta_address',
};

export type RegistrationStep = 'stepOne' | 'stepTwo' | 'stepTree'

export const REGISTRATION_TYPES: RegistrationUser[] = ['regularUser', 'horecaUser'];


export const paymentTypesEnums = {
  BANK: 'bank',
  CARD_ONLINE: 'card_online',
  cod: 'cod',
  properties: {
    cod: {
      translation_key: 'checkout-pay__cod-label',
      dataMarker: 'Cash upon receiving the order',
    },
    card_online: {
      translation_key: 'checkout-pay__card-online-label',
      dataMarker: 'Visa-Mastercard on the web',
    },
    card_offline: {
      translation_key: 'checkout-pay__card-offline-label',
      dataMarker: 'Visa-Mastercard upon receiving the order',
    },
    bank: {
      translation_key: 'checkout-pay__bank-label',
      dataMarker: 'Cashless payments',
    },
    card_online_auto: {
      translation_key: 'checkout-pay__card-online-label',
      dataMarker: 'Card auto charge',
    },
  },
};

interface HumanImageSizes {
  [name: string]: ImageSize
}

export const imageSizes: HumanImageSizes = {
  preview: 's150x150',
  small: 's200x200',
  medium: 's350x350',
  large: 's1350x1350',
};

export const defaultProductImage = '/i/default_product_image.png';

export const searchErrorList = [
  {
    translation_key: 'error.search.typo',
  },
  {
    translation_key: 'error.search.another-text',
  },
  {
    translation_key: 'error.search.general-text',
  },
];
