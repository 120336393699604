import Image from 'next/image';
import useLocalization from 'src/services/localization/useLocalization';
import { MessengerLauncherButtonProps } from '../Messengers.types';

const MessengerLauncherButton = ({ onClick, dataMarkerValue = 'MessengerLauncherButton' }: MessengerLauncherButtonProps) => {
  const localize = useLocalization();

  return (
    <span
      onClick={onClick}
      data-marker={dataMarkerValue}
      className='MessengerLauncherButton'
      data-testid="launcher_button"
    >
      <Image
        src='https://img4.zakaz.ua/about_contacts/zendesk3.png'
        alt={localize('chat_online')}
        width={40}
        height={40}
      />
      <style jsx>{`
        .MessengerLauncherButton {
          display: inline-block;
        }
      `}</style>
    </span>
  );
};

export default MessengerLauncherButton;
