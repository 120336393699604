import { weightMeasureEnum } from "../../fixtures";
import { Quantity } from "../../redux/apiTypes";
import { getMinimum } from "./getMinimum";

export function restrict(
  amount: number,
  unit: string,
  quantity: Quantity = {},
) {
  const min = getMinimum(unit, quantity);
  const max = weightMeasureEnum.properties[unit].limit;

  if (amount < min) return min;

  if (amount > max) return max;

  if (quantity?.is_strict) {
    const step = quantity.step || 1;
    return Math.ceil(amount / step) * step;
  }

  return amount;
}
